import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { fromEvent, Subject, timer } from 'rxjs';
import { filter, shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { IAppState } from '@app/store/app-init/app.state';
import { getFeatureIsEnabled } from '@app/store/feature-flag/feature-flag.reducer';
import { FeatureName } from '@configurations/feature-name.enum';
import { WindowRefService } from '@core/windowref/windowref.service';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import {
  AuthorizingEvent,
  InitializedEvent,
  InstallmentPlanChangedEvent,
  PaymentAttemptEvent,
  PaymentAttemptV2Event,
  PaymentMethodActionEvent,
  PaymentMethodActionV2Event,
  PaymentMethodChangedEvent,
  PaymentPanelEvent,
  PaymentPanelEventName,
  PaymentPanelV2Event,
  PaymentPanelV2EventName,
  SetIFrameHeightEvent,
} from '@shared/model/payment-panel-event';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentNotificationService implements OnDestroy {
  private setIFrameHeightSubject$ = new Subject<number>();
  onSetIFrameHeight$ = this.setIFrameHeightSubject$.asObservable();

  private paymentIframeLoadedSubject$ = new Subject<boolean>();
  onPaymentIframeLoaded$ = this.paymentIframeLoadedSubject$.asObservable();

  private paymentAttemptSubject$ = new Subject<string>();
  onPaymentAttempt$ = this.paymentAttemptSubject$.asObservable();

  private paymentAuthorizingSubject = new Subject<string>();
  onPaymentAuthorizing$ = this.paymentAuthorizingSubject.asObservable();

  private paymentMethodActionSubject = new Subject<PaymentMethodActionEvent>();
  onPaymentMethodAction$ = this.paymentMethodActionSubject.asObservable();

  private paymentMethodActionV2Subject = new Subject<PaymentMethodActionV2Event>();
  onPaymentMethodActionV2$ = this.paymentMethodActionV2Subject.asObservable();

  private installmentPlanChangedSubject = new Subject<InstallmentPlanChangedEvent>();
  onInstallmentPlanChanged$ = this.installmentPlanChangedSubject.asObservable().pipe(shareReplay(1));

  constructor(
    private windowRef: WindowRefService,
    private store: Store<IAppState>,
    private appInsightsWrapperService: AppInsightsWrapperService,
  ) {
    this.attachOnWindowMessages();
  }

  trackPaymentPanelLoadTimeoutEvent(timeout: number = 20000) {
    const iframeIsLoaded$ = this.onPaymentIframeLoaded$.pipe(filter(Boolean));
    return timer(timeout).pipe(
      tap(() =>
        this.appInsightsWrapperService.trackEvent(AppInsightsEventName.PaymentPanelLoadTimeoutErrorEvent, {
          timeout: timeout.toString(),
        }),
      ),
      takeUntil(iframeIsLoaded$),
    );
  }

  ngOnDestroy() {
    this.setIFrameHeightSubject$.complete();
    this.paymentIframeLoadedSubject$.complete();
    this.paymentAttemptSubject$.complete();
  }

  paymentIframeLoaded(value: boolean): void {
    this.paymentIframeLoadedSubject$.next(value);
  }

  resetIframeHeight() {
    this.setIFrameHeightSubject$.next(0);
  }

  private attachOnWindowMessages() {
    fromEvent<MessageEvent>(this.windowRef.nativeWindow, 'message')
      .pipe(
        filter((event: MessageEvent) => !!event.data),
        withLatestFrom(this.store.pipe(select(getFeatureIsEnabled(FeatureName.PaymentPanelV2Enabled)))),
        tap(([event, paymentPanelV2IsEnabled]: [MessageEvent, boolean]) => {
          if (paymentPanelV2IsEnabled) {
            this.handlePaymentPanelV2Event(event);
          } else {
            this.handlePaymentPanelV1Event(event);
          }
        }),
      )
      .subscribe();
  }

  private handlePaymentPanelV1Event(event: MessageEvent) {
    const message: PaymentPanelEvent = event.data;

    switch (message.name) {
      case PaymentPanelEventName.PaymentAttempt:
        this.notifyPaymentAttemptEvent((message as PaymentAttemptEvent).paymentMethod);
        break;
      case PaymentPanelEventName.SetIFrameHeight:
        this.notifySetIFrameHeightEvent((message as SetIFrameHeightEvent).height);
        break;
      case PaymentPanelEventName.PaymentMethodAction: {
        this.notifyPaymentMethodActionEvent(message as PaymentMethodActionEvent);
        break;
      }
    }
  }

  private handlePaymentPanelV2Event(event: MessageEvent) {
    const eventData: PaymentPanelV2Event = event.data;

    switch (eventData.eventName) {
      case PaymentPanelV2EventName.Initialized: {
        this.notifySetIFrameHeightEvent((eventData as InitializedEvent).eventData.documentHeight);
        break;
      }

      case PaymentPanelV2EventName.PaymentAttempt: {
        this.notifyPaymentAttemptEvent((eventData as PaymentAttemptV2Event).eventData.paymentMethod);
        break;
      }

      case PaymentPanelV2EventName.Authorizing: {
        this.notifyPaymentAuthorizing((eventData as AuthorizingEvent).eventData.paymentMethod);
        break;
      }

      case PaymentPanelV2EventName.PaymentMethodChanged: {
        this.notifySetIFrameHeightEvent((eventData as PaymentMethodChangedEvent).eventData.documentHeight);
        break;
      }

      case PaymentPanelV2EventName.PaymentMethodAction: {
        this.notifyPaymentMethodActionV2Event(eventData as PaymentMethodActionV2Event);
        break;
      }

      case PaymentPanelV2EventName.InstallmentPlanChanged: {
        this.notifyInstallmentPlanChanged(eventData as InstallmentPlanChangedEvent);
        break;
      }
    }
  }

  private notifyPaymentMethodActionEvent(eventData: PaymentMethodActionEvent) {
    this.paymentMethodActionSubject.next(eventData);
  }

  private notifyPaymentMethodActionV2Event(eventData: PaymentMethodActionV2Event) {
    this.paymentMethodActionV2Subject.next(eventData);
  }

  private notifyPaymentAuthorizing(paymentMethod: string): void {
    this.paymentAuthorizingSubject.next(paymentMethod);
  }

  private notifyPaymentAttemptEvent(paymentMethod: string): void {
    this.paymentAttemptSubject$.next(paymentMethod);
  }

  private notifySetIFrameHeightEvent(height: number): void {
    this.setIFrameHeightSubject$.next(height);
  }

  private notifyInstallmentPlanChanged(eventData: InstallmentPlanChangedEvent): void {
    this.installmentPlanChangedSubject.next(eventData);
  }
}

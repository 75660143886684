<ng-container [ngSwitch]="layoutVariant$ | async">
  <ng-container *ngSwitchCase="layoutVariants.Default">
    <ng-container [ngTemplateOutlet]="defaultLayoutVariant"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="layoutVariants.Reverse">
    <ng-container [ngTemplateOutlet]="reverseLayoutVariant"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="layoutVariants.Inline">
    <ng-container [ngTemplateOutlet]="inlineLayoutVariant"></ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultLayoutVariant>
  <h3 class="item-name">{{ item.description }}</h3>

  <div class="item-description default-layout">
    <ng-container [ngTemplateOutlet]="cartImage"></ng-container>

    <div class="item-specs">
      <ng-container *ngIf="item.available">
        <ng-container>
          <ng-container [ngTemplateOutlet]="itemQuantity"></ng-container>

          <div *ngIf="customDeliveryDate$ | async as customDeliveryDate" class="item-spec">
            <div class="spec-label">
              <label class="spec-label__delivery">{{ 'Delivery' | translate }}:</label>
            </div>
            <div class="spec-value">
              <span>{{ customDeliveryDate | eswDate: 'dd MMMM' }}</span>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="itemColor"></ng-container>
          <ng-container [ngTemplateOutlet]="itemSize"></ng-container>
          <ng-container [ngTemplateOutlet]="itemComparablePrice"></ng-container>
        </ng-container>
        <ng-container [ngTemplateOutlet]="metadataItems"></ng-container>

        <ng-container>
          <div class="item-spec">
            <div class="spec-label price-spec-label">
              <label class="spec-label__price">{{ 'Price:' | translate }}</label>
            </div>
            <div class="spec-value spec-value--numeric item-price" [class.spec-value-discount]="itemHasDiscountAmount">
              <esw-currency [price]="item.price" freeTextKey="Free cart item"></esw-currency>
            </div>
          </div>
          <div class="item-spec" *ngIf="itemHasDiscountAmount">
            <div class="spec-label before-discount-spec-label"></div>
            <div class="spec-value spec-value--numeric spec-value-before-discount">
              <esw-currency [price]="item.priceBeforeDiscounts" freeTextKey="Free cart item"></esw-currency>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.available">
        <ng-container [ngTemplateOutlet]="notAvailableItem"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #reverseLayoutVariant>
  <h2 class="item-collection-name">{{ item?.title }}</h2>
  <h3 class="item-name">{{ item.description }}</h3>

  <div class="item-description item-description--reverse">
    <ng-container [ngTemplateOutlet]="cartImage"></ng-container>

    <div class="item-specs">
      <ng-container *ngIf="item.available">
        <ng-container>
          <div class="item-inline-spec">
            <span class="item-inline-spec__id">{{ item.id }}</span>
            <span class="item-inline-spec__status">
              {{ (item.isProductInStock ? 'In Stock' : 'Backordered') | translate }}
            </span>
          </div>
          <div *ngIf="customDeliveryDate$ | async as customDeliveryDate" class="custom-delivery-date">
            {{ 'Estimated Delivery' | translate: { date: customDeliveryDate | eswDate: 'dd MMMM' } }}
          </div>
          <ng-container [ngTemplateOutlet]="itemColor"></ng-container>
          <ng-container [ngTemplateOutlet]="itemSize"></ng-container>
          <ng-container [ngTemplateOutlet]="itemQuantity"></ng-container>
        </ng-container>
        <ng-container [ngTemplateOutlet]="metadataItems"></ng-container>

        <div class="item-reverse__container">
          <div class="item-spec">
            <div class="spec-value item-price" [class.spec-value-discount]="itemHasDiscountAmount">
              <esw-currency [price]="item.totalPrice" freeTextKey="Free cart item"></esw-currency>
            </div>
          </div>
          <div class="item-spec" *ngIf="item.quantity > 1 && item.price.value !== 0">
            <div class="spec-value item-price-each" [class.spec-value-discount]="itemHasDiscountAmount">
              <esw-currency [price]="item.price" freeTextKey="Free cart item"></esw-currency>
              <span>{{ 'Each' | translate }}</span>
            </div>
          </div>
          <div class="item-spec" *ngIf="itemHasDiscountAmount">
            <div class="spec-value spec-value-before-discount">
              <span>{{ 'Orig' | translate }}</span>
              <esw-currency [price]="item.priceBeforeDiscounts" freeTextKey="Free cart item"></esw-currency>
              <span *ngIf="item.quantity > 1">{{ 'Each' | translate }}</span>
            </div>
          </div>
          <div *ngIf="itemHasClearanceDiscount" class="item-discount-clearance item-spec">{{ 'Clearance' | translate }}</div>
          <ng-container *ngIf="nonClearanceDiscounts.length">
            <div class="item-spec">
              {{ (nonClearanceDiscounts.length > 1 ? 'Offers Applied' : 'Offer Applied') | translate }}
            </div>
            <div *ngFor="let discount of nonClearanceDiscounts" class="item-spec item-discount-promotion">
              {{ discount.description }}
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!item.available">
        <ng-container [ngTemplateOutlet]="notAvailableItem"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inlineLayoutVariant>
  <div class="item-description">
    <ng-container [ngTemplateOutlet]="cartImage"></ng-container>

    <div class="item-specs">
      <ng-container *ngIf="item.available">
        <ng-container>
          <h3 class="item-name">{{ item.description }}</h3>
          <ng-container>
            <div class="item-spec item-prices">
              <div *ngIf="itemHasDiscountAmount" class="spec-value spec-value--numeric spec-value-before-discount">
                <esw-currency [price]="item.priceBeforeDiscounts" freeTextKey="Free cart item"></esw-currency>
              </div>
              <div class="spec-value spec-value--numeric item-price" [class.spec-value-discount]="itemHasDiscountAmount">
                <esw-currency [price]="item.price" freeTextKey="Free cart item"></esw-currency>
              </div>
            </div>
          </ng-container>

          <ng-container [ngTemplateOutlet]="itemColor"></ng-container>
          <ng-container [ngTemplateOutlet]="itemSize"></ng-container>
          <ng-container [ngTemplateOutlet]="itemQuantity"></ng-container>
        </ng-container>
        <ng-container [ngTemplateOutlet]="metadataItems"></ng-container>
      </ng-container>

      <ng-container *ngIf="!item.available">
        <ng-container [ngTemplateOutlet]="notAvailableItem"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #cartImage>
  <div class="cart-image">
    <img [eswImgPreload]="item.imageUrl" [ngClass]="{ 'cart-image--unavailable': !item.available }" [alt]="item.title" />
  </div>
</ng-template>

<ng-template #metadataItems>
  <div class="item-spec item-metadata" *ngFor="let detail of visibleMetadataItems">
    <div class="spec-label">
      <label class="spec-label__{{ detail.name | lowercase }}">
        <ng-container *eswFeatureToggle="FeatureName.MetadataItemsNoTranslation">{{ detail.name }}</ng-container>
        <ng-container *eswFeatureToggle="'!' + FeatureName.MetadataItemsNoTranslation">{{ detail.name | translate }}</ng-container>
        <ng-container *eswFeatureToggle="'!' + FeatureName.CartItemLayoutReverse">:</ng-container>
      </label>
    </div>
    <div class="spec-value spec-value--line-clamping">
      <span>{{ detail.value }}</span>
    </div>
  </div>
</ng-template>

<ng-template #notAvailableItem>
  <div class="item--unavailable error">
    <i class="ion-alert-circled" aria-hidden="true"></i>
    <span>{{ 'This item is no longer available for purchase' | translate }}</span>
  </div>
</ng-template>

<ng-template #itemColor>
  <div class="item-spec item-color" *ngIf="item.color">
    <div class="spec-label">
      <label class="spec-label__color">{{ 'Colour:' | translate }}</label>
    </div>
    <div class="spec-value">
      <span>{{ item.color }}</span>
    </div>
  </div>
</ng-template>

<ng-template #itemSize>
  <div class="item-spec item-size" *ngIf="item.size">
    <div class="spec-label">
      <label class="spec-label__size">{{ 'Size:' | translate }}</label>
    </div>
    <div class="spec-value">
      <span>{{ item.size }}</span>
    </div>
  </div>
</ng-template>

<ng-template #itemComparablePrice>
  <div class="item-spec" *ngIf="comparablePrice as itemComparablePrice">
    <div class="spec-label">
      <label class="spec-label__size">{{ 'Comparable Price:' | translate }}</label>
    </div>
    <div class="spec-value item-comparable-price">
      <span>{{ itemComparablePrice }}</span>
    </div>
  </div>
</ng-template>

<ng-template #itemQuantity>
  <div class="item-spec item-quantity">
    <div class="spec-label">
      <label class="spec-label__quantity">{{ 'Quantity:' | translate }}</label>
    </div>
    <div class="spec-value spec-value--numeric">
      <span>{{ item.quantity }}</span>
    </div>
  </div>
</ng-template>

<div *ngIf="item.hasGiftMessage && isDetailsModalsAvailable" class="item-gift-message">
  <span (click)="openGiftMessageModal()" class="item-gift-message__title" role="button"> {{ 'Gift Message' | translate }}: </span>
  {{ giftMessage }}
</div>

<ng-container *ngIf="(layoutVariant$ | async) !== layoutVariants.Reverse">
  <div class="item-discount" *ngIf="itemHasDiscountTitle || itemHasDiscountDescription">
    <div class="discount__icon">
      <i class="ion-ios-checkmark success" aria-hidden="true"> </i>
    </div>
    <div class="discount-info">
      <div class="discount-info__title" *ngIf="itemHasDiscountTitle">
        <span>{{ itemDiscountTitle }}</span>
      </div>
      <div class="discount-info__description" [class.discount-info__small]="itemHasDiscountTitle" *ngIf="itemHasDiscountDescription">
        <span>{{ itemDiscountDescription }}</span>
      </div>
    </div>
  </div>
</ng-container>

<div class="additional-messages" *ngIf="hasAdditionalMessages">
  <div class="additional-messages__item" *ngFor="let message of additionalMessages">
    {{message}}
  </div>
</div>

<div *ngIf="(isViewMoreButtonVisible$ | async) && isDetailsModalsAvailable">
  <esw-button class="item-view-more spec-details" tertiary type="button" (click)="openSpecDetailsModal()">
    {{ 'View more' | translate }}
  </esw-button>
</div>

<ng-template #specDetailsModal let-modal>
  <esw-modal (close)="modal.close()">
    <esw-cart-item-details [item]="item" (backToCheckout)="modal.close()"></esw-cart-item-details>
  </esw-modal>
</ng-template>

<div *ngIf="item.productCombinedItems?.length && isDetailsModalsAvailable">
  <esw-button class="item-view-more sub-items-details" tertiary type="button" (click)="openSubItemsDetailsModal()">
    {{ 'View more' | translate }}
  </esw-button>
</div>

<ng-template #subItemsDetailsModal let-modal>
  <esw-modal (close)="modal.close()">
    <esw-sub-items-details [item]="item" (backToCheckout)="modal.close()"></esw-sub-items-details>
  </esw-modal>
</ng-template>

<hr class="item-divider" />

import { Action } from '@ngrx/store';

import { ContactInformationModel } from '@shared/model/contact-information.model';

export enum BillingContactInformationUpdateActionType {
  Update = '[BillingContactInformation] Update',
  UpdateSuccess = '[BillingContactInformation] Update Success',
  UpdateFailure = '[BillingContactInformation] Update Failure',
}

export class BillingContactInformationUpdate implements Action {
  readonly type = BillingContactInformationUpdateActionType.Update;
  constructor(public payload: ContactInformationModel) {}
}

export class BillingContactInformationUpdateSuccess implements Action {
  readonly type = BillingContactInformationUpdateActionType.UpdateSuccess;
  constructor(public payload: ContactInformationModel) {}
}

export class BillingContactInformationUpdateFailure implements Action {
  readonly type = BillingContactInformationUpdateActionType.UpdateFailure;
  constructor(public payload: Error) {}
}

export type BillingContactInformationUpdateActions =
  | BillingContactInformationUpdate
  | BillingContactInformationUpdateSuccess
  | BillingContactInformationUpdateFailure;

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CartItemDeliveryDateInformation } from '@app/shared/model/cart-item-delivery-date-information';
import { getSelectedDeliveryOption } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';
import { CONFIGURATION, Configuration } from '@configurations/configuration.token';
import { FeatureName } from '@configurations/feature-name.enum';
import { CartItem } from '@shared/model/cart-item.model';
import { IMetadataItem } from '@shared/model/metadata-item.model';

@Component({
  selector: 'esw-cart-item-details',
  templateUrl: './cart-item-details.component.html',
  styleUrls: ['./cart-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemDetailsComponent {
  @Input() item: CartItem;
  @Output() backToCheckout = new EventEmitter<void>();

  customDeliveryDate$: Observable<Date> = this.store.pipe(
    select(getSelectedDeliveryOption),
    map(
      (selectedDilveryOption: string) =>
        this.item.estimatedDeliveryDatesForDeliveryOptions?.find(
          (information: CartItemDeliveryDateInformation) => information.deliveryOption === selectedDilveryOption,
        )?.estimatedDeliveryDate,
    ),
  );

  FeatureName = FeatureName;
  get topMetadataItems(): IMetadataItem[] {
    return this.item.metadataItems
      .filter((item: IMetadataItem) => !this.configuration.metadataItemNamesNotVisible.includes(item.name))
      .slice(0, this.configuration.metadataItemsMaxNumberToDisplay);
  }

  get bottomMetadataItems(): IMetadataItem[] {
    const visibleItems = this.item.metadataItems.filter(
      (item: IMetadataItem) => !this.configuration.metadataItemNamesNotVisible.includes(item.name),
    );
    return visibleItems.slice(this.configuration.metadataItemsMaxNumberToDisplay, visibleItems.length);
  }

  constructor(private store: Store<IAppState>, @Inject(CONFIGURATION) public configuration: Configuration) {}

  onBackToCheckoutClick() {
    this.backToCheckout.emit();
  }
}

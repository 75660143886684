import { CountryIso, CountryModel } from '@esw/checkout-common-ui';

import { InternationalizationConfiguration } from '@configurations/i18n/i18n-configuration.token';
import { FooterLinkConfigurationModel } from '@shared/model/configurations/footer-link-configuration.model';
import { SocialMediaConfigurationModel } from '@shared/model/configurations/social-media-configuration.model';
import { AddressValidationConfiguration } from '@configurations/configuration.token';

export class BrandConfigurationModel {
  socialMediaConfigurations: Array<SocialMediaConfigurationModel> = [];
  footerLinkConfigurations: Array<FooterLinkConfigurationModel> = [];
  internationalization = new InternationalizationConfiguration();
  allCountries: Array<CountryModel> = [];
  billingCountries: Array<CountryModel> = [];
  retailerWebsiteUrl: string;
  backToCartUrl: string;
  continueShoppingUrl: string;
  registrationUrl: string;
  transliteratedCountriesIso: CountryIso[] = [];
  poBoxValidationAllowedCountries: CountryIso[] = [];
  addressValidation: Array<AddressValidationConfiguration> = [];
}

import { Injectable, NgZone } from '@angular/core';

import { Store } from '@ngrx/store';

import { IAppState } from '@app/store/app-init/app.state';
import { SetFeatureFlags } from '@app/store/feature-flag/feature-flag.actions';
import { WindowRefService } from '@core/windowref/windowref.service';

@Injectable()
export class GoogleOptimizeService {
  constructor(private ngZone: NgZone, private windowRef: WindowRefService, private store: Store<IAppState>) {}

  init() {
    this.registerCallbackForFeatureFlagsUpdate();
  }

  private registerCallbackForFeatureFlagsUpdate() {
    this.windowRef.nativeWindow.setFeatureToggles = (featureFlags: { [key: string]: boolean } = {}) => {
      this.ngZone.run(() => {
        this.store.dispatch(new SetFeatureFlags({ type: 'optimize', flags: featureFlags }));
      });
    };
  }
}

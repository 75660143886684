import { Action } from '@ngrx/store';

export enum PaymentOptionsGetActionType {
  Get = '[PaymentOptions] Get',
  GetSuccess = '[PaymentOptions] Get Success',
  GetFailure = '[PaymentOptions] Get Failure',
}

export class PaymentOptionsGet implements Action {
  readonly type = PaymentOptionsGetActionType.Get;
  constructor() {}
}

export class PaymentOptionsGetSuccess implements Action {
  readonly type = PaymentOptionsGetActionType.GetSuccess;
  constructor(public payload: { paymentUrl: string }) {}
}

export class PaymentOptionsGetFailure implements Action {
  readonly type = PaymentOptionsGetActionType.GetFailure;
  constructor(public payload: Response) {}
}

export type PaymentOptionsGetActions = PaymentOptionsGet | PaymentOptionsGetSuccess | PaymentOptionsGetFailure;

import { Action } from '@ngrx/store';

export enum BillingActionType {
  Initialize = '[Billing] initialize',
  ShowPaymentError = '[Billing] show payment error',
  HidePaymentError = '[Billing] hide payment error',
}

export class BillingInitializeAction implements Action {
  readonly type = BillingActionType.Initialize;
}

export class BillingShowPaymentErrorAction implements Action {
  readonly type = BillingActionType.ShowPaymentError;
}

export class BillingHidePaymentErrorAction implements Action {
  readonly type = BillingActionType.HidePaymentError;
}

export type BillingActions = BillingInitializeAction | BillingShowPaymentErrorAction | BillingHidePaymentErrorAction;

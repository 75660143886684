import { createSelector } from '@ngrx/store';

import { getMainState } from '../app-init/app-init.reducer';
import { IAppState } from '../app-init/app.state';
import { FeatureFlagsActions, FeatureFlagsActionType } from './feature-flag.actions';
import { FeatureName } from '@configurations/feature-name.enum';

export interface FeatureFlagState {
  base: { [key: string]: boolean };
  preorder: { [key: string]: boolean };
  optimize: { [key: string]: boolean };
}

export function featureFlagReducer(state: IAppState, action: FeatureFlagsActions): IAppState {
  switch (action.type) {
    case FeatureFlagsActionType.Set:
      const type = action.payload.type;
      return {
        ...state,
        featureState: {
          ...state.featureState,
          [type]: {
            ...(state.featureState || {})[type],
            ...action.payload.flags,
          },
        },
      };

    default:
      return state;
  }
}

export const getFeatureState = createSelector(getMainState, state => state.featureState || {});

export const getFeatureFlagState = createSelector(getFeatureState, (state: FeatureFlagState) => {
  const { base, preorder, optimize } = state;
  return {
    ...base,
    ...preorder,
    ...optimize,
  };
});

export const getFeatureIsEnabled = (featureName: FeatureName) =>
  createSelector(getFeatureFlagState, (featureState: { [key: string]: boolean }): boolean => featureState[featureName]);

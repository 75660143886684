import { CountryModel } from '@esw/checkout-common-ui';

export class AddressModel {
  id?: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  city: string;
  region?: string;
  postalCode: string;
  country = {} as CountryModel;
}

import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { TranslateInitializerService } from '@shared/services//translate-initializer.service';
import { TranslateLoaderService } from '@shared/services//translate-loader.service';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { OnlyCompletedOrdersGuard, OnlyOngoingOrdersGuard, OnlyPaymentOrdersGuard, OnlyValidOrdersGuard } from '@shared/services/guards';
import { ThemeInjectorService } from '@shared/services/theme-injector.service';
import { TimeOutService } from '@shared/services/time-out.service';

import { GoogleOptimizeService } from './analytics/google-optimize.service';
import { NotificationService } from './notification.service';
import { OrderService } from './order.service';

@NgModule({
  imports: [StoreModule],
  exports: [],
  declarations: [],
  providers: [
    ThemeInjectorService,
    AnalyticsService,
    TranslateInitializerService,
    TranslateLoaderService,
    OrderService,
    OnlyValidOrdersGuard,
    OnlyCompletedOrdersGuard,
    OnlyOngoingOrdersGuard,
    OnlyPaymentOrdersGuard,
    TimeOutService,
    NotificationService,
    GoogleOptimizeService,
  ],
})
export class SharedServicesModule {}

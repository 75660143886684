import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { EndPointRelation } from './endPointRelation';
import { IEnvironmentConfig } from './IEnvironmentConfig';

export const environment: IEnvironmentConfig = {
  production: true,
  backends: [
    {
      url: 'https://checkout-api.sandbox.eshopworld.com/api',
      rel: EndPointRelation.appBackend,
    },
  ],
  backendOrderApiVersion: 'v2',
  backendShipToStoreApiVersion: 'v2',
  backendAppConfigurationVersion: 'v3',

  appInsightsInstrumentalKey: 'f006f638-ddd1-4352-84e9-ef8b9e0030a6',
  analyticsGoogleTagManagerContainerId: 'GTM-NTK9K33',
  analyticsTealiumIQAccount: 'go-casual',
  analyticsTealiumIQProfile: 'go-casual',
  analyticsTealiumIQEnvironment: 'qa',
  addressCompleteKey: '',
  addressCompleteName: '',
  googleMapsApiKey: 'AIzaSyDXBvVcF9La7STXRpAqqTHUfx1uOcO2TnI',
  segmentWriteKey: '',
  expressPaymentsPanelUrl: 'https://payments-panel.sandbox.eshopworld.com',
  ravelinPublicKey: 'pk_live_LL3gdSY8oNOn4qah4Use1LcFAXBGZtZW',
  imports: [StoreDevtoolsModule.instrument()],
};

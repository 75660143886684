import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'esw-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @HostBinding('class.form__field') class = 'form__field';
  @HostBinding('class.esw-button') eswButton = true;
  @Input() type: 'submit' | 'reset' | 'button' = 'submit';
  @Input() state: string;
  @Input() secondary = false;
  @Input() tertiary = false;
  @Input() disabled = false;
}

import { Action } from '@ngrx/store';

import { OrderChargesUpdateModel } from '@shared/model/order-charges-update.model';
import { OrderChargesModel } from '@shared/model/order-charges.model';
import { ShippingInfoUpdateModel } from '@shared/model/shipping-info-update.model';

export enum ShippingInfoUpdateActionType {
  ShippingInfoUpdate = '[ShippingInfo] Update',
  ShippingInfoUpdateSuccess = '[ShippingInfo] Update Success',
  ShippingInfoUpdateFailure = '[ShippingInfo] Update Failure',
  MarketingEmailOptInUpdate = '[MarketingEmailOptIn] Update',
  MarketingEmailOptInUpdateSuccess = '[MarketingEmailOptIn] Update Success',
  MarketingEmailOptInUpdateFailure = '[MarketingEmailOptIn] Update Failure',
  OrderChargesUpdate = '[OrderCharges] Update',
  OrderChargesUpdateSuccess = '[OrderCharges] Update Success',
  OrderChargesUpdateFailure = '[OrderCharges] Update Failure',
}

export class ShippingInfoUpdate implements Action {
  readonly type = ShippingInfoUpdateActionType.ShippingInfoUpdate;
  constructor(public payload: ShippingInfoUpdateModel) {}
}

export class ShippingInfoUpdateSuccess implements Action {
  readonly type = ShippingInfoUpdateActionType.ShippingInfoUpdateSuccess;
  constructor(public payload: ShippingInfoUpdateModel) {}
}

export class ShippingInfoUpdateFailure implements Action {
  readonly type = ShippingInfoUpdateActionType.ShippingInfoUpdateFailure;
  constructor(public payload: Error) {}
}

export class MarketingEmailOptInUpdate implements Action {
  readonly type = ShippingInfoUpdateActionType.MarketingEmailOptInUpdate;
  constructor(public payload: boolean) {}
}

export class MarketingEmailOptInUpdateSuccess implements Action {
  readonly type = ShippingInfoUpdateActionType.MarketingEmailOptInUpdateSuccess;
  constructor(public payload: boolean) {}
}

export class MarketingEmailOptInUpdateFailure implements Action {
  readonly type = ShippingInfoUpdateActionType.MarketingEmailOptInUpdateFailure;
  constructor(public payload: Error) {}
}

export class OrderChargesUpdate implements Action {
  readonly type = ShippingInfoUpdateActionType.OrderChargesUpdate;
  constructor(public payload: OrderChargesUpdateModel) {}
}

export class OrderChargesUpdateSuccess implements Action {
  readonly type = ShippingInfoUpdateActionType.OrderChargesUpdateSuccess;
  constructor(public payload: OrderChargesModel) {}
}

export class OrderChargesUpdateFailure implements Action {
  readonly type = ShippingInfoUpdateActionType.OrderChargesUpdateFailure;
  constructor(public payload: Error) {}
}

export type ShippingInfoUpdateActions =
  | ShippingInfoUpdate
  | ShippingInfoUpdateSuccess
  | ShippingInfoUpdateFailure
  | MarketingEmailOptInUpdate
  | MarketingEmailOptInUpdateSuccess
  | MarketingEmailOptInUpdateFailure
  | OrderChargesUpdate
  | OrderChargesUpdateSuccess
  | OrderChargesUpdateFailure;

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { GenericErrorTemplateEnum } from '@app/errors/generic-error/generic-error-template.enum';
import { LayoutRoutesNames } from '@app/layout/layout.routes.names';
import { OrderInitializeAction } from '@app/store/app-init/app-init.actions';
import { getMainState } from '@app/store/app-init/app-init.reducer';
import { IAppState, ICheckoutState } from '@app/store/app-init/app.state';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { OrderStatus } from '@shared/model/order-status.enum';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

import { getRoutePath } from './navigation-helper';

@Injectable()
export class OnlyCompletedOrdersGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store<IAppState>, private appInsights: AppInsightsWrapperService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const orderIdCandidate = next.paramMap.get('orderId');
    const countryIsoCandidate = next.paramMap.get('countryIso');

    this.store.dispatch(new OrderInitializeAction(orderIdCandidate));

    return this.store.pipe(
      select(getMainState),
      filter((app) => !!app.appInitialized),
      map((app: IAppState) => app.checkout),
      map((checkout: ICheckoutState) => {
        if (!checkout || !checkout.order || checkout.order.status === null || checkout.order.status === undefined) {
          throw new Error('order badly formatted');
        }

        if (checkout.order.status === OrderStatus.BeingProcessed) {
          this.appInsights.trackEvent(AppInsightsEventName.CheckoutConfirmationOrderBeingProcessedEvent);
          return true;
        }

        if (checkout.order.status === OrderStatus.Completed) {
          this.appInsights.trackEvent(AppInsightsEventName.CheckoutConfirmationOrderCompletedEvent);
          return true;
        }

        if (checkout.order.status === OrderStatus.OnGoing || checkout.order.status === OrderStatus.PaymentInProgress) {
          this.appInsights.trackEvent(AppInsightsEventName.CheckoutLoadedEvent);
          this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));
        }

        if (checkout.order.status === OrderStatus.TimedOut) {
          this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]), {
            state: { template: GenericErrorTemplateEnum.ConfirmationPage },
          });
        }

        return false;
      }),
      catchError((a, b) => {
        this.router.navigate(getRoutePath(countryIsoCandidate, [LayoutRoutesNames.Error]));
        return of(false);
      }),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}

import { CountryIso } from '@esw/checkout-common-ui';

import { ContactDetailsResponseDto } from '@shared/api';
import { AddressModel } from '@shared/model/address.model';

export class AddressAdapter {
  public static createModel(contactDetailsResponse: ContactDetailsResponseDto): AddressModel {
    if (!contactDetailsResponse) {
      return null;
    }

    const model = new AddressModel();

    model.id = contactDetailsResponse.id;
    model.addressLine1 = contactDetailsResponse.address1;
    model.addressLine2 = contactDetailsResponse.address2;
    model.addressLine3 = contactDetailsResponse.address3;
    model.firstName = contactDetailsResponse.firstName;
    model.lastName = contactDetailsResponse.lastName;
    model.city = contactDetailsResponse.city;
    model.country.name = '';
    model.country.iso = contactDetailsResponse.country as CountryIso;
    model.postalCode = contactDetailsResponse.postalCode;
    model.region = contactDetailsResponse.region;

    return model;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/store/app-init/app.state';
import { FeatureName } from '@configurations/feature-name.enum';
import { Observable } from 'rxjs';
import { getFeatureFlagState } from '@app/store/feature-flag/feature-flag.reducer';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

@Pipe({
  name: 'featureFlagIs',
})
export class FeatureFlagIsPipe implements PipeTransform {

  constructor(private store: Store<IAppState>) {
  }

  transform(featureName: FeatureName, featureFlagIsEnabled: boolean): Observable<boolean> {
    return this.store.pipe(
      select(getFeatureFlagState),
      pluck(featureName),
      map((featureIsEnabled: boolean) => featureIsEnabled === featureFlagIsEnabled),
      distinctUntilChanged()
    );
  }

}

import { CheckoutThemeState, PaymentThemeState, ThemeState } from '@app/store/app-init/app.state';
import { ExternalStylesConfiguration } from '@configurations/external-styles-configuration.token';
import { FeatureName } from '@configurations/feature-name.enum';
import {
  AppConfigurationDto,
  AppConfigurationModel,
  ButtonStyle,
  FontModel,
  HeaderConfigurationModel,
  ThemeModelDto,
} from '@shared/model';

import { StylingModel } from '../model';

export const stylesPropertiesToCssVariablesMap: { [key in keyof StylingModel]: string } = {
  primaryColor: '--color-primary',
  primaryFontFamily: '--font-primary',
  buttonStyle: '--button-border-radius',
};

export const stylesPropertiesToPaymentCssVariablesMap: { [key in keyof StylingModel]: string } = {
  primaryColor: 'color-primary',
  primaryFontFamily: 'font-primary',
  buttonStyle: 'button-border-radius',
};

export const headerConfigurationToFeatureToggleMap: { [key in keyof HeaderConfigurationModel]: FeatureName } = {
  showCartIcon: FeatureName.BagTotalItemsEnabled,
};

export enum ButtonStyleValues {
  Square = '0',
  Round = '4px',
}

export class AppConfigurationAdapter {
  public static getAppConfigurationModel(
    remoteConfig: AppConfigurationDto,
    origin: string,
    externalStylesConfiguration: ExternalStylesConfiguration,
  ): AppConfigurationModel {
    if (!remoteConfig) {
      return {};
    }

    const appConfigurationModel: AppConfigurationModel = {
      countries: remoteConfig.countries,
      countriesInactiveForBilling: remoteConfig.countriesInactiveForBilling,
      theme: this.getThemeState(remoteConfig.theme, origin, externalStylesConfiguration),
      featureToggles: this.getFeatureFlags(remoteConfig),
    };
    return appConfigurationModel;
  }

  private static getThemeState(
    theme: ThemeModelDto | undefined,
    origin: string,
    externalStylesConfiguration: ExternalStylesConfiguration,
  ): ThemeState {
    if (!theme) {
      return { name: null };
    }

    const themeStateModel: ThemeState = {
      name: theme.name,
      checkout: this.getCheckoutState(theme),
      payment: this.getPaymentState(theme, origin, externalStylesConfiguration),
    };
    return themeStateModel;
  }

  private static getCheckoutState(theme: ThemeModelDto): CheckoutThemeState | undefined {
    const checkoutState: CheckoutThemeState = {
      cssVariables: this.getCheckoutCssVariablesList(theme.styling),
      fontUrls: this.getFontUrls(theme.styling),
      favicon: theme.styling?.favicon,
      logoUrl: theme.styling?.logo,
    };
    return checkoutState;
  }

  private static getPaymentState(
    theme: ThemeModelDto,
    origin: string,
    externalStylesConfiguration: ExternalStylesConfiguration,
  ): PaymentThemeState | undefined {
    const paymentState: PaymentThemeState = {
      cssVariables: this.getPaymentCssVariablesList(theme.styling),
      paymentPanelFontUrls: this.getFontUrls(theme.styling),
      cardPanelFontUrls: this.getFontUrls(theme.styling),
      paymentPanelThemeUrl: this.getExternalStyleUrl(theme.name, 'payment-panel', origin, externalStylesConfiguration),
      cardPanelThemeUrl: this.getExternalStyleUrl(theme.name, 'card-panel', origin, externalStylesConfiguration),
    };
    return paymentState;
  }

  private static getCheckoutCssVariablesList(styling: StylingModel | undefined): { [key: string]: string } | undefined {
    return this.getCssVariablesList(styling, stylesPropertiesToCssVariablesMap);
  }

  private static getPaymentCssVariablesList(styling: StylingModel | undefined): { [key: string]: string } | undefined {
    return this.getCssVariablesList(styling, stylesPropertiesToPaymentCssVariablesMap);
  }

  private static getCssVariablesList(
    styling: StylingModel | undefined,
    stylesMap: { [key in keyof StylingModel]: string },
  ): { [key: string]: string } | undefined {
    if (!styling) {
      return;
    }

    const cssVariablesList: { [key: string]: string } = {};

    Object.keys(styling).forEach((property: string) => {
      const propertyValue: FontModel | string | ButtonStyle = styling[property];
      const cssVariableName: string = stylesMap[property];

      if (propertyValue && cssVariableName) {
        if (property === 'buttonStyle') {
          cssVariablesList[cssVariableName] = propertyValue === ButtonStyle.Round ? ButtonStyleValues.Round : ButtonStyleValues.Square;
        } else if (typeof propertyValue === 'string') {
          cssVariablesList[cssVariableName] = propertyValue;
        } else if (this.instanceOfFontModel(propertyValue)) {
          cssVariablesList[cssVariableName] = propertyValue.name;
        }
      }
    });

    return cssVariablesList;
  }

  private static getFontUrls(styling: StylingModel): string[] {
    if (!styling) {
      return;
    }

    const fontUrls: string[] = [];

    Object.keys(styling).forEach((property: string) => {
      const propertyValue: string | FontModel | ButtonStyle | undefined = styling[property];

      if (propertyValue && this.instanceOfFontModel(propertyValue) && propertyValue.source) {
        fontUrls.push(propertyValue.source);
      }
    });

    return fontUrls;
  }

  private static instanceOfFontModel(input: string | FontModel | ButtonStyle): input is FontModel {
    return input instanceof Object && 'name' in input;
  }

  private static getFeatureFlags(config: AppConfigurationDto): { [key: string]: boolean } {
    const flags: { [key: string]: boolean } = {};

    if (config?.header) {
      Object.keys(headerConfigurationToFeatureToggleMap).forEach((property: string) => {
        const headerValue: boolean = !!config.header[property];
        const toggleName: FeatureName = headerConfigurationToFeatureToggleMap[property];

        if (headerValue) {
          flags[toggleName] = headerValue;
        }
      });
    }

    return flags;
  }

  private static getExternalStyleUrl(
    themeName: string,
    styleName: string,
    origin: string,
    externalStylesConfiguration: ExternalStylesConfiguration,
  ): string | undefined {
    if (!themeName) {
      return;
    }

    const hash = externalStylesConfiguration[themeName][styleName];
    return `${origin}/generated/external-styles/${themeName}/${styleName}.${hash}.css`;
  }
}

import { ExpressPaymentAttempt, ExpressUiIntegrationType } from '@esw/payments-express-panel';

import { IAppState } from '@app/store/app-init/app.state';
import { ExpressPaymentsActions, ExpressPaymentsActionType } from '@app/store/express-payments/express-payments.actions';
import { LayoutActions, LayoutActionType } from '@app/store/layout/layout.actions';
import { ContactInformationModel } from '@shared/model/contact-information.model';

export interface ExpressPaymentsState {
  expressPaymentId: string;
  expressPaymentMethodLogoUrl: string;
  expressUiIntegrationType: ExpressUiIntegrationType;
  shippingContactInfo: ContactInformationModel | null;
  authorizeExpressPaymentIsLoading: boolean;
  expressPaymentErrorIsVisible: boolean;
  currentExpressPaymentAttempt: ExpressPaymentAttempt | null;
  expressPaymentPanelIsInitialized: boolean;
  ravelinDeviceId: string;
}

export const expressPaymentsInitialState: ExpressPaymentsState = {
  expressPaymentId: null,
  expressPaymentMethodLogoUrl: null,
  expressUiIntegrationType: null,
  shippingContactInfo: null,
  authorizeExpressPaymentIsLoading: false,
  expressPaymentErrorIsVisible: false,
  currentExpressPaymentAttempt: null,
  expressPaymentPanelIsInitialized: false,
  ravelinDeviceId: null,
};

export function expressPaymentsReducer(state: IAppState, action: ExpressPaymentsActions | LayoutActions): IAppState {
  switch (action.type) {
    case ExpressPaymentsActionType.SetExpressPaymentId:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressPaymentId: action.expressPaymentId,
        },
      };

    case ExpressPaymentsActionType.SetExpressPaymentMethodLogo:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressPaymentMethodLogoUrl: action.expressPaymentMethodLogoUrl,
        },
      };

    case ExpressPaymentsActionType.SetExpressPaymentUiIntegrationType:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressUiIntegrationType: action.expressUiIntegrationType,
        },
      };

    case ExpressPaymentsActionType.SetExpressPaymentShippingContactInfo:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          shippingContactInfo: action.shippingContactInfo,
        },
      };

    case ExpressPaymentsActionType.SetExpressPaymentRavelinDeviceId:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          ravelinDeviceId: action.ravelinDeviceId,
        },
      };

    case ExpressPaymentsActionType.AuthorizeExpressPayment:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          authorizeExpressPaymentIsLoading: true,
        },
      };

    case ExpressPaymentsActionType.AuthorizeExpressPaymentFailure:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          authorizeExpressPaymentIsLoading: false,
          expressPaymentErrorIsVisible: true,
          expressPaymentId: null,
        },
      };

    case ExpressPaymentsActionType.AuthorizeExpressPaymentSuccess:
      return {
        ...state,
        checkout: { ...state.checkout, order: action.order },
        expressPaymentsState: {
          ...state.expressPaymentsState,
          authorizeExpressPaymentIsLoading: false,
          expressPaymentErrorIsVisible: false,
        },
      };

    case ExpressPaymentsActionType.ExpressPaymentAttempt:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          currentExpressPaymentAttempt: action.paymentAttempt,
        },
      };

    case ExpressPaymentsActionType.ExpressPaymentPanelInitialized:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressPaymentPanelIsInitialized: true,
        },
      };

    case LayoutActionType.MoveToPaymentEdit:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressPaymentErrorIsVisible: false,
        },
      };

    case ExpressPaymentsActionType.SetExpressPaymentErrorIsVisible:
      return {
        ...state,
        expressPaymentsState: {
          ...state.expressPaymentsState,
          expressPaymentErrorIsVisible: action.isVisible,
        },
      };

    default:
      return state;
  }
}

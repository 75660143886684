import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';

import { LANGUAGE_CONFIGURATION, LanguageConfiguration } from '@configurations/i18n/language-configuration.token';

@Injectable()
export class TranslateLoaderService implements TranslateLoader {
  private readonly PREFIX = './generated/i18n/lang/lang.';
  private readonly SUFFIX = '.json';
  private translateHttpLoader: TranslateHttpLoader;

  constructor(http: HttpClient, @Inject(LANGUAGE_CONFIGURATION) private languageConfiguration: LanguageConfiguration) {
    this.translateHttpLoader = new TranslateHttpLoader(http, this.PREFIX, this.SUFFIX);
  }

  getTranslation(lang: string): Observable<any> {
    return this.translateHttpLoader.getTranslation(`${lang}.${this.languageConfiguration.hashList[lang]}`);
  }
}

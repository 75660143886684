import { Action } from '@ngrx/store';

import { FeatureFlagPayload } from './feature-flag-payload.model';

export enum FeatureFlagsActionType {
  Set = '[FeatureFlag] set flags',
}

export class SetFeatureFlags implements Action {
  readonly type = FeatureFlagsActionType.Set;
  constructor(public payload: FeatureFlagPayload) {}
}

export type FeatureFlagsActions = SetFeatureFlags;

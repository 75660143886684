import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { WindowRefService } from '@app/core';
import { CONFIGURATION, Configuration } from '@configurations/configuration.token';
import { FeatureName } from '@configurations/feature-name.enum';
import { CartItems } from '@shared/model';
import { BrandConfigurationService } from '@shared/services';

@Component({
  selector: 'esw-restricted-cart-items-modal',
  templateUrl: './restricted-cart-items-modal.component.html',
  styleUrls: ['./restricted-cart-items-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictedCartItemsModalComponent {
  FeatureName = FeatureName;
  @Input() restrictedCartItems: CartItems;
  @Input() cartDiscountsRemoved: boolean;
  @Input() allItemsRestricted: boolean;

  constructor(
    public modal: NgbActiveModal,
    private brandConfigurationService: BrandConfigurationService,
    private window: WindowRefService,
    @Inject(CONFIGURATION) public configuration: Configuration,
  ) {}

  redirectToCart() {
    const backToCartUrl = this.brandConfigurationService.getBrandConfiguration().backToCartUrl;
    this.window.nativeWindow.location.href = backToCartUrl;
  }
}

import { Configuration, MetadataItemToDataLayer } from '@configurations/configuration.token';
import { CartItem, IMetadataItem, OrderModel } from '@shared/model';
import { AnalyticsModel, IAnalyticsPromoCode, IEcommerceModel, IEcommerceProductModel } from '@shared/services/analytics/analytics.model';

import { OrderDiscountModel } from '../model/order-discount.model';
import { PromoCode } from '../model/promo-code.model';
import { OrderChargesModel } from '@shared/model/order-charges.model';

export class AnalyticsAdapter {
  public static createAnalytics(order: OrderModel): AnalyticsModel {
    const category = 'сheckout';
    const page = 'checkout';
    const clientProfileId = order.shopperCheckoutExperience?.registeredProfileId;
    const analytics: AnalyticsModel = <AnalyticsModel>{
      page: page,
      category: category,
      checkoutBillingAddress: order.useDeliveryAsPaymentAddress,
      deliveryCountryIso: order.deliveryCountryIso,
      checkoutCurrency: order.shopperCurrencyIso,
      checkoutLanguage: order.shopperCheckoutExperience.shopperCultureLanguageIso,
      deliveryOption: order.deliveryOption,
      retailerCartId: order.retailerCartId,
      customerType: clientProfileId ? 'Member' : 'New',
      discountAmount: 0,
    };

    if (order.discounts?.length) {
      analytics.discountAmount = order.discounts
        .map((discount: OrderDiscountModel) => (discount.amount ? discount.amount.value : 0))
        .reduce((total: number, value: number) => total + value, 0);
    }

    if (order.promoCodes?.length) {
      analytics.retailerPromoCodes = order.promoCodes.map((promo: PromoCode) => <IAnalyticsPromoCode>{ promoCode: promo.code });
    }

    if (order.discountsRetailerCurrency) {
      analytics.orderDiscountAmountRetailerCurrency =
        order.discountsRetailerCurrency
          .map((discount: OrderDiscountModel) => (discount.amount ? discount.amount.value : 0))
          .reduce((total: number, value: number) => total + value, 0) || 0;
    }

    return analytics;
  }

  public static createEcommercePageLoad(
    analyticsModel: AnalyticsModel,
    order: OrderModel,
    metadataItemsToDataLayer: MetadataItemToDataLayer[],
    isAlternativeEcommerceFlow: boolean,
  ): IEcommerceModel {
    return {
      checkout: {
        actionField: {
          step: isAlternativeEcommerceFlow ? 2 : 1,
          option: `Customer Type: ${analyticsModel.customerType}`,
        },
        products: AnalyticsAdapter.createEcommerceProducts(order, metadataItemsToDataLayer),
      },
    };
  }

  public static createEcommerceDeliveryAddress(
    analyticsModel: AnalyticsModel,
    order: OrderModel,
    metadataItemsToDataLayer: MetadataItemToDataLayer[],
    isAlternativeEcommerceFlow: boolean,
  ): IEcommerceModel {
    return {
      currencyCode: analyticsModel.checkoutCurrency,
      checkout: {
        actionField: {
          step: isAlternativeEcommerceFlow ? 3 : 2,
          option: order.deliveryOption,
        },
        products: AnalyticsAdapter.createEcommerceProducts(order, metadataItemsToDataLayer),
      },
    };
  }

  public static createEcommercePaymentAttempt(
    analyticsModel: AnalyticsModel,
    order: OrderModel,
    paymentMethod: string,
    metadataItemsToDataLayer: MetadataItemToDataLayer[],
    isAlternativeEcommerceFlow: boolean,
  ): IEcommerceModel {
    return this.createEcommercePayment(analyticsModel, isAlternativeEcommerceFlow ? 4 : 3, paymentMethod, order, metadataItemsToDataLayer);
  }

  public static createEcommercePaymentSuccess(
    analyticsModel: AnalyticsModel,
    order: OrderModel,
    paymentMethod: string,
    metadataItemsToDataLayer: MetadataItemToDataLayer[],
    isAlternativeEcommerceFlow: boolean,
  ): IEcommerceModel {
    return this.createEcommercePayment(analyticsModel, isAlternativeEcommerceFlow ? 5 : 4, paymentMethod, order, metadataItemsToDataLayer);
  }

  public static createEcommerceOrderConfirmation(
    analyticsModel: AnalyticsModel,
    order: OrderModel,
    configuration: Configuration,
    revenueAsItemsAfterDiscount: boolean = false,
  ): IEcommerceModel {
    const result: IEcommerceModel = {
      currencyCode: analyticsModel.checkoutCurrency,
      purchase: {
        actionField: {
          id: order.retailerCartId,
          affiliation: configuration.brandName,
          revenue: revenueAsItemsAfterDiscount ? order.charges.itemsAfterDiscount.value : order.charges.items.value,
          tax: order.charges.tax ? order.charges.tax.value : null,
          shipping: order.charges.shipping.value,
          coupon: order.promoCodes ? order.promoCodes.map((promo: PromoCode) => promo.code).join(';') : null,
        },
        products: AnalyticsAdapter.createEcommerceProducts(order, configuration.metadataItemToDataLayer),
      },
    };

    const revenueRetailerCurrency: number = this.getRevenueRetailerCurrency(order.charges, revenueAsItemsAfterDiscount);
    if (revenueRetailerCurrency) {
      result.purchase.actionField.revenueRetailerCurrency = revenueRetailerCurrency;
      result.purchase.actionField.retailerCurrencyCode = order.retailerCurrencyIso;
    }

    if (order.charges.total && order.charges.totalRetailerCurrency) {
      result.purchase.actionField.total = order.charges.total.value;
      result.purchase.actionField.totalRetailerCurrency = order.charges.totalRetailerCurrency.value;
    }

    return result;
  }

  private static getRevenueRetailerCurrency(charges: OrderChargesModel, revenueAsItemsAfterDiscount: boolean): number {
    return revenueAsItemsAfterDiscount
      ? charges.itemsRetailerCurrency?.value
      : charges.totalLineItemBeforeDiscountsRetailerCurrency?.value ||
          charges.totalBeforeCartDiscountsRetailerCurrency?.value ||
          charges.itemsRetailerCurrency?.value;
  }

  private static createEcommercePayment(
    analyticsModel: AnalyticsModel,
    step: number,
    paymentMethod: string,
    order: OrderModel,
    metadataItemsToDataLayer: MetadataItemToDataLayer[],
  ) {
    return {
      currencyCode: analyticsModel.checkoutCurrency,
      checkout: {
        actionField: {
          step,
          option: `Selected PayMethod: '${paymentMethod}'`,
        },
        products: AnalyticsAdapter.createEcommerceProducts(order, metadataItemsToDataLayer),
      },
    };
  }

  private static createEcommerceProducts(order: OrderModel, metadataItemsToDataLayer: MetadataItemToDataLayer[]): IEcommerceProductModel[] {
    const products = order.cart.map((cartItem: CartItem) => {
      const product: IEcommerceProductModel = {
        id: cartItem.id,
        name: cartItem.description,
        title: cartItem.title,
        category: null, // TODO: add api
        price: cartItem.price.value.toString(),
        quantity: cartItem.quantity,
        style: null, // TODO: add for custom retailers
        colour: cartItem.color,
        size: cartItem.size,
        imageURL: cartItem.imageUrl,
        ProductURL: null, // TODO: verify
      };

      if (cartItem.discounts?.length) {
        product.discountAmount = cartItem.discounts
          .map((discount: OrderDiscountModel) => (discount.amount ? discount.amount.value : 0))
          .reduce((total: number, value: number) => total + value, 0);
      }

      product.onSale = product.discountAmount ? 'Y' : 'N';

      if (cartItem.metadataItems?.length && metadataItemsToDataLayer?.length) {
        metadataItemsToDataLayer.forEach((metadataItem: MetadataItemToDataLayer) => {
          const item: IMetadataItem = cartItem.metadataItems.find((i) => i.name === metadataItem.orderName);
          if (item) {
            product[metadataItem.dataLayerName] = item.value;
          }
        });
      }

      if (cartItem.discountsRetailerCurrency && cartItem.priceRetailerCurrency) {
        product.itemDiscountAmountRetailerCurrency = cartItem.discountsRetailerCurrency
          .map((discount: OrderDiscountModel) => (discount.amount ? discount.amount.value : 0))
          .reduce((total: number, value: number) => total + value, 0);
        product.priceRetailerCurrency = cartItem.priceRetailerCurrency.value.toString();
      }

      return product;
    });

    return products;
  }
}

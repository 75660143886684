export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  NotFound = 404,
  Gone = 410,
  InternalServerError = 500,
}

export const HttpStatusText: { [key: number]: string } = {
  [HttpStatusCode.Ok]: 'Ok',
  [HttpStatusCode.Created]: 'Created',
  [HttpStatusCode.BadRequest]: 'Bad Request',
  [HttpStatusCode.NotFound]: 'Not Found',
  [HttpStatusCode.Gone]: 'Gone',
  [HttpStatusCode.InternalServerError]: 'Server Error',
};

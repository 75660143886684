import { Injectable, NgZone } from '@angular/core';

import { WindowRefService } from '@core/windowref/windowref.service';

@Injectable()
export class ScrollingService {
  constructor(private windowRef: WindowRefService, private ngZone: NgZone) {}

  public scrollToElement(el: HTMLElement, duration = 500, offset = 0) {
    const viewportOffset = el.getBoundingClientRect();
    const offsetTop = viewportOffset.top + window.pageYOffset;

    this.ngZone.runOutsideAngular(() => this.doScrolling(offsetTop + offset, duration));
  }

  private doScrolling(elementY, duration) {
    const window = this.windowRef.nativeWindow;
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    window.requestAnimationFrame(function step(timestamp) {
      start = start || timestamp;

      const time = timestamp - start;
      const percent = duration ? Math.min(time / duration, 1) : 1;

      window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }
}

import { MoneyModel } from '@shared/model/money.model';

export class OrderChargesModel {
  items: MoneyModel;
  itemsRetailerCurrency?: MoneyModel;
  itemsAfterDiscount?: MoneyModel;
  totalLineItemBeforeDiscountsRetailerCurrency?: MoneyModel;
  shipping: MoneyModel;
  tax: MoneyModel;
  duty?: MoneyModel;
  discount: MoneyModel;
  total: MoneyModel;
  totalRetailerCurrency?: MoneyModel;
  totalBeforeDiscounts: MoneyModel;
  totalBeforeCartDiscountsRetailerCurrency?: MoneyModel;
}

import { Injectable, NgZone } from '@angular/core';

import { Observable, Subscription, timer } from 'rxjs';

import { NotificationCode } from '../model/notification-code.enum';
import { NotificationService } from './notification.service';

@Injectable()
export class TimeOutService {
  private timeoutSeconds = 5;
  private timerSubscription: Subscription;
  private timer: Observable<number>;

  constructor(private ngZone: NgZone, private notificationService: NotificationService) {}

  public setTimeOut(interval: number): void {
    this.timeoutSeconds = interval;
  }

  public getTimeOut(): number {
    return this.timeoutSeconds;
  }

  public startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer = timer(this.timeoutSeconds * 1000);
    /* This needs to run outside the angular zone so that it does not block protractor.
     * By running outside the angular zone, angular does not wait for the promise before
     * notifying it is stable / synchronised. The inner run function tells angular that it
     * should synchonise that content and will update the UI with changes.
     */
    this.ngZone.runOutsideAngular(() => {
      this.timerSubscription = this.timer.subscribe((n) => {
        this.ngZone.run(() => {
          this.timerComplete(n);
        });
      });
    });
  }

  public stopTimer() {
    if (this?.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public timerComplete(n: number) {
    this.notificationService.notify(NotificationCode.OrderExpired);
  }
}

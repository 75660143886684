import { IAnalyticConfig } from '@core/IAnalyticConfig';
import { IAnalyticInitializer } from '@shared/services/analytics/ianalytic-initializer';

/* tslint:disable */
// I've disabled it to keep the 'original' script
export class TealiumIQInitializerService implements IAnalyticInitializer {
  configuration: IAnalyticConfig;

  init() {
    if (!this.configuration.tealiumIQCountriesSpecific || !this.configuration.tealiumIQCountriesSpecific.length) {
      this.loadScript(this.configuration.tealiumIQAccount, this.configuration.tealiumIQProfile, this.configuration.tealiumIQEnvironment);
    }
  }

  initForCountry(countryIso: string) {
    if (!this.configuration.tealiumIQCountriesSpecific || !this.configuration.tealiumIQCountriesSpecific.length) {
      return;
    }

    let account = this.configuration.tealiumIQAccount;
    let profile = this.configuration.tealiumIQProfile;
    const countrySpecificConfig = this.configuration.tealiumIQCountriesSpecific.find(c => c.countriesIso.includes(countryIso));

    if (countrySpecificConfig) {
      account = countrySpecificConfig.account;
      profile = countrySpecificConfig.profile;
    }

    this.loadScript(account, profile, this.configuration.tealiumIQEnvironment);
  }

  private loadScript(account: string, profile: string, environment: string) {
    window['utag_data'] = {};

    (function(a, b, c, d) {
      a = `//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js`;
      b = document;
      c = 'script';
      d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      a = b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d, a);
    })();
  }
}

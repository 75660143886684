import { MoneyAdapter } from '@shared/adapters/money.adapter';
import { OrderOrderDiscountResponseDto, PriceInfoResponseDto } from '@shared/api';
import { OrderDiscountModel } from '@shared/model/order-discount.model';

export interface ShopperRetailerDiscount {
  shopperCurrencyPrices: OrderDiscountModel;
  retailerCurrencyPrices?: OrderDiscountModel;
}

export class OrderDiscountAdapter {
  public static createOrderDiscounts(orderDiscounts: OrderOrderDiscountResponseDto[]): ShopperRetailerDiscount[] {
    return (orderDiscounts || []).map((discount) => {
      const discountModel: ShopperRetailerDiscount = {
        shopperCurrencyPrices: this.createModel(discount?.shopperCurrencyOrderDiscountAmount),
      };
      if (discount?.retailerCurrencyOrderDiscountAmount) {
        discountModel.retailerCurrencyPrices = this.createModel(discount.retailerCurrencyOrderDiscountAmount);
      }
      return discountModel;
    });
  }

  private static createModel(orderDiscount: PriceInfoResponseDto): OrderDiscountModel {
    const model = new OrderDiscountModel();
    if (orderDiscount) {
      model.title = orderDiscount.title;
      model.description = orderDiscount.description;
      if (orderDiscount.discountAmount) {
        model.amount = MoneyAdapter.createModel(orderDiscount.discountAmount);
        // negate amount for display
        model.amount.value = model.amount.value * -1;
      }
    }
    return model;
  }
}

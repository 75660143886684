import { EnumExtractor } from '@shared/utils';

export enum EndPointRelation {
  appBackend,
}
export namespace EndPointRelationNamespace {
  export function toArray(): Array<{ name: string; value: EndPointRelation }> {
    return EnumExtractor.toArray<EndPointRelation>(EndPointRelation);
  }
}

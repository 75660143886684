import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OrderChargesComponent } from '@app/cart/order-charges/order-charges.component';
import { PromoCodesComponent } from '@app/cart/promo-codes/promo-codes.component';
import { SharedUIModule } from '@shared/ui';

import { CartItemDetailsComponent } from './cart-item/cart-item-details/cart-item.details.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { SubItemsDetailsComponent } from './cart-item/sub-items-details/sub-items-details.component';
import { CartMessageComponent } from './cart-message/cart-message.component';
import { CartComponent } from './cart.component';
import { GiftMessageModalComponent } from './cart-item/gift-message-modal/gift-message-modal.component';

@NgModule({
  imports: [SharedUIModule, CommonModule],
  declarations: [
    CartComponent,
    CartMessageComponent,
    CartItemComponent,
    CartItemDetailsComponent,
    SubItemsDetailsComponent,
    OrderChargesComponent,
    PromoCodesComponent,
    GiftMessageModalComponent,
  ],
  exports: [CartComponent, CartItemComponent],
})
export class CartModule {}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IGlobalAppSettings } from '@app/core';
import {
  EXTERNAL_STYLES_CONFIGURATION,
  ExternalStylesConfiguration,
} from '@configurations/external-styles-configuration.token';

import { AppConfigurationAdapter } from '../adapters/app-config.adapter';
import { DisplayConfigurationDto } from '../api/app-configuration/src/models';
import { AppConfigurationDto, AppConfigurationModel } from '../model';
import { DomWrapperService } from './dom-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configUrl: string;
  constructor(
    settings: IGlobalAppSettings,
    private http: HttpClient,
    private domWrapperService: DomWrapperService,
    @Inject(EXTERNAL_STYLES_CONFIGURATION) private externalStylesConfiguration: ExternalStylesConfiguration,
  ) {
    this.configUrl = `${settings.backendApiUrl}/${settings.backendAppConfigurationVersion}/Configuration/Display`;
  }

  getRemoteConfig(): Observable<AppConfigurationModel> {
    return this.http
      .get<DisplayConfigurationDto>(this.configUrl)
      .pipe(
        map((response: AppConfigurationDto) =>
          AppConfigurationAdapter.getAppConfigurationModel(response, this.domWrapperService.origin, this.externalStylesConfiguration),
        ),
      );
  }
}

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { WindowRefService } from '@core/windowref/windowref.service';
import { NotificationCode } from '@shared/model/notification-code.enum';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'esw-order-completed-modal',
  templateUrl: './order-completed-modal.component.html',
  styleUrls: ['./order-completed-modal.component.scss'],
})
export class OrderCompletedModalComponent implements OnDestroy {
  @ViewChild('modal', { static: true })
  private dialogElementRef: ElementRef;
  private notificationSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private windowRefService: WindowRefService,
  ) {
    this.notificationSubscription = this.notificationService.notification$
      .pipe(filter(code => code === NotificationCode.OrderAlreadyCompleted))
      .subscribe(() => {
        this.openDialog();
      });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  openDialog(): void {
    this.modalService.open(this.dialogElementRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  redirectToOrderConfirmation(): void {
    this.windowRefService.nativeWindow.location.reload();
  }
}

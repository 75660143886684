<ng-template #modal>
  <esw-modal
    [title]="'Session expired' | translate"
    iconClass="ion-android-alert"
    [buttonText]="'Back to cart' | translate"
    (buttonClick)="redirectToCart()"
  >
    <div class="timeout-container">
      {{ 'Your session has expired' | translate }}
    </div>
  </esw-modal>
</ng-template>

import { registerLocaleData } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { LayoutState } from '@app/store/app-init/app.state';
import { LayoutChangeLanguageAction } from '@app/store/layout/layout.actions';
import { INTERNATIONALIZATION_CONFIGURATION, InternationalizationConfiguration } from '@configurations/i18n/i18n-configuration.token';
import { ILanguagesToCountriesSpecificConfig } from '@core/ILanguagesToCountriesSpecificConfig';
import { LocalStorageKey } from '@shared/model/local-storage-key.enum';
import { LocalStorageWrapperService } from '@shared/services/local-storage-wrapper/local-storage-wrapper.service';
import { ALL_COUNTRIES_ARE_ALLOWED } from '@shared/utils';

export const LOCALES_TO_LANGUAGE_MAP = {
  'zh-CN': 'zh-Hans',
  'zh-SG': 'zh-Hans',
  'zh-CHS': 'zh-Hans',
  'zh-HK': 'zh-Hant',
  'zh-TW': 'zh-Hant',
  'zh-MO': 'zh-Hant',
  'zh-CHT': 'zh-Hant',
  'es-MX': 'es-MX',
  'fr-CA': 'fr-CA',
};

@Injectable()
export class TranslateInitializerService {
  private isInitialized = false;

  constructor(
    private translateService: TranslateService,
    private store: Store<LayoutState>,
    @Inject(INTERNATIONALIZATION_CONFIGURATION)
    private internationalizationConfiguration: InternationalizationConfiguration,
    private localStorageWrapperService: LocalStorageWrapperService,
  ) {}

  init(orderLanguageIso: string, deliveryCountryIso?: string): void {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;
    const orderLanguage = this.getLanguageFromIso(orderLanguageIso);
    let supportedLanguagesByCountriesIso: ILanguagesToCountriesSpecificConfig = deliveryCountryIso
      ? this.getSupportedLanguagesByCountry(deliveryCountryIso, this.internationalizationConfiguration.supportedLanguagesByCountriesIso)
      : this.getGenerallySupportedLanguages(this.internationalizationConfiguration.supportedLanguagesByCountriesIso);
    if (!supportedLanguagesByCountriesIso) {
      supportedLanguagesByCountriesIso = this.internationalizationConfiguration.supportedLanguagesByCountriesIso[0];
    }
    const supportedLanguages: string[] = supportedLanguagesByCountriesIso.supportedLanguages;

    this.translateService.addLangs(supportedLanguages);

    const defaultLanguage = supportedLanguagesByCountriesIso.defaultLanguage || supportedLanguagesByCountriesIso.supportedLanguages[0];
    const languageToUse = this.evaluateLanguageToUse(supportedLanguages, defaultLanguage, orderLanguage);

    this.translateService.use(languageToUse);
    this.loadLocaleData(languageToUse);
  }

  initWithDefault() {
    const storedLang = this.localStorageWrapperService.getItem(LocalStorageKey.Language);
    this.init(storedLang);
  }

  loadLocaleData(lang: string) {
    // DO NOT REMOVE: Next comment used by webpack at build time to exclude locales from the main bundle.
    // See https://webpack.js.org/api/module-methods/#import-

    /* webpackExclude(/\.*\.js$/) */
    import(`@angular/common/locales/${lang}.js`).then((locale: any) => {
      registerLocaleData(locale.default);

      this.store.dispatch(new LayoutChangeLanguageAction(lang));
    });
  }

  private evaluateLanguageToUse(supportedLanguages: Array<string>, defaultLanguage: string, orderLanguage: string): string {
    if (orderLanguage && this.isLanguageSupported(supportedLanguages, orderLanguage)) {
      return orderLanguage;
    }

    return defaultLanguage;
  }

  private isLanguageSupported(supportedLanguages: Array<string>, language: string) {
    const isValid = supportedLanguages.indexOf(language) >= 0;

    return isValid;
  }

  private getLanguageFromIso(langIso: string): string {
    if (langIso && Object.keys(LOCALES_TO_LANGUAGE_MAP).includes(langIso)) {
      return LOCALES_TO_LANGUAGE_MAP[langIso];
    }
    return langIso?.split('-')[0] || '';
  }

  private getSupportedLanguagesByCountry(
    deliveryCountryIso: string,
    supportedLanguagesByCountriesIso: ILanguagesToCountriesSpecificConfig[],
  ): ILanguagesToCountriesSpecificConfig {
    return supportedLanguagesByCountriesIso.find(
      (slc: ILanguagesToCountriesSpecificConfig) =>
        slc.countriesIso.includes(deliveryCountryIso) || slc.countriesIso.indexOf(ALL_COUNTRIES_ARE_ALLOWED) !== -1,
    );
  }

  private getGenerallySupportedLanguages(
    supportedLanguagesByCountriesIso: ILanguagesToCountriesSpecificConfig[],
  ): ILanguagesToCountriesSpecificConfig {
    const generallySupportedLanguages: string[] = [];

    supportedLanguagesByCountriesIso.forEach((slc: ILanguagesToCountriesSpecificConfig) => {
      generallySupportedLanguages.push(...slc.supportedLanguages.filter((sl) => !generallySupportedLanguages.includes(sl)));
    });
    return {
      countriesIso: [],
      supportedLanguages: generallySupportedLanguages,
    };
  }
}

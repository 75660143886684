import { InjectionToken } from '@angular/core';

export const URLSET = new InjectionToken<UrlSetConfiguration>('url-set');

export class UrlSocialMediaConfiguration {
  url: string;
}

export class SocialMediaConfiguration {
  facebook?: UrlSocialMediaConfiguration;
  youtube?: UrlSocialMediaConfiguration;
  instagram?: UrlSocialMediaConfiguration;
  twitter?: UrlSocialMediaConfiguration;
  pinterest?: UrlSocialMediaConfiguration;
  snapchat?: UrlSocialMediaConfiguration;
}

export enum StaticLinkKey {
  EShopWorld = 'eShopWorld',
  TermsAndConditions = 'termsAndConditions',
  PrivacyPolicy = 'privacyPolicy',
  CookiePolicy = 'cookiePolicy',
  RefundPolicy = 'refundPolicy',
  Copyright = 'copyright',
  Careers = 'careers',
  Press = 'press',
  ContactUs = 'contactUs',
  Logo = 'logo',
  Registration = 'registration',
  AdditionalLink = 'additionalLink',
}

export class UrlConfiguration {
  [StaticLinkKey.EShopWorld]: string;
  [StaticLinkKey.TermsAndConditions]: string;
  [StaticLinkKey.PrivacyPolicy]: string;
  [StaticLinkKey.Logo]: string;
  [StaticLinkKey.Registration]?: string;
  [StaticLinkKey.ContactUs]?: string;
  [StaticLinkKey.Press]?: string;
  [StaticLinkKey.Careers]?: string;
  [StaticLinkKey.Copyright]?: string;
  [StaticLinkKey.RefundPolicy]?: string;
  [StaticLinkKey.CookiePolicy]?: string;
  [StaticLinkKey.AdditionalLink]?: string;
  socialMedia: SocialMediaConfiguration = new SocialMediaConfiguration();
}

export class CountrySpecificUrlConfiguration {
  countryIso: string;
  urls: UrlConfiguration = new UrlConfiguration();
}

export class LanguageSpecificUrlConfiguration {
  languageIso: string;
  urls: UrlConfiguration = new UrlConfiguration();
}

export class UrlSetConfiguration extends UrlConfiguration {
  countrySpecific: Array<CountrySpecificUrlConfiguration> = [];
  languageSpecific: Array<LanguageSpecificUrlConfiguration> = [];
}

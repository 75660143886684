import { Inject, Injectable } from '@angular/core';
import { OnInitEffects } from '@ngrx/effects';
import { SetLayoutConfiguration } from './layout-config.actions';
import { Action } from '@ngrx/store';
import { Configuration, CONFIGURATION } from '@configurations/configuration.token';

@Injectable()
export class LayoutConfigEffects implements OnInitEffects {
  constructor(@Inject(CONFIGURATION) private configuration: Configuration) {}

  ngrxOnInitEffects(): Action {
    return new SetLayoutConfiguration(this.configuration.layout);
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { LayoutActionType, SetIsRtlModeAction } from '@app/store/layout/layout.actions';
import { LanguageCode } from '@shared/model/language-code.enum';

@Injectable()
export class LayoutEffects {
  private readonly languagesWithRtlModeSupport: string[] = [LanguageCode.Hebrew];

  constructor(private actions$: Actions, private translateService: TranslateService, @Inject(DOCUMENT) private document: Document) {}

  @Effect()
  isRtlLanguage$ = this.translateService.onLangChange.pipe(
    map((currentLanguage: LangChangeEvent) => {
      const isRtlModeSupported: boolean = this.languagesWithRtlModeSupport.includes(currentLanguage.lang);
      return new SetIsRtlModeAction(isRtlModeSupported);
    }),
  );

  @Effect({ dispatch: false })
  isRtlMode$ = this.actions$.pipe(
    ofType(LayoutActionType.SetIsRtlMode),
    map((action: SetIsRtlModeAction) => action.isRtlMode),
    distinctUntilChanged(),
    tap((isRtlMode: boolean) => {
      this.setLayoutDirection(isRtlMode);
    }),
  );

  private setLayoutDirection(isRtlMode: boolean): void {
    this.document.documentElement.setAttribute('dir', isRtlMode ? 'rtl' : 'ltr');
  }
}

import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Segment } from 'angulartics2/segment';

import { IAnalyticConfig } from '@core/IAnalyticConfig';
import { GoogleTagManagerInitializerService } from '@shared/services/analytics/google-tag-manager-initializer.service';
import { IAnalyticInitializer } from '@shared/services/analytics/ianalytic-initializer';
import { SegmentInitializerService } from '@shared/services/analytics/segment-initializer.service';
import { EnvironmentWrapper } from '@shared/utils/EnvironmentWrapper';

import { TealiumIQInitializerService } from './tealium-iq-initializer.service';

export class AnalyticInitializerService {
  private analyticInitializers: Array<IAnalyticInitializer> = [];

  constructor(
    private analyticFactory: IAnalyticServiceFactory,
    private angulartics2Segment: Angulartics2Segment,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {
    this.createInitializers();
  }

  initializeAnalyticServices() {
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      this.analyticInitializers.forEach((analyticInitializer: IAnalyticInitializer) => analyticInitializer.init());
    }
  }

  initializeCountrySpecificAnalyticServices(countryIso: string) {
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      this.analyticInitializers.forEach(analyticInitializer => {
        analyticInitializer.initForCountry(countryIso);
      });
    }
  }

  private createInitializers() {
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      const configuration: IAnalyticConfig = this.analyticFactory.getConfiguration();
      if (configuration.googleTagManagerContainerId) {
        this.addGoogleTagManager();
        this.angulartics2GoogleTagManager.startTracking();
      }
      if (configuration.tealiumIQAccount && configuration.tealiumIQProfile) {
        this.addTealiumIQ();
      }
      if (configuration.segmentWriteKey) {
        this.addSegment();
        this.angulartics2Segment.startTracking();
      }
    }
  }

  private addGoogleTagManager() {
    const gtm = this.analyticFactory.createGoogleTagManager();
    this.analyticInitializers.push(gtm);
  }

  private addTealiumIQ() {
    const tealiumIQ = this.analyticFactory.createTealiumIQ();
    this.analyticInitializers.push(tealiumIQ);
  }

  private addSegment() {
    const segmentInitializer: IAnalyticInitializer = this.analyticFactory.createSegment();
    this.analyticInitializers.push(segmentInitializer);
  }
}

export interface IAnalyticServiceFactory {
  getConfiguration(): IAnalyticConfig;
  createGoogleTagManager(): IAnalyticInitializer;
  createTealiumIQ(): IAnalyticInitializer;
  createSegment(): IAnalyticInitializer;
}

export class AnalyticServiceFactory implements IAnalyticServiceFactory {
  constructor(private configuration: IAnalyticConfig) {}

  getConfiguration(): IAnalyticConfig {
    return this.configuration;
  }

  createGoogleTagManager(): IAnalyticInitializer {
    return this.create(GoogleTagManagerInitializerService);
  }

  createTealiumIQ(): IAnalyticInitializer {
    return this.create(TealiumIQInitializerService);
  }

  createSegment(): IAnalyticInitializer {
    return this.create(SegmentInitializerService);
  }

  private create<T extends IAnalyticInitializer>(type: new () => T) {
    const analyticInitializer = new type();
    analyticInitializer.configuration = this.configuration;

    return analyticInitializer;
  }
}

import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { AppInsightsEventName } from '../model/app-insights-event-name.enum';
import { AppInsightsExclusionService } from '../services/app-insights-exclusion.service';
import { AppInsightsWrapperService } from '../services/app-insights-wrapper.service';
import { Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  private actionsHistory: Array<string> = [];

  constructor(private injector: Injector, private actions$: Actions) {
    super();
    this.trackActionsHistory();
  }

  handleError(error: Error) {
    const appInsights = this.injector.get(AppInsightsWrapperService);
    const appInsightsExclusionService = this.injector.get(AppInsightsExclusionService);
    const eventName = appInsightsExclusionService.shouldBeExcluded(error.message)
      ? AppInsightsEventName.UIEventExcludedWarningEvent
      : AppInsightsEventName.UIUncaughtErrorEvent;

    appInsights.trackEvent(eventName, {
      Name: error.name,
      Message: error.message,
      StackTrace: error.stack,
      UIActionsHistory: this.actionsHistory.join('\n'),
    });

    super.handleError(error);
  }

  private trackActionsHistory(): void {
    this.actions$.pipe(tap((action: Action) => this.actionsHistory.push(action.type))).subscribe();
  }
}

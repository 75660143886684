import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { WindowRefService } from '@core/windowref/windowref.service';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { NotificationCode } from '@shared/model/notification-code.enum';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';
import { BrandConfigurationService } from '@shared/services/brand-configuration.service';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'esw-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.scss'],
})
export class TimeOutComponent implements OnDestroy {
  @ViewChild('modal', { static: true })
  private dialogElementRef: ElementRef;
  private tearDown$ = new Subject();

  constructor(
    private appInsights: AppInsightsWrapperService,
    private modalService: NgbModal,
    private brandConfigurationService: BrandConfigurationService,
    private notificationService: NotificationService,
    private window: WindowRefService,
  ) {
    this.notificationService.notification$
      .pipe(
        filter(code => code === NotificationCode.OrderExpired),
        takeUntil(this.tearDown$),
      )
      .subscribe(() => {
        this.issueAppInsightEvent();
        this.openDialog();
      });
  }

  ngOnDestroy() {
    this.tearDown$.next();
    this.tearDown$.complete();
  }

  issueAppInsightEvent(): void {
    this.appInsights.trackEvent(AppInsightsEventName.CheckoutOrderExpiredWarningEvent);
  }

  openDialog(): void {
    this.modalService.open(this.dialogElementRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  redirectToCart(): void {
    const backToCartUrl = this.brandConfigurationService.getBrandConfiguration().backToCartUrl;
    this.window.nativeWindow.location.href = backToCartUrl;
  }
}

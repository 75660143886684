import { Action } from '@ngrx/store';

import { CartItems, OrderModel } from '@shared/model';

import { ThemeState } from './app.state';

export enum AppInitActionType {
  AppInitialize = '[AppInitialize] init',
  AppInitializeSuccessAction = '[AppInitialize] success',
  OrderInitialize = '[OrderInitialize] init',
  OrderInitializeSuccess = '[OrderInitialize] success',
  OrderInitializeFailure = '[OrderInitialize] failure',
  OrderInitializeMoveToPayment = '[OrderInitialize] move to payment',
  ShowRestrictedCartItemsModal = '[OrderInitialize] show restricted cart items modal',
  ThemeInitializeSuccess = '[ThemeInitialize] success',
  SetAppAsNative = '[SetAppAsNative]'
}

export class AppInitializeAction implements Action {
  readonly type = AppInitActionType.AppInitialize;
}

export class AppInitializeSuccessAction implements Action {
  readonly type = AppInitActionType.AppInitializeSuccessAction;
  constructor(public themeState: ThemeState) {}
}

export class OrderInitializeAction implements Action {
  readonly type = AppInitActionType.OrderInitialize;
  constructor(public payload: string, public pricingSynchronizationId?: string) {}
}

export class OrderInitializeSuccessAction implements Action {
  readonly type = AppInitActionType.OrderInitializeSuccess;
  constructor(public payload: OrderModel) {}
}

export class OrderInitializeFailureAction implements Action {
  readonly type = AppInitActionType.OrderInitializeFailure;
}

export class OrderInitializeMoveToPaymentAction implements Action {
  readonly type = AppInitActionType.OrderInitializeMoveToPayment;
}

export class ShowRestrictedCartItemsModal implements Action {
  readonly type = AppInitActionType.ShowRestrictedCartItemsModal;
  constructor(public restrictedCartItems: CartItems, public cartDiscountsRemoved: boolean, public allItemsRestricted: boolean) {}
}

export class ThemeInitializeSuccessAction implements Action {
  readonly type = AppInitActionType.ThemeInitializeSuccess;
}

export class SetAppAsNativeAction implements Action {
  readonly type = AppInitActionType.SetAppAsNative;
}

export type AppInitActions =
  | AppInitializeAction
  | AppInitializeSuccessAction
  | OrderInitializeAction
  | OrderInitializeSuccessAction
  | OrderInitializeFailureAction
  | ShowRestrictedCartItemsModal
  | OrderInitializeMoveToPaymentAction
  | ThemeInitializeSuccessAction
  | SetAppAsNativeAction;

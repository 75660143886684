import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { NotificationCode } from '../model/notification-code.enum';

@Injectable()
export class NotificationService implements OnDestroy {
  private notificationSubject = new Subject<NotificationCode>();
  notification$ = this.notificationSubject.asObservable();

  notify(code: NotificationCode) {
    this.notificationSubject.next(code);
  }

  ngOnDestroy() {
    this.notificationSubject.complete();
  }
}

export enum FeatureName {
  MultipleDeliveryMethods = 'multiple-delivery-methods',
  MarketingEmailOptIn = 'marketing-email-opt-in',
  SaveAddress = 'save-address',
  AddressComplete = 'address-complete',
  ExpandMetadataItems = 'expand-metadata-items',
  EmailDisabledForRegisteredUser = 'email-disabled-for-registered-user',
  LogoNotClickable = 'logo-not-clickable',
  NortonIconInsteadOfSocialLinks = 'norton-icon-instead-of-social-links',
  MetadataItemsNoTranslation = 'metadata-items-no-translation',
  CountrySelectTooltipHidden = 'country-select-tooltip-hidden',
  RegistrationEnabled = 'registration-enabled',
  ShipToStore = 'ship-to-store',
  ConfirmOnLeaveApp = 'confirm-on-leave-app',
  PanelsExpandCollapseAnimation = 'panels-expand-collapse-animation',
  BrazilianAddressEnabled = 'brazilian-address-enabled',
  UkraineAddressEnabled = 'ukraine-address-enabled',
  IndonesianAddressEnabled = 'indonesian-address-enabled',
  RussianAddressEnabled = 'russian-address-enabled',
  MexicanAddressEnabled = 'mexican-address-enabled',
  IndianAddressEnabled = 'indian-address-enabled',
  ChileAddressEnabled = 'chile-address-enabled',
  JapaneseAddressEnabled = 'japanese-address-enabled',
  TaiwanAddressEnabled = 'taiwan-address-enabled',
  SingaporeAddressEnabled = 'singapore-address-enabled',
  RussianCustomsEnabled = 'russian-customs-enabled',
  SouthKoreaCustomsEnabled = 'south-korea-customs-enabled',
  IndiaCustomsEnabled = 'india-customs-enabled',
  SaudiArabiaCustomsEnabled = 'saudi-arabia-customs-enabled',
  MexicoCustomsEnabled = 'mexico-customs-enabled',
  IndonesianCustomsEnabled = 'indonesian-customs-enabled',
  ChileCustomsEnabled = 'chile-customs-enabled',
  BrazilCustomsEnabled = 'brazil-customs-enabled',
  PerformanceTestBaseline = 'performance-test-baseline',
  CartItemLayoutReverse = 'cart-item-layout-reverse',
  ConfirmationPageLayoutAlternative = 'confirmation-page-layout-alternative',
  ConfirmationPagePaymentCardNumber = 'confirmation-page-payment-card-number',
  FooterLayoutAlternative = 'footer-layout-alternative',
  ShippingCountryAlternative = 'shipping-country-alternative',
  ShippingDetailsReviewAlternative = 'shipping-details-review-alternative',
  PaymentAddressReviewAlternative = 'payment-address-review-alternative',
  ExpressPaymentMethodsEnabled = 'express-payment-methods-enabled',
  AdditionalAnalyticsData = 'additional-analytics-data',
  PostCodeIsMandatoryForAllCountries = 'post-code-is-mandatory-for-all-countries',
  RegionExclusionValidation = 'region-exclusion-validation',
  OrderTotalInCartOverview = 'order-total-in-cart-overview',
  PaymentPanelV2Enabled = 'payment-panel-v2-enabled',
  InventoryCheckEnabled = 'inventory-check-enabled',
  AnalyticsEcommerceStepsAlternative = 'analytics-ecommerce-steps-alternative',
  PaymentPanelProfileV2 = 'payment-panel-profile-v2',
  OrderLimitsEnabled = 'order-limits-enabled',
  IdCollectionFunctionality = 'id-collection-functionality',
  AddressFromGeolocationEnabled = 'address-from-geolocation-enabled',
  BagTotalItemsEnabled = 'bag-total-items-enabled',
  RemoteConfig = 'remote-config',
  EstimatedArrivalSectionEnabled = 'estimated-arrival-section-enabled',
  RestrictedCartItemsCheckEnabled = 'restricted-cart-items-check-enabled',
  RestrictedItemsModalProceedButtonHidden = 'restricted-items-modal-proceed-button-hidden',
  SkipInitialBillingInfoUpdate = 'skip-initial-billing-info-update',
  BlockApoFpoPoBoxShippingAddress = 'block-apo-fpo-po-box-shipping-address',
  AnalyticsRevenueAsItemsAfterDiscount = 'analytics-revenue-as-items-after-discount',
  CountryCodeInCheckoutUrl = 'country-code-in-checkout-url',
  AddRegisteredProfileIdToAnalyticsEvents = 'add-registered-profile-id-to-analytics-events',
  AdditionalSocialMediaFooterLinks = 'additional-social-media-footer-links',
}

export enum AppInsightsEventName {
  CheckoutLoadedEvent = 'CheckoutLoadedEvent',

  DeliveryOptionsSavedEvent = 'DeliveryOptionsSavedEvent',
  DeliveryOptionsSaveErrorEvent = 'DeliveryOptionsSaveErrorEvent',
  DeliveryOptionsSaveGoneEvent = 'DeliveryOptionsSaveGoneEvent',
  DeliveryOptionsSaveOrderCompletedEvent = 'DeliveryOptionsSaveOrderCompletedEvent',

  GDPRConsentErrorEvent = 'GDPRConsentErrorEvent',
  GDPRConsentGoneEvent = 'GDPRConsentGoneEvent',
  GDPRConsentOrderCompletedEvent = 'GDPRConsentOrderCompletedEvent',

  InvalidPhoneEvent = 'InvalidPhoneEvent',

  MissingTranslationWarningEvent = 'MissingTranslationWarningEvent',

  CheckoutConfirmationOrderBeingProcessedEvent = 'CheckoutConfirmationOrderBeingProcessedEvent',

  CheckoutConfirmationOrderCompletedEvent = 'CheckoutConfirmationOrderCompletedEvent',

  CheckoutOrderExpiredWarningEvent = 'CheckoutOrderExpiredWarningEvent',

  CheckoutOrderGetErrorEvent = 'CheckoutOrderGetErrorEvent',
  CheckoutOrderBadlyFormattedErrorEvent = 'CheckoutOrderBadlyFormattedErrorEvent',
  CheckoutOrderStatusNotRecognizedErrorEvent = 'CheckoutOrderStatusNotRecognizedErrorEvent',

  PaymentOptionsSavedEvent = 'PaymentOptionsSavedEvent',
  PaymentOptionsSaveErrorEvent = 'PaymentOptionsSaveErrorEvent',
  PaymentOptionsSaveGoneEvent = 'PaymentOptionsSaveGoneEvent',
  PaymentOptionsSaveOrderCompletedEvent = 'PaymentOptionsSaveOrderCompletedEvent',

  PaymentNotSuccessfulEvent = 'PaymentNotSuccessfulEvent',
  OrderStatusNotValidAfterPaymentErrorEvent = 'OrderStatusNotValidAfterPaymentErrorEvent',

  ExpressPaymentWarningEvent = 'ExpressPaymentWarningEvent',

  PaymentPanelLoadTimeoutErrorEvent = 'PaymentPanelLoadTimeoutErrorEvent',

  CustomsSectionConfigDiscrepancyErrorEvent = 'CustomsSectionConfigDiscrepancyErrorEvent',

  UIUncaughtErrorEvent = 'UIUncaughtErrorEvent',
  UIWindowOnErrorEvent = 'UIWindowOnErrorEvent',
  UIEventExcludedWarningEvent = 'UIEventExcludedWarningEvent',
}

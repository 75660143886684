import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CountryIso, CountryService } from '@esw/checkout-common-ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IGlobalAppSettings } from '@app/core';
import { FeatureName } from '@configurations/feature-name.enum';
import { FEATURESET } from '@configurations/feature-set.token';
import { ContactInformationAdapter } from '@shared/adapters/contact-information.adapter';
import { OrderAdapter } from '@shared/adapters/order.adapter';
import {
  AuthorizeExpressPaymentRequestDto,
  ContactDetailsRequestDto,
  ContactDetailsResponseDto,
  DeliveryOptionRequestDto,
  ErrorModel,
  EventName,
  InventoryCheckResponseDto,
  Option,
  OrderChargeResponseDto,
  OrderContactDetailsRequestDto,
  OrderDataAnalyticsRequestDto,
  OrderLanguageRequestDto,
  OrderResponseDto,
  PricingSynchronizationRequestDto,
  RegionExclusionCheckRequestDto,
} from '@shared/api';
import { AddressModel } from '@shared/model/address.model';
import { ContactInformationModel, ContactInformationType } from '@shared/model/contact-information.model';
import { BrowserInfoService } from '@shared/services/browser-info.service';

import { FeatureAdapter } from '../adapters/feature.adapter';
import { OrderChargesAdapter } from '../adapters/order-charges.adapter';
import { OrderModel, OrderStatus } from '../model';
import { AuthorizeExpressPaymentModel } from '../model/authorise-express-payment.model';
import { HttpStatusCode } from '../model/http-status-code.enum';
import { OrderAndFeaturesModel } from '../model/order-and-features.model';
import { OrderChargesUpdateModel } from '../model/order-charges-update.model';
import { OrderChargesModel } from '../model/order-charges.model';
import { ShippingInfoUpdateModel } from '../model/shipping-info-update.model';
import { SaveAddressService } from './save-address.service';

@Injectable()
export class OrderService {
  private baseUrl: string;

  constructor(
    settings: IGlobalAppSettings,
    private http: HttpClient,
    private countryService: CountryService,
    private saveAddressService: SaveAddressService,
    @Inject(FEATURESET) private featureSet: { [key: string]: boolean },
    private browserInfoService: BrowserInfoService,
  ) {
    this.baseUrl = `${settings.backendApiUrl}/${settings.backendOrderApiVersion}/Order`;
  }

  getOrderAndFeatures(orderCode: string): Observable<OrderAndFeaturesModel> {
    return this.createGetOrderRequest(orderCode).pipe(
      map((response) => this.mapOrderResponseToOrderAndFeaturesModel(response)),
      catchError((error) => this.handleGetOrderError(error)),
    );
  }

  putOrderWithPricingSyncId(orderId: string, pricingSynchronizationId: string): Observable<OrderAndFeaturesModel> {
    const url = `${this.baseUrl}/${orderId}/PricingSynchronizationId`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const payload: PricingSynchronizationRequestDto = { pricingSynchronizationId };
    return this.http
      .put<OrderResponseDto>(url, payload, { headers })
      .pipe(
        map((response: OrderResponseDto) => this.mapOrderResponseToOrderAndFeaturesModel(response)),
        catchError((error) => this.handleGetOrderError(error)),
      );
  }

  authorizeExpressPayment(model: AuthorizeExpressPaymentModel): Observable<OrderModel> {
    const url = `${this.baseUrl}/${model.orderId}/AuthorizeExpressPayment`;
    const deliveryDetails: ContactDetailsRequestDto = OrderAdapter.createContactDetailsRequestDto(
      model.shippingContactInfo.address,
      model.shippingContactInfo.email,
      model.shippingContactInfo.telephone,
      model.shippingContactInfo.idCollection,
    );

    const deliveryOptions: DeliveryOptionRequestDto = {
      option: <Option>model.deliveryOption,
    };

    const payload: AuthorizeExpressPaymentRequestDto = {
      expressPaymentId: model.expressPaymentId,
      deliveryDetails,
      deliveryOptions,
      browserInfo: this.browserInfoService.getBrowserInfo(),
      ravelinDeviceId: model.ravelinDeviceId,
    };
    return this.http.put<OrderResponseDto>(url, payload).pipe(map((response: OrderResponseDto) => OrderAdapter.createOrder(response)));
  }

  calculateOrderCharges(orderCode: string, model: OrderChargesUpdateModel): Observable<OrderChargesModel> {
    const url = `${this.baseUrl}/${orderCode}/Charges/CalculateAndForget`;

    return this.http
      .get<OrderChargeResponseDto[]>(url, {
        headers: this.createGetOrderHeaders(),
        params: {
          DeliveryCountryIso: model.deliveryCountryIso,
          Region: model.deliveryRegion || '',
          DeliveryOption: model.deliveryOption,
        },
      })
      .pipe(map((response) => OrderChargesAdapter.createOrderChargesModel(response)));
  }

  handleGetOrderError(err: any): Observable<any> {
    if (err.status === HttpStatusCode.Gone) {
      return this.handleGetOrderSessionExpiredError(err.error);
    }

    return throwError(err);
  }

  updatePaymentContactInfo(orderCode: string, paymentDetails: ContactInformationModel): Observable<ContactInformationModel> {
    const requestDto = OrderAdapter.createPaymentDetailsRequestDto(paymentDetails);
    return this.putContactDetails(orderCode, requestDto).pipe(
      map((response) => {
        const contactInfo = this.getNewContactDetailsFromApiResponse(response, paymentDetails.type);
        if (contactInfo) {
          this.populateCountryModel(contactInfo.address);
          return contactInfo;
        }
        return paymentDetails;
      }),
    );
  }

  checkInventoryIsAvailable(orderId: string): Observable<boolean> {
    return this.http
      .put<InventoryCheckResponseDto>(`${this.baseUrl}/${orderId}/InventoryCheck`, null)
      .pipe(map((response: InventoryCheckResponseDto) => response.inventoryAvailable));
  }

  putGdprStatus(orderCode: string, gdprAccepted: boolean): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(`${this.baseUrl}/${orderCode}/GDPR`, null, {
      observe: 'response',
      params: new HttpParams().set('gdprAccepted', gdprAccepted.toString()),
    });
  }

  putMarketingEmailOptInConfirmation(orderCode: string, emailOptInConfirmed: boolean): Observable<HttpResponse<boolean>> {
    return this.http.put<boolean>(`${this.baseUrl}/${orderCode}/MarketingEmail`, null, {
      observe: 'response',
      params: new HttpParams().set('marketingEmailOpt', emailOptInConfirmed.toString()),
    });
  }

  putCheckoutLanguage(orderCode: string, languageSelected: string): Observable<string> {
    const requestLanguage = {
      languageIso: languageSelected,
    };
    return this.http
      .put<OrderLanguageRequestDto>(`${this.baseUrl}/${orderCode}/LanguageIso`, requestLanguage, {
        observe: 'response',
      })
      .pipe(map((response) => response.body.toString()));
  }

  getOrderPaymentUrl(orderCode: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/${orderCode}/PaymentUrl`);
  }

  putRetryPayment(orderCode: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${orderCode}/RetryPayment`, null);
  }

  putOrder(orderCode: string, shippingInfo: ShippingInfoUpdateModel): Observable<void> {
    const requestDto = OrderAdapter.createOrderRequestDto(orderCode, shippingInfo);
    return this.http.put<void>(`${this.baseUrl}/${orderCode}`, requestDto);
  }

  checkRegion(
    orderId: string,
    city: string,
    postalCode: string,
    region: string,
    country: string | CountryIso,
  ): Observable<Array<ErrorModel> | null> {
    const requestDto: RegionExclusionCheckRequestDto = { city, postalCode, region, country };
    return this.http.put<Array<ErrorModel> | null>(`${this.baseUrl}/${orderId}/CheckRegion`, requestDto).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === HttpStatusCode.BadRequest && Array.isArray(errorResponse.error)) {
          return of(errorResponse.error);
        }
        return throwError(errorResponse);
      }),
    );
  }

  putDataAnalyticsEvent(orderCode: string, eventName: EventName): Observable<void> {
    const url = `${this.baseUrl}/${orderCode}/DataAnalyticsEvent`;
    const requestDto: OrderDataAnalyticsRequestDto = { eventName };

    return this.http.put<void>(url, requestDto);
  }

  private handleGetOrderSessionExpiredError(orderExpiredResponseDto: OrderResponseDto) {
    const orderAndFeaturesModel = this.mapOrderResponseToOrderAndFeaturesModel(orderExpiredResponseDto);
    return of(orderAndFeaturesModel);
  }

  private createGetOrderRequest(orderCode: string): Observable<OrderResponseDto> {
    const url = `${this.baseUrl}/${orderCode}`;
    const headers = this.createGetOrderHeaders();
    const requestOptions = { headers: headers };

    return this.http.get<OrderResponseDto>(url, requestOptions);
  }

  private mapOrderResponseToOrderAndFeaturesModel(response: OrderResponseDto) {
    const order = OrderAdapter.createOrder(response);
    const features = FeatureAdapter.createFeatures(response.features);

    if (this.featureSet[FeatureName.SaveAddress] && order.status === OrderStatus.OnGoing) {
      order.delivery = this.populateSavedAddress(order.delivery);
    }

    this.populateCountryModel(order.delivery.address);
    this.populateCountryModel(order.payment.address);

    const getOrder = new OrderAndFeaturesModel();
    getOrder.order = order;
    getOrder.features = features;

    return getOrder;
  }

  private createGetOrderHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Mon, 26 Jul 1997 05:00:00 GMT',
    });

    return headers;
  }

  private populateCountryModel(address: AddressModel): void {
    if (address?.country.iso) {
      const country = this.countryService.getCountry(address.country.iso);
      address.country = country;
    }
  }

  private populateSavedAddress(contactInfo: ContactInformationModel) {
    const savedAddress: ContactInformationModel = this.saveAddressService.getSavedAddress();
    return savedAddress?.address.country.iso === contactInfo.address.country.iso ? savedAddress : contactInfo;
  }

  private putContactDetails(
    orderCode: string,
    requestDto: OrderContactDetailsRequestDto,
  ): Observable<HttpResponse<ContactDetailsResponseDto>> {
    const url = `${this.baseUrl}/${orderCode}/ContactDetails`;
    return this.http.put<ContactDetailsResponseDto>(url, requestDto, {
      observe: 'response',
    });
  }

  private getNewContactDetailsFromApiResponse(
    response: HttpResponse<ContactDetailsResponseDto>,
    type?: ContactInformationType,
  ): ContactInformationModel | null {
    if (response.status === 201) {
      // 201 - created, new address entity
      const contactInfo = ContactInformationAdapter.createModel(response.body, type);
      this.populateCountryModel(contactInfo.address);
      return contactInfo;
    }
    return null;
  }
}

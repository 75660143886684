<div class="modal-header" [class.hasCloseButton]="isCloseButtonVisible">
  <h1 *ngIf="title" class="modal-title">
    <span *ngIf="iconClass" class="{{ iconClass }} modal-title-icon" aria-hidden="true"></span>
    <span class="modal-title-text">{{ title }}</span>
  </h1>
  <div *ngIf="isCloseButtonVisible" class="modal-close-button" (click)="onCloseButtonClick()"></div>
</div>
<hr />
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div *ngIf="buttonText || secondaryButtonText" class="modal-footer">
  <esw-button secondary *ngIf="secondaryButtonText as secondaryBtnText" (click)="onSecondaryButtonClick()">
    {{ secondaryBtnText }}
  </esw-button>
  <esw-button *ngIf="buttonText as btnText" (click)="onButtonClick()">
    {{ btnText }}
  </esw-button>
</div>

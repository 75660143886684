import { Injectable } from '@angular/core';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

declare var dataLayer: any;

@Injectable()
export class Angulartics2GtmCustomProvider extends Angulartics2GoogleTagManager {
  constructor(angulartics2: Angulartics2) {
    super(angulartics2);
  }

  /** Override library implementation of method, to avoid using of properties.gtmCustom. See:
   * https://github.com/angulartics/angulartics2/blob/master/src/lib/providers/gtm/gtm.ts#L81
   * */
  eventTrack(action: string, properties: any) {
    properties = properties || {};
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push({
        event: properties.event || 'interaction',
        target: properties.category || 'Event',
        action,
        ...properties,
      });
    }
  }
}

import { Injectable } from '@angular/core';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

import { AppInsightsEventName } from '../model/app-insights-event-name.enum';

@Injectable()
export class MissingTranslationHighlighter implements MissingTranslationHandler {
  constructor(private appInsights: AppInsightsWrapperService) {}

  handle(params: MissingTranslationHandlerParams) {
    this.reportMissingKeyToAppInsights(params);
    return params.key;
  }

  private reportMissingKeyToAppInsights(params: MissingTranslationHandlerParams) {
    this.appInsights.trackEvent(AppInsightsEventName.MissingTranslationWarningEvent, {
      Language: params.translateService.currentLang,
      MissingKey: params.key,
      Params: JSON.stringify(params.interpolateParams),
    });
  }
}

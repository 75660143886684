import { Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';

import { WindowRefService } from '@app/core/windowref/windowref.service';

import { DomInjectionConfigurationModel } from '../model/dom-injection-configuration.model';

@Injectable({ providedIn: 'root' })
export class DomWrapperService {
  get origin(): string {
    return this.window.nativeWindow.location.origin;
  }

  private documentRef: Document;
  private renderer: Renderer2;

  constructor(private window: WindowRefService, rendererFactory: RendererFactory2) {
    this.documentRef = this.window.nativeWindow.document;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setFavicon(url: string): void {
    const favIcon: HTMLLinkElement = this.documentRef.querySelector('link[rel*="icon"]');
    this.renderer.setAttribute(favIcon, 'href', url);
  }

  public setCssVariable(name: string, value: string): void {
    this.renderer.setStyle(this.documentRef.body, name, value, RendererStyleFlags2.DashCase);
  }

  public injectCss(configuration: DomInjectionConfigurationModel): void {
    const linkElement = this.createStyleElement(configuration);

    this.appendLinkToElement('head', linkElement);
  }

  public injectScript(configuration: DomInjectionConfigurationModel): void {
    const scriptElement = this.createScriptElement(configuration);

    this.appendLinkToElement('body', scriptElement);
  }

  private appendLinkToElement(elementTagName: string, linkElement: HTMLElement): void {
    const parentElement = this.documentRef.getElementsByTagName(elementTagName)[0];

    this.renderer.appendChild(parentElement, linkElement);
  }

  private createStyleElement({ url, content }: DomInjectionConfigurationModel): HTMLLinkElement | HTMLStyleElement {
    let styleElement: HTMLLinkElement | HTMLStyleElement;

    if (url) {
      styleElement = this.renderer.createElement('link');

      this.renderer.setAttribute(styleElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(styleElement, 'type', 'text/css');
      this.renderer.setAttribute(styleElement, 'href', url);
    } else {
      styleElement = this.renderer.createElement('style');

      const contentText = this.renderer.createText(content);
      this.renderer.appendChild(styleElement, contentText);
    }

    return styleElement;
  }

  private createScriptElement({ url, content }: DomInjectionConfigurationModel): HTMLScriptElement {
    const link = this.renderer.createElement('script');

    if (url) {
      this.renderer.setAttribute(link, 'src', url);
    } else {
      const contentText = this.renderer.createText(content);
      this.renderer.appendChild(link, contentText);
    }

    return link;
  }
}

import { Inject, Injectable } from '@angular/core';

import { CountryIso, CountryModel, CountryService } from '@esw/checkout-common-ui';

import { CONFIGURATION, Configuration } from '@configurations/configuration.token';
import {
  INTERNATIONALIZATION_CONFIGURATION,
  InternationalizationConfiguration,
} from '@configurations/i18n/i18n-configuration.token';
import { StaticLinkKey, UrlConfiguration, URLSET, UrlSetConfiguration } from '@configurations/url-set.token';
import {
  AppConfigurationModel,
  BrandConfigurationModel,
  FooterLinkConfigurationModel,
  SocialMediaConfigurationModel,
} from '@shared/model';
import { ALL_COUNTRIES_ARE_ALLOWED } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class BrandConfigurationService {
  private brandConfiguration: BrandConfigurationModel;
  private urlConfig: UrlConfiguration;
  private deliveryCountry = 'default';

  constructor(
    private countryService: CountryService,
    @Inject(URLSET) private urlSet: UrlSetConfiguration,
    @Inject(INTERNATIONALIZATION_CONFIGURATION) private i18nConfiguration: InternationalizationConfiguration,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) {
    this.init(this.deliveryCountry);
  }

  init(deliveryCountry: string): void {
    this.deliveryCountry = deliveryCountry;

    let urlConfig = this.urlSet;
    const countrySpecificConfig = this.urlSet.countrySpecific;

    if (countrySpecificConfig?.length) {
      const countrySpecificUrlConfig = countrySpecificConfig.find((cs) => cs.countryIso === this.deliveryCountry);

      if (countrySpecificUrlConfig?.urls) {
        urlConfig = {
          ...urlConfig,
          ...countrySpecificUrlConfig.urls,
          socialMedia: {
            ...urlConfig.socialMedia,
            ...countrySpecificUrlConfig.urls.socialMedia,
          },
        };
      }
    }

    this.urlConfig = urlConfig;
    this.brandConfiguration = this.loadBrandConfiguration();
  }

  getBrandConfiguration(): BrandConfigurationModel {
    return this.brandConfiguration;
  }

  loadBrandConfigurationRemoteConfigSettings(remoteConfig?: AppConfigurationModel) {
    this.brandConfiguration.billingCountries = this.setBillingCountries(remoteConfig?.countriesInactiveForBilling);
    if (remoteConfig?.countries) {
      this.brandConfiguration.transliteratedCountriesIso = Object.keys(remoteConfig.countries).filter(
        (countryIso: CountryIso) => remoteConfig.countries[countryIso].transliterateAddress,
      ) as CountryIso[];
    }
  }

  private loadBrandConfiguration(): BrandConfigurationModel {
    const allCountries = this.countryService.getCountries();
    const brandConfiguration = this.brandConfiguration || new BrandConfigurationModel();
    // if theme, billing countries, and transliterated countries already filled by remote config, it shouldn't be overwritten
    brandConfiguration.billingCountries = this.setBillingCountries();
    brandConfiguration.socialMediaConfigurations = [];
    Object.keys(this.urlConfig.socialMedia).forEach((key) => {
      const socialMediaUrlConfig = this.urlConfig.socialMedia[key];
      const socialMediaConfig = new SocialMediaConfigurationModel();
      if (socialMediaUrlConfig) {
        socialMediaConfig.name = key;
        socialMediaConfig.url = socialMediaUrlConfig.url;
      }
      brandConfiguration.socialMediaConfigurations.push(socialMediaConfig);
    });

    brandConfiguration.footerLinkConfigurations = [];

    const footerLinksConfig: Array<FooterLinkConfigurationModel> = [
      {
        url: StaticLinkKey.EShopWorld,
        name: 'Powered by eShopWorld',
      },
      {
        url: StaticLinkKey.TermsAndConditions,
        name: 'Terms and Conditions',
      },
      {
        url: StaticLinkKey.PrivacyPolicy,
        name: 'Privacy Statement',
      },
      {
        url: StaticLinkKey.CookiePolicy,
        name: 'Cookie Policy',
      },
      {
        url: StaticLinkKey.RefundPolicy,
        name: 'Refund Policy',
      },
      {
        url: StaticLinkKey.Copyright,
        name: 'Copyright',
      },
      {
        url: StaticLinkKey.AdditionalLink,
        name: 'Additional Link',
      },
    ];

    footerLinksConfig
      .filter((link: FooterLinkConfigurationModel) => !!this.urlConfig[link.url]) // config has URL for this key
      .forEach((link: FooterLinkConfigurationModel) => brandConfiguration.footerLinkConfigurations.push(link));

    brandConfiguration.allCountries = allCountries;

    brandConfiguration.internationalization.supportedLanguagesByCountriesIso = this.i18nConfiguration.supportedLanguagesByCountriesIso;

    brandConfiguration.retailerWebsiteUrl = this.urlConfig.logo;
    brandConfiguration.registrationUrl = this.urlConfig.registration;
    brandConfiguration.backToCartUrl = 'https://www.eshopworld.com';
    brandConfiguration.continueShoppingUrl = 'https://www.eshopworld.com';
    brandConfiguration.poBoxValidationAllowedCountries = this.configuration.poBoxValidationAllowedCountries;
    brandConfiguration.addressValidation = this.configuration.addressValidation || [];

    return brandConfiguration;
  }

  isAddressCompleteSupportedByCountry(countryIso: string): boolean {
    const supportedAddressCompleteCountries = this.i18nConfiguration.supportedAddressCompleteCountriesIso;
    if (supportedAddressCompleteCountries?.length) {
      return !!this.getMatchedCountry(countryIso, supportedAddressCompleteCountries);
    }
    return false;
  }

  private getFilteredCountries(allCountries: CountryModel[], supportedCountriesIso: string[]) {
    if (!supportedCountriesIso || supportedCountriesIso.indexOf(ALL_COUNTRIES_ARE_ALLOWED) !== -1) {
      return allCountries;
    }

    return allCountries.filter((c) => supportedCountriesIso.indexOf(c.iso) !== -1);
  }

  private getMatchedCountry(country: string, supportedCountriesIso: string[]) {
    return supportedCountriesIso.find((sc) => sc === country || sc === ALL_COUNTRIES_ARE_ALLOWED);
  }

  private setBillingCountries(countriesInactiveForBilling?: string[]) {
    const allCountries = this.countryService.getCountries();
    if (countriesInactiveForBilling) {
      if (countriesInactiveForBilling.length) {
        const supportedCountriesForBilling = allCountries
          .filter((country: CountryModel) => !countriesInactiveForBilling.includes(country.iso))
          .map((country: CountryModel) => country.iso);

        return this.getFilteredCountries(allCountries, supportedCountriesForBilling);
      } else {
        return allCountries;
      }
    }

    return this.brandConfiguration?.billingCountries?.length
      ? this.brandConfiguration.billingCountries
      : this.getFilteredCountries(allCountries, this.i18nConfiguration.supportedBillingCountriesIso);
  }
}

<button
  [type]="type"
  [disabled]="disabled"
  [ngClass]="{
    secondary: secondary !== false && disabled === false,
    tertiary: tertiary !== false && disabled === false,
    'button--state-error': state === 'error',
    'button--state-success': state === 'success',
    'button--state-loading': state === 'loading'
  }"
  [ngSwitch]="state"
>
  <i *ngSwitchCase="'error'" class="ion-android-cancel" aria-hidden="true"></i>
  <i *ngSwitchCase="'success'" class="ion-checkmark-circled" aria-hidden="true"></i>
  <div *ngSwitchCase="'loading'" class="loading-container">
    <span class="loader"></span>
  </div>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</button>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { GoogleOptimizeService } from '@app/shared/services/analytics/google-optimize.service';
import { AppConfigService } from '@app/shared/services/app-config.service';
import { PaymentOptionsGet } from '@app/store/payment-options-get/payment-options-get.actions';
import { FeatureName } from '@configurations/feature-name.enum';
import { BusyIndicatorService } from '@core/busyIndicator';
import { RestrictedCartItemsModalComponent } from '@core/restricted-cart-items-modal/restricted-cart-items-modal.component';
import { Code } from '@shared/api';
import { AppConfigurationModel, CartItems, OrderModel, OrderStatus } from '@shared/model';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { FeatureModel } from '@shared/model/feature.model';
import { HttpStatusCode } from '@shared/model/http-status-code.enum';
import { ModalSize } from '@shared/model/modal-size.enum';
import { OrderAndFeaturesModel } from '@shared/model/order-and-features.model';
import {
  BrandConfigurationService,
  OrderService,
  ThemeInjectorService,
  TranslateInitializerService,
} from '@shared/services';
import { AnalyticInitializerService } from '@shared/services/analytics/analytic-initializer.service';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';
import { TimeOutService } from '@shared/services/time-out.service';
import { EnvironmentWrapper } from '@shared/utils/EnvironmentWrapper';

import { SetFeatureFlags } from '../feature-flag/feature-flag.actions';
import { getFeatureIsEnabled } from '../feature-flag/feature-flag.reducer';
import {
  AppInitActionType,
  AppInitializeAction,
  AppInitializeSuccessAction,
  OrderInitializeAction,
  OrderInitializeFailureAction,
  OrderInitializeMoveToPaymentAction,
  OrderInitializeSuccessAction,
  ShowRestrictedCartItemsModal,
  ThemeInitializeSuccessAction,
} from './app-init.actions';
import { getCalculatorNotificationIsPresent, getMainState } from './app-init.reducer';
import { IAppState } from './app.state';

@Injectable()
export class AppInitEffects {
  orderId: string;
  countryIsoFromUrl: string;

  constructor(
    private orderService: OrderService,
    private store: Store<IAppState>,
    private actions$: Actions,
    private brandConfigurationService: BrandConfigurationService,
    private themeInjector: ThemeInjectorService,
    private translateConfigurationService: TranslateInitializerService,
    private appInsights: AppInsightsWrapperService,
    private timeOutService: TimeOutService,
    private busyIndicatorService: BusyIndicatorService,
    private ngZone: NgZone,
    private analyticInitializerService: AnalyticInitializerService,
    private googleOptimizeService: GoogleOptimizeService,
    private appConfigService: AppConfigService,
    private modalService: NgbModal,
  ) {}

  @Effect({ dispatch: false })
  initGoogleOptimizeService$: Observable<Action> = this.actions$.pipe(
    ofType(AppInitActionType.AppInitialize),
    tap(() => {
      this.googleOptimizeService.init();
    }),
  );

  @Effect()
  initializeRemoteConfigAction$: Observable<Action> = this.actions$.pipe(
    ofType(AppInitActionType.AppInitialize),
    withLatestFrom(this.store.pipe(select(getFeatureIsEnabled(FeatureName.RemoteConfig)))),
    tap(([action, isRemoteConfig]: [AppInitializeAction, boolean]) => {
      if (!isRemoteConfig) {
        this.themeInjector.injectTheme();
      }
    }),
    filter(([action, isRemoteConfig]: [AppInitializeAction, boolean]) => isRemoteConfig),
    mergeMap(() =>
      this.appConfigService.getRemoteConfig().pipe(
        withLatestFrom(this.store.pipe(select(getFeatureIsEnabled(FeatureName.RemoteConfig)))), // can be changed by Google Optimize
        switchMap(([remoteConfig, isRemoteConfigStillEnabled]: [AppConfigurationModel, boolean]) => {
          if (isRemoteConfigStillEnabled) {
            this.brandConfigurationService.loadBrandConfigurationRemoteConfigSettings(remoteConfig);
            this.themeInjector.injectTheme(remoteConfig?.theme, remoteConfig?.featureToggles);

            return of(new AppInitializeSuccessAction(remoteConfig?.theme));
          } else {
            this.themeInjector.injectTheme();
            return EMPTY;
          }
        }),
        catchError(() => {
          this.themeInjector.injectTheme();
          return EMPTY;
        }),
      ),
    ),
  );

  @Effect()
  initializeOrderAction$: Observable<Action> = this.actions$.pipe(
    ofType<OrderInitializeAction>(AppInitActionType.OrderInitialize),
    map((action: OrderInitializeAction) => {
      this.orderId = action.payload;
      return action;
    }),
    withLatestFrom(this.store.pipe(select(getMainState))),
    filter(([action, currentState]: [OrderInitializeAction, IAppState]) => {
      return currentState.appInitialized === false;
    }),
    mergeMap(([action]) => {
      const orderId: string = action.payload;
      const pricingSynchronizationId: string | undefined = action.pricingSynchronizationId;
      return this.fetchOrderDetails(orderId, pricingSynchronizationId).pipe(
        switchMap(({ order, features }) => {
          const featureFlags = features.reduce((flags: { [key: string]: boolean }, feature: FeatureModel) => {
            return {
              ...flags,
              [feature['type']]: feature['active'],
            };
          }, {});

          this.initializeTimeOut(order);
          return [
            new SetFeatureFlags({
              type: 'preorder',
              flags: featureFlags,
            }),
            new OrderInitializeSuccessAction(order),
          ];
        }),
      );
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status !== HttpStatusCode.NotFound && error.status !== HttpStatusCode.Gone) {
        this.appInsights.trackHttpErrorEvent(error, AppInsightsEventName.CheckoutOrderGetErrorEvent, {
          // we should pass it manually otherwise it will be empty guid
          OrderCode: this.orderId,
        });
      }

      return of(new OrderInitializeFailureAction());
    }),
  );

  @Effect()
  checkOrderPaymentState$: Observable<Action> = this.actions$.pipe(
    ofType<OrderInitializeSuccessAction>(AppInitActionType.OrderInitializeSuccess),
    switchMap((action) => {
      if (action.payload.status === OrderStatus.PaymentInProgress && action.payload.timeToExpiry) {
        return of(new OrderInitializeMoveToPaymentAction());
      }

      return EMPTY;
    }),
  );

  @Effect()
  checkOrderRestrictedCartItems$: Observable<Action | never> = this.actions$.pipe(
    ofType<OrderInitializeSuccessAction>(AppInitActionType.OrderInitializeSuccess),
    withLatestFrom(
      this.store.pipe(select(getMainState)),
      this.store.pipe(select(getFeatureIsEnabled(FeatureName.RestrictedCartItemsCheckEnabled))),
      this.store.pipe(select(getCalculatorNotificationIsPresent(Code.CartDiscountsRemovedDueToRestrictedItems))),
    ),
    switchMap(
      ([_, appState, restrictedItemsCheckIsEnabled, cartDiscountsRemoved]: [OrderInitializeSuccessAction, IAppState, boolean, boolean]) => {
        const cartItems: CartItems = appState.checkout.order.cart;
        const restrictedCartItems: CartItems = appState.checkout.order.restrictedCartItems;
        const restrictedCartItemsPresent: boolean = restrictedCartItems.length > 0;
        const allItemsRestricted: boolean = restrictedCartItemsPresent && cartItems.length === 0;
        const orderStatus: OrderStatus = appState.checkout.order.status;

        if (restrictedItemsCheckIsEnabled && restrictedCartItemsPresent && orderStatus === OrderStatus.OnGoing) {
          return of(new ShowRestrictedCartItemsModal(restrictedCartItems, cartDiscountsRemoved, allItemsRestricted));
        }

        return EMPTY;
      },
    ),
  );

  @Effect({ dispatch: false })
  showRestrictedCartItemsModal$ = this.actions$.pipe(
    ofType<ShowRestrictedCartItemsModal>(AppInitActionType.ShowRestrictedCartItemsModal),
    tap((action: ShowRestrictedCartItemsModal) => {
      const modalRef: NgbModalRef = this.modalService.open(RestrictedCartItemsModalComponent, {
        keyboard: false,
        backdrop: 'static',
        windowClass: ModalSize.Medium,
      });
      const componentInstance = modalRef.componentInstance as RestrictedCartItemsModalComponent;
      componentInstance.restrictedCartItems = action.restrictedCartItems;
      componentInstance.cartDiscountsRemoved = action.cartDiscountsRemoved;
      componentInstance.allItemsRestricted = action.allItemsRestricted;
    }),
  );

  @Effect()
  loadPaymentOptions$: Observable<Action> = this.actions$.pipe(
    ofType<OrderInitializeMoveToPaymentAction>(AppInitActionType.OrderInitializeMoveToPayment),
    map((action) => new PaymentOptionsGet()),
  );

  @Effect({ dispatch: false })
  initializeBrand$ = this.actions$.pipe(
    ofType<OrderInitializeSuccessAction>(AppInitActionType.OrderInitializeSuccess),
    tap((order) => {
      const resp = order.payload;
      let orderLanguageIso = '';
      if (resp) {
        this.brandConfigurationService.init(resp.retailerOriginCountry || 'default');
        if (resp.retailerCheckoutExperience?.backToCartUrl) {
          this.brandConfigurationService.getBrandConfiguration().backToCartUrl = resp.retailerCheckoutExperience.backToCartUrl;
        }
        if (resp.retailerCheckoutExperience?.continueShoppingUrl) {
          this.brandConfigurationService.getBrandConfiguration().continueShoppingUrl = resp.retailerCheckoutExperience.continueShoppingUrl;
        }
        if (resp.shopperCheckoutExperience?.shopperCultureLanguageIso) {
          orderLanguageIso = resp.shopperCheckoutExperience.shopperCultureLanguageIso;
        }
      }
      this.translateConfigurationService.init(orderLanguageIso, resp.deliveryCountryIso);
    }),
  );

  @Effect({ dispatch: false })
  hideLoading$: Observable<Action> = this.actions$.pipe(
    ofType<OrderInitializeSuccessAction | OrderInitializeFailureAction | ThemeInitializeSuccessAction>(
      AppInitActionType.OrderInitializeSuccess,
      AppInitActionType.OrderInitializeFailure,
      AppInitActionType.ThemeInitializeSuccess,
    ),
    withLatestFrom(this.store.pipe(select(getMainState))),
    map(([action, state]) => {
      if (state.appInitialized && state.isThemeInitialized) {
        this.busyIndicatorService.setAvailable();
      }

      return action;
    }),
  );

  @Effect({ dispatch: false })
  initializeCountrySpecificAnalytics$ = this.actions$.pipe(
    ofType<OrderInitializeSuccessAction>(AppInitActionType.OrderInitializeSuccess),
    tap((action) => {
      if (EnvironmentWrapper.getAnalyticsEnabled()) {
        this.ngZone.runOutsideAngular(() => {
          this.analyticInitializerService.initializeCountrySpecificAnalyticServices(action.payload.retailerOriginCountry);
        });
      }
    }),
  );

  private initializeTimeOut(order: OrderModel): void {
    if (order.status !== OrderStatus.OnGoing && order.status !== OrderStatus.PaymentInProgress) {
      this.timeOutService.stopTimer();
    } else {
      if (order.timeToExpiry > 0) {
        this.timeOutService.setTimeOut(order.timeToExpiry);
        this.timeOutService.startTimer();
      }
    }
  }

  private fetchOrderDetails(orderId: string, pricingSynchronizationId: string | undefined): Observable<OrderAndFeaturesModel> {
    if (pricingSynchronizationId) {
      return this.orderService.putOrderWithPricingSyncId(orderId, pricingSynchronizationId);
    }
    return this.orderService.getOrderAndFeatures(orderId);
  }
}

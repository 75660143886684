import { Injectable } from '@angular/core';
import { BrowserInfo } from '@shared/model/browser-info.model';
import { WindowRefService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoService {
  constructor(private windowRefService: WindowRefService) {}

  getBrowserInfo(): BrowserInfo {
    return {
      colorDepth: this.windowRefService.nativeWindow.screen.colorDepth,
      screenHeight: this.windowRefService.nativeWindow.screen.height,
      screenWidth: this.windowRefService.nativeWindow.screen.width,
      timeZoneOffset: new Date().getTimezoneOffset().toString(),
      javaEnabled: this.windowRefService.nativeWindow.navigator?.javaEnabled() || false,
      javaScriptEnabled: true,
    };
  }
}

<ng-template #modal>
  <esw-modal
    [title]="'Order completed warning title' | translate"
    iconClass="ion-android-alert"
    [buttonText]="'Order completed warning button text' | translate"
    (buttonClick)="redirectToOrderConfirmation()"
  >
    <div class="error-modal-container">
      {{ 'Order completed warning message' | translate }}
    </div>
  </esw-modal>
</ng-template>

import { Inject, Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ICurrencyConfig } from '@app/core/ICurrencyFormatsConfig';
import { IPriceFormatsToCurrencySpecificConfig, IPriceFormatsToLocaleSpecificConfig } from '@app/core/IPriceFormatsConfig';
import { getDeliveryCountryIso, getLocaleState } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';
import { INTERNATIONALIZATION_CONFIGURATION, InternationalizationConfiguration } from '@configurations/i18n/i18n-configuration.token';

import { CustomCurrencyConfiguration } from '../pipes/currency/custom-currency-configuration';
import { ALL_COUNTRIES_ARE_ALLOWED } from '@shared/utils';

export const DEFAULT_CURRENCY_EXPONENT = 2;
export const DEFAULT_THOUSAND_SEPARATOR = ',';
export const DEFAULT_DECIMAL_SEPARATOR = '.';
export const DEFAULT_CONFIGURATION_STRING = '[Number][DecimalSeparator][Exponent] [CurrencyISO]';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  configurationChanged$: Observable<void>;
  private priceFormatConfigurationByCountryIso: IPriceFormatsToCurrencySpecificConfig[];

  constructor(
    private store: Store<IAppState>,
    @Inject(INTERNATIONALIZATION_CONFIGURATION)
    private internationalizationConfiguration: InternationalizationConfiguration,
  ) {
    this.initConfigurationChange();
  }

  getPriceConfiguration(currencyIso: string): IPriceFormatsToCurrencySpecificConfig {
    let priceFormatConfig: IPriceFormatsToCurrencySpecificConfig;
    if (this.priceFormatConfigurationByCountryIso) {
      priceFormatConfig = this.priceFormatConfigurationByCountryIso.find((pf: IPriceFormatsToCurrencySpecificConfig) =>
        pf.currencyIso.includes(currencyIso),
      );

      if (!priceFormatConfig) {
        priceFormatConfig = this.internationalizationConfiguration.priceConfigurations[0].configuration.find(
          (pf: IPriceFormatsToCurrencySpecificConfig) => pf.currencyIso.includes(currencyIso),
        );
      }
    }

    return {
      currencyIso: currencyIso,
      configurationString: DEFAULT_CONFIGURATION_STRING,
      thousandSeparator: DEFAULT_THOUSAND_SEPARATOR,
      decimalSeparator: DEFAULT_DECIMAL_SEPARATOR,
      ...priceFormatConfig,
    };
  }

  getCurrencyConfiguration(currencyIso: string): ICurrencyConfig {
    return {
      currencySymbol: currencyIso,
      currencyExponent: DEFAULT_CURRENCY_EXPONENT,
      ...this.internationalizationConfiguration.currencyConfigurations[currencyIso],
    };
  }

  getConfigurationForCurrency(currencyIso: string): Observable<CustomCurrencyConfiguration> {
    return this.configurationChanged$.pipe(
      map(() => {
        const priceConfiguration: IPriceFormatsToCurrencySpecificConfig = this.getPriceConfiguration(currencyIso);
        const currencyConfiguration: ICurrencyConfig = this.getCurrencyConfiguration(currencyIso);
        const configuration: CustomCurrencyConfiguration = {
          currencyCodeIso: currencyIso,
          currencySymbol: currencyConfiguration.currencySymbol,
          currencyExponent: currencyConfiguration.currencyExponent,
          configurationString: priceConfiguration.configurationString,
          thousandSeparator: priceConfiguration.thousandSeparator,
          decimalSeparator: priceConfiguration.decimalSeparator,
        };

        return configuration;
      }),
    );
  }

  private initConfigurationChange(): void {
    const languageIso$: Observable<string> = this.store.pipe(select(getLocaleState));
    const deliveryCountryIso$: Observable<string> = this.store.pipe(select(getDeliveryCountryIso));

    this.configurationChanged$ = combineLatest([languageIso$, deliveryCountryIso$]).pipe(
      filter(([languageIso, deliveryCountryIso]: [string, string]) => !!languageIso && !!deliveryCountryIso),
      map(([languageIso, deliveryCountryIso]: [string, string]) => {
        this.priceFormatConfigurationByCountryIso = this.getPriceFormatByLocale(
          deliveryCountryIso,
          languageIso,
          this.internationalizationConfiguration.priceConfigurations,
        );
      }),
    );
  }

  private getPriceFormatByLocale(
    deliveryCountryIso: string,
    languageIso: string,
    priceFormatsByCountriesIso: IPriceFormatsToLocaleSpecificConfig[],
  ): IPriceFormatsToCurrencySpecificConfig[] {
    let localeSpecificConfiguration: IPriceFormatsToCurrencySpecificConfig[];
    let countrySpecificConfiguration: IPriceFormatsToCurrencySpecificConfig[];
    let allCountriesConfiguration: IPriceFormatsToCurrencySpecificConfig[];

    priceFormatsByCountriesIso.forEach((config: IPriceFormatsToLocaleSpecificConfig) => {
      if (config.countriesIso.includes(ALL_COUNTRIES_ARE_ALLOWED)) {
        allCountriesConfiguration = config.configuration;
      } else if (config.countriesIso.includes(deliveryCountryIso)) {
        if (!config.languagesIso) {
          countrySpecificConfiguration = config.configuration;
        } else if (config.languagesIso.includes(languageIso)) {
          localeSpecificConfiguration = config.configuration;
        }
      }
    });

    return (
      localeSpecificConfiguration ||
      countrySpecificConfiguration ||
      allCountriesConfiguration ||
      priceFormatsByCountriesIso[0].configuration
    );
  }
}

import { WindowRefService } from '@app/core';
import { IAddressCompleteConfig } from '@env/IAddressCompleteConfig';

export class AddressCompleteService {
  private configuration: IAddressCompleteConfig;

  constructor(configuration: IAddressCompleteConfig, private windowRef: WindowRefService) {
    this.configuration = configuration;
  }

  getKey(): string {
    return this.configuration.key;
  }

  getName(): string {
    return this.configuration.name;
  }

  init() {
    if (this.configuration.key && !this.isLoaded()) {
      this.loadStyles();
      this.loadScript();
    }
  }

  private isLoaded(): boolean {
    const window = this.windowRef.nativeWindow;
    return typeof window.pca !== 'undefined' && window.pca;
  }

  private loadStyles() {
    (function(d) {
      const head = d.head || d.getElementsByTagName('head')[0];
      const link = d.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', 'https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css');
      head.appendChild(link);
    })(document);
  }

  private loadScript() {
    (function(d, s) {
      const e = d.getElementsByTagName(s)[0];
      const f: any = d.createElement(s);
      f.async = true;
      f.src = 'https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js';
      e.parentNode.insertBefore(f, e);
    })(document, 'script');
  }
}

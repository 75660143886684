import { LayoutConfigActions, LayoutConfigActionTypes } from './layout-config.actions';
import { IAppState } from '@app/store/app-init/app.state';
import { LayoutConfiguration } from '@configurations/configuration.token';

export interface LayoutConfigState {
  configuration: LayoutConfiguration | null;
}

export const layoutConfigInitialState: LayoutConfigState = {
  configuration: null,
};

export function layoutConfigReducer(state: IAppState, action: LayoutConfigActions): IAppState {
  switch (action.type) {
    case LayoutConfigActionTypes.SetLayoutConfiguration:
      return {
        ...state,
        layoutConfigState: {
          configuration: action.configuration,
        },
      };

    default:
      return state;
  }
}

import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class BusyIndicatorService implements OnDestroy {
  private counter = 0;
  private isBusySubject = new BehaviorSubject(false);

  isBusy$ = this.isBusySubject.pipe(distinctUntilChanged());

  ngOnDestroy() {
    this.isBusySubject.complete();
  }

  setBusy() {
    this.setStatus(false);
  }

  setAvailable() {
    this.setStatus(true);
  }

  private setStatus(isAvailable: boolean) {
    if (!isAvailable) {
      this.counter++;
    } else if (this.counter) {
      this.counter--;
    }

    this.isBusySubject.next(!!this.counter);
  }
}

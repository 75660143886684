import { MoneyAdapter } from '@shared/adapters/money.adapter';
import { DiscountInfoResponseDto, MetadataItemResponseDto, OrderItemResponseDto, ProductResponseDto } from '@shared/api';
import { CartItem, CartItems } from '@shared/model';
import { MetadataItemName } from '@shared/model/metadata-item-name.enum';

import { CartItemDiscountModel } from '../model/cart-item-discount.model';
import { MoneyModel } from '../model/money.model';

export class CartAdapter {
  public static createModel(items: OrderItemResponseDto[] = []): CartItems {
    return items.map(
      (item: OrderItemResponseDto): CartItem => {
        const cartItem = new CartItem();
        cartItem.id = item.product.productCode;
        cartItem.available = item.available;
        cartItem.title = item.product.title;
        cartItem.description = item.product.description;
        cartItem.imageUrl = item.product.imageUrl;
        cartItem.size = item.product.size;
        cartItem.color = item.product.color;
        cartItem.cartGrouping = item.cartGrouping;
        cartItem.metadataItems = item.product.metadataItems;
        cartItem.productCombinedItems = item.product.productCombinedItemsInfo?.productCombinedItems || item.product.customKitItems;
        cartItem.isProductInStock = item.product.isProductInStock;
        cartItem.quantity = item.quantity;
        cartItem.price = MoneyAdapter.createModel(item.product.shopperCurrencyProductPriceInfo.price);
        cartItem.totalPrice = MoneyAdapter.createModel(item.totalPrice);
        cartItem.hasGiftMessage = this.productHasGiftMessage(item.product);

        const discounts = item.product.shopperCurrencyProductPriceInfo.discounts;
        cartItem.discounts = discounts ? discounts.map(CartAdapter.createCartItemDiscountModel) : [];
        cartItem.priceBeforeDiscounts = this.getPriceBeforeDiscounts(cartItem);
        cartItem.estimatedDeliveryDatesForDeliveryOptions = item.estimatedDeliveryDatesForDeliveryOptions;

        if (item.product.retailerCurrencyProductPriceInfo) {
          cartItem.priceRetailerCurrency = MoneyAdapter.createModel(item.product.retailerCurrencyProductPriceInfo.price);
          const discountsRetailerCurrency = item.product.retailerCurrencyProductPriceInfo.discounts;
          cartItem.discountsRetailerCurrency = discountsRetailerCurrency
            ? discountsRetailerCurrency.map(CartAdapter.createCartItemDiscountModel)
            : [];
        }

        return cartItem;
      },
    );
  }

  private static createCartItemDiscountModel({
    title,
    description,
    discount,
    beforeDiscount,
  }: DiscountInfoResponseDto): CartItemDiscountModel {
    return {
      title,
      description,
      beforeDiscount: MoneyAdapter.createModel(beforeDiscount),
      amount: MoneyAdapter.createModel(discount),
    };
  }

  private static getPriceBeforeDiscounts(item: CartItem): MoneyModel {
    if (!item.discounts || !item.discounts.length) {
      return item.price;
    }

    return item.discounts[0].beforeDiscount;
  }

  private static productHasGiftMessage(product: ProductResponseDto): boolean {
    const giftMetadataItem: MetadataItemResponseDto | undefined = product.metadataItems?.find(
      (item: MetadataItemResponseDto) => item.name === MetadataItemName.GiftMessage,
    );
    if (!giftMetadataItem) {
      return false;
    }
    return true;
  }
}

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { ThemeInitializeSuccessAction } from '@app/store/app-init/app-init.actions';
import { CheckoutThemeState, IAppState, ThemeState } from '@app/store/app-init/app.state';
import { SetFeatureFlags } from '@app/store/feature-flag/feature-flag.actions';

import { DomWrapperService } from './dom-wrapper.service';
import { DynamicImportWrapperService } from './dynamic-import-wrapper.service';

@Injectable()
export class ThemeInjectorService {
  constructor(
    private store: Store<IAppState>,
    private domWrapperService: DomWrapperService,
    private dynamicImportWrapperService: DynamicImportWrapperService,
  ) {}

  injectTheme(theme?: ThemeState, featureFlags?: { [key: string]: boolean }): void {
    if (!theme || !theme.name) {
      this.loadNotThemedStyles();
      return;
    }

    const checkoutState: CheckoutThemeState = theme.checkout;
    if (checkoutState?.favicon) {
      this.domWrapperService.setFavicon(checkoutState.favicon);
    }

    if (checkoutState?.fontUrls) {
      this.loadCustomFonts(checkoutState.fontUrls);
    }

    if (checkoutState?.cssVariables) {
      this.setCssVariables(checkoutState.cssVariables);
    }

    if (featureFlags) {
      this.setFeatureFlags(featureFlags);
    }

    this.loadThemeStyles(theme.name);
  }

  private loadNotThemedStyles(): void {
    this.dynamicImportWrapperService
      .load(() => import(`../../../style/retailer-theme.js`))
      .then((m) => {
        this.domWrapperService.injectCss({ content: m.default });
        this.store.dispatch(new ThemeInitializeSuccessAction());
      });
  }

  private loadThemeStyles(themeName: string): void {
    this.dynamicImportWrapperService
      .load(
        () =>
          import(
            /* webpackChunkName: "[request]" */
            `../../../style/themes/${themeName}/index.js`
          ),
      )
      .then((m) => {
        this.domWrapperService.injectCss({ content: m.default });
        this.store.dispatch(new ThemeInitializeSuccessAction());
      });
  }

  private loadCustomFonts(fontUrls: string[]): void {
    fontUrls.forEach((fontUrl) => {
      this.domWrapperService.injectCss({ url: fontUrl });
    });
  }

  private setCssVariables(cssVariables: { [key: string]: string }): void {
    Object.keys(cssVariables).forEach((property: string) => {
      this.domWrapperService.setCssVariable(property, cssVariables[property]);
    });
  }

  private setFeatureFlags(flags: { [key: string]: boolean }): void {
    this.store.dispatch(new SetFeatureFlags({ type: 'preorder', flags: flags }));
  }
}

import { Action } from '@ngrx/store';

export enum LayoutActionType {
  MoveToShippingEdit = '[Layout] move to ShippinEdit',
  MoveToPaymentEdit = '[Layout] move to PaymentEdit',
  MoveToPaymentReview = '[Layout] move to PaymentReview',
  ChangeLanguage = '[Layout] change locale',
  SetIsRtlMode = '[Layout] set is rtl mode',
}

export class LayoutMoveToShippingEditAction implements Action {
  readonly type = LayoutActionType.MoveToShippingEdit;
}

export class LayoutMoveToPaymentEditAction implements Action {
  readonly type = LayoutActionType.MoveToPaymentEdit;
}

export class LayoutMoveToPaymentReviewAction implements Action {
  readonly type = LayoutActionType.MoveToPaymentReview;
}

export class LayoutChangeLanguageAction implements Action {
  readonly type = LayoutActionType.ChangeLanguage;
  constructor(public payload: string) {}
}

export class SetIsRtlModeAction implements Action {
  readonly type = LayoutActionType.SetIsRtlMode;
  constructor(public isRtlMode: boolean) {}
}

export type LayoutActions =
  | LayoutMoveToShippingEditAction
  | LayoutMoveToPaymentEditAction
  | LayoutMoveToPaymentReviewAction
  | SetIsRtlModeAction
  | LayoutChangeLanguageAction;

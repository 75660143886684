import { ThreeDSecureVersion } from '@esw/payments-express-panel';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { CartGroupingAdapter } from '@shared/adapters/cart-grouping.adapter';
import { CartAdapter } from '@shared/adapters/cart.adapter';
import { ContactInformationAdapter } from '@shared/adapters/contact-information.adapter';
import { DeliveryOptionAdapter } from '@shared/adapters/delivery-option.adapter';
import { OrderChargesAdapter } from '@shared/adapters/order-charges.adapter';
import { OrderDiscountAdapter } from '@shared/adapters/order-discount.adapter';
import { RetailerCheckoutExperienceAdapter } from '@shared/adapters/retailer-experience.adapter';
import { ShopperCheckoutExperienceAdapter } from '@shared/adapters/shopper-experience.adapter';
import {
  CalculatorNotificationResponseDto,
  ContactDetailsRequestDto,
  ContactDetailsType,
  DeliveryOption,
  Gender1,
  MetadataItemResponseDto,
  Option,
  OrderContactDetailsRequestDto,
  OrderDeliveryOptionResponseDto,
  OrderRequestDto,
  OrderResponseDto,
  PaymentThreeDSecureRedirectResponseDto,
  RetailerPromoCodeResponseDto,
  Status,
} from '@shared/api';
import { OrderModel, OrderStatus } from '@shared/model';
import { AddressModel } from '@shared/model/address.model';
import { ContactInformationModel, ContactInformationType } from '@shared/model/contact-information.model';
import { OrderDeliveryOptionModel } from '@shared/model/order-delivery-options.model';
import { PaymentProviderType } from '@shared/model/payment-provider-type.enum';
import { PhoneNumber } from '@shared/model/phone-number.model';
import { PromoCode } from '@shared/model/promo-code.model';

import { CalculatorNotificationsModel } from '../model/calculator-notifications.model';
import { IdCollectionModel } from '../model/id-collection.model';
import { OrderDiscountModel } from '../model/order-discount.model';
import { ShippingInfoUpdateModel } from '../model/shipping-info-update.model';

export class OrderAdapter {
  private static defaultTimeToExpiryInMinutes = 20;
  private static defaultTimeToExpiryInSeconds = OrderAdapter.defaultTimeToExpiryInMinutes * 60;

  static createOrder(orderResponse: OrderResponseDto): OrderModel {
    const useDeliveryAsPaymentAddress = orderResponse.useDeliveryContactDetailsForPaymentContactDetails;
    const deliveryContactInfo = this.createDeliveryContractInfo(orderResponse, useDeliveryAsPaymentAddress);
    const paymentContactInfo = this.createPaymentContactInfo(useDeliveryAsPaymentAddress, deliveryContactInfo, orderResponse);
    const timeToExpiry = this.getTimeToExpireFromResponse(orderResponse);
    const deliveryOptions = this.createDeliveryOptions(orderResponse);
    const retailerCheckoutExperience = this.createRetailerCheckoutExperience(orderResponse);
    const shopperCheckoutExperience = ShopperCheckoutExperienceAdapter.createShopperCheckoutExperience(
      orderResponse.shopperCheckoutExperience,
    );
    const cart = CartAdapter.createModel(orderResponse.orderItems);
    const restrictedItems = CartAdapter.createModel(orderResponse.restrictedItems);
    const charges = OrderChargesAdapter.createOrderChargesModel(orderResponse.charges);
    const status = this.getOrderStatus(orderResponse);
    const orderDiscounts = OrderDiscountAdapter.createOrderDiscounts(orderResponse.orderDiscounts);
    const shopperDiscounts = (orderDiscounts || []).map((discount) => discount.shopperCurrencyPrices);
    let retailerDiscounts: OrderDiscountModel[];
    orderDiscounts?.forEach((discount) => {
      if (discount?.retailerCurrencyPrices) {
        retailerDiscounts = retailerDiscounts || [];
        retailerDiscounts.push(discount.retailerCurrencyPrices);
      }
    });
    const promoCodes = this.createPromoCodes(orderResponse.retailerPromoCodes);
    const calculatorNotifications = this.createCalculatorNotifications(orderResponse.calculatorNotifications);

    const order = new OrderModel();

    order.id = orderResponse.orderCode;
    order.cart = CartGroupingAdapter.groupItems(cart);
    order.restrictedCartItems = CartGroupingAdapter.groupItems(restrictedItems);
    order.delivery = deliveryContactInfo;
    order.useDeliveryAsPaymentAddress = useDeliveryAsPaymentAddress;
    order.payment = paymentContactInfo;
    order.deliveryOption = orderResponse.deliveryOption;
    order.deliveryCountryIso = orderResponse.deliveryCountryIso;
    order.deliveryOptions = deliveryOptions;
    order.charges = charges;
    order.discounts = shopperDiscounts;
    order.discountsRetailerCurrency = retailerDiscounts;
    order.status = status;
    order.retailerCheckoutExperience = retailerCheckoutExperience;
    order.shopperCheckoutExperience = shopperCheckoutExperience;
    order.shopperCurrencyIso = orderResponse.shopperCurrencyIso;
    order.retailerCurrencyIso = orderResponse.retailerCurrencyIso;
    order.retailerOriginCountry = orderResponse.deliveryCountryIso;
    order.retailerCartId = orderResponse.retailerCartId;
    order.retailerOrderNumber = orderResponse.retailerOrderNumber;
    order.gdprAccepted = orderResponse.gdprAccepted;
    order.paymentId = orderResponse.payment.paymentId;
    order.paymentProviderType = orderResponse.payment.paymentProviderType as PaymentProviderType;
    order.paymentIsSuccessfull = orderResponse.payment.success;
    order.paymentCardLast4Digits = orderResponse.payment.cardLast4Digits;
    order.timeToExpiry = timeToExpiry;
    order.promoCodes = promoCodes;
    order.errors = orderResponse.errors;
    order.calculatorNotifications = calculatorNotifications;
    order.contactDetailsMetadataItems = this.getContactDetailsMetadataItems(orderResponse);

    if (orderResponse.payment.threeDSecureRedirect) {
      const redirectResponse: PaymentThreeDSecureRedirectResponseDto = orderResponse.payment.threeDSecureRedirect;
      order.paymentThreeDSecureRedirect = {
        // TODO: use the spread operator instead - 82293
        // currently, API doesn't support the required fields metadata, so this
        // custom mapping is required in order to resolve the optional/required types mismatch
        merchantData: redirectResponse.merchantData,
        paReq: redirectResponse.paReq,
        termUrl: redirectResponse.termUrl,
        url: redirectResponse.url,
        threeDSecureVersion: <ThreeDSecureVersion>redirectResponse.threeDSecureVersion,
      };
    }

    return order;
  }

  static createPaymentDetailsRequestDto(contactInfo: ContactInformationModel): OrderContactDetailsRequestDto {
    return this.createOrderContactDetailsRequestDto(
      contactInfo.type,
      contactInfo.address,
      contactInfo.email,
      contactInfo.telephone,
      contactInfo.idCollection,
    );
  }

  static createOrderRequestDto(orderCode: string, shippingInfo: ShippingInfoUpdateModel): OrderRequestDto {
    const contactInfo = shippingInfo.contactInfo;
    const contactInfoType =
      shippingInfo.selectedDeliveryMethod === DeliveryOption.Store
        ? ContactInformationType.IsDelivery
        : contactInfo.type || ContactInformationType.IsDeliveryAndPayment;
    const contactDetails = this.createContactDetailsRequestDto(
      contactInfo.address,
      contactInfo.email,
      contactInfo.telephone,
      contactInfo.idCollection,
    );
    const orderRequestDto: OrderRequestDto = {
      orderCode: orderCode,
      deliveryOptions: {
        option: <Option>shippingInfo.selectedDeliveryMethod,
      },
    };

    if (contactInfoType === ContactInformationType.IsDelivery || contactInfoType === ContactInformationType.IsDeliveryAndPayment) {
      orderRequestDto.deliveryDetails = contactDetails;
    }

    if (contactInfoType === ContactInformationType.IsPayment || contactInfoType === ContactInformationType.IsDeliveryAndPayment) {
      orderRequestDto.paymentDetails = contactDetails;
    }

    return orderRequestDto;
  }

  static createContactDetailsRequestDto(
    address: AddressModel,
    email: string,
    telephone: PhoneNumber,
    idCollection: IdCollectionModel,
    poBox?: string,
  ): ContactDetailsRequestDto {
    let intlPhoneNumber = null;

    if (telephone) {
      try {
        // Try get internationally formatted number from our PhoneNumber
        if (telephone.iso && telephone.intPhCode && telephone.number.charAt(0) !== '+') {
          const googPhoneNumber = parsePhoneNumberFromString(telephone.number, <CountryCode>telephone.iso);
          intlPhoneNumber = `+${telephone.intPhCode}${googPhoneNumber ? googPhoneNumber.nationalNumber : telephone.number}`;
        } else {
          intlPhoneNumber = telephone.number || null;
        }
      } catch (err) {
        // Could not parse the provided PhoneNumber
        intlPhoneNumber = telephone.number || null;
      }
    }
    const requestDto: ContactDetailsRequestDto = {
      id: address.id || 0, // if id is not present: push 0. Api will create a new Address
      contactDetailsNickName: '',
      address1: address.addressLine1,
      address2: address.addressLine2,
      address3: address.addressLine3,
      firstName: address.firstName,
      lastName: address.lastName,
      city: address.city,
      country: address.country.iso,
      postalCode: address.postalCode,
      region: address.region,
      email: email,
      gender: Gender1.None,
      telephone: intlPhoneNumber,
      poBox: poBox,
      additionalPersonalDetails: idCollection,
    };
    return requestDto;
  }

  private static getContactDetailsMetadataItems(orderResponse: OrderResponseDto): MetadataItemResponseDto[] {
    if (orderResponse.deliveryDetails?.metadataItems?.length > 0) {
      return orderResponse.deliveryDetails.metadataItems;
    }
    if (orderResponse.paymentDetails?.metadataItems?.length > 0) {
      return orderResponse.paymentDetails.metadataItems;
    }
    return [];
  }

  private static createPromoCodes(orderPromoCodes: RetailerPromoCodeResponseDto[]): PromoCode[] {
    if (orderPromoCodes?.length) {
      return orderPromoCodes.map((orderPromoCode: RetailerPromoCodeResponseDto) => {
        const promoCode = new PromoCode();
        promoCode.code = orderPromoCode.promoCode;
        promoCode.title = orderPromoCode.title;
        promoCode.description = orderPromoCode.description;
        return promoCode;
      });
    } else {
      return [];
    }
  }

  private static createRetailerCheckoutExperience(orderResponse: OrderResponseDto) {
    const retailerCheckoutExperience = RetailerCheckoutExperienceAdapter.createRetailerCheckoutExperience({
      backToCartUrl: orderResponse.retailerCheckoutExperience?.backToCartUrl,
      continueShoppingUrl: orderResponse.retailerCheckoutExperience?.continueShoppingUrl,
      customerServiceUrl: orderResponse.retailerCheckoutExperience?.customerServiceUrl,
    });

    return retailerCheckoutExperience;
  }

  private static createDeliveryOptions(orderResponse: OrderResponseDto): OrderDeliveryOptionModel[] {
    const deliveryOptions = (orderResponse.deliveryOptions || []).map((option: OrderDeliveryOptionResponseDto) =>
      DeliveryOptionAdapter.createModel(option, orderResponse.deliveryOption),
    );

    return deliveryOptions;
  }

  private static getTimeToExpireFromResponse(orderResponse: OrderResponseDto) {
    const timeToExpire =
      orderResponse.timeToExpiry !== null && orderResponse.timeToExpiry !== undefined && orderResponse.timeToExpiry >= 0
        ? orderResponse.timeToExpiry
        : OrderAdapter.defaultTimeToExpiryInSeconds;

    return timeToExpire;
  }

  private static createPaymentContactInfo(
    useDeliveryAsPaymentAddress: boolean,
    deliveryContactInfo: ContactInformationModel,
    orderResponse: OrderResponseDto,
  ) {
    const paymentContactInfo = useDeliveryAsPaymentAddress
      ? deliveryContactInfo
      : ContactInformationAdapter.createModel(orderResponse.paymentDetails, ContactInformationType.IsPayment);

    if (!paymentContactInfo.address.country.iso) {
      paymentContactInfo.address.country = { ...deliveryContactInfo.address.country };
    }

    return paymentContactInfo;
  }

  private static createDeliveryContractInfo(
    orderResponse: OrderResponseDto,
    useDeliveryAsPaymentAddress: boolean,
  ): ContactInformationModel {
    const deliveryContact = ContactInformationAdapter.createModel(
      orderResponse.deliveryDetails,
      useDeliveryAsPaymentAddress ? ContactInformationType.IsDeliveryAndPayment : ContactInformationType.IsDelivery,
    );

    return deliveryContact;
  }

  private static createOrderContactDetailsRequestDto(
    contactInformationType: ContactInformationType,
    address: AddressModel,
    email: string,
    telephone: PhoneNumber,
    idCollection: IdCollectionModel,
    poBox?: string,
  ): OrderContactDetailsRequestDto {
    const requestDto: OrderContactDetailsRequestDto = {
      contactDetails: this.createContactDetailsRequestDto(address, email, telephone, idCollection, poBox),
      contactDetailsType: ContactDetailsType[contactInformationType],
    };
    return requestDto;
  }

  private static getOrderStatus(orderResponse: OrderResponseDto): OrderStatus {
    switch (orderResponse.status) {
      case Status.Initialized:
        return OrderStatus.OnGoing;

      case Status.PaymentInProgress:
        return OrderStatus.PaymentInProgress;

      case Status.PaymentSuccessful:
      case Status.OrderBeingProcessed:
        return OrderStatus.BeingProcessed;

      case Status.RetailerConfirmationSuccess:
      case Status.OrderCompleted:
        if (orderResponse.isOrderReceiptAvailable === false) {
          return OrderStatus.TimedOut;
        }
        return OrderStatus.Completed;

      case Status.PaymentFailed:
        return OrderStatus.PaymentFailed;

      default:
        return OrderStatus.Failed;
    }
  }

  private static createCalculatorNotifications(
    calculatorNotifications: CalculatorNotificationResponseDto[],
  ): CalculatorNotificationsModel[] {
    return calculatorNotifications?.map((calculatorNotification) => {
      return {
        code: calculatorNotification.code,
        message: calculatorNotification.message,
        thresholdValues: calculatorNotification.thresholdValues,
      };
    });
  }
}

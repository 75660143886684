import { Injectable } from '@angular/core';

import { LocalStorageKey } from '@shared/model/local-storage-key.enum';

@Injectable({ providedIn: 'root' })
export class LocalStorageWrapperService {
  private readonly storageIsAvailable: boolean = this.isStorageAvailable(localStorage);

  getItem(key: LocalStorageKey): string | null {
    if (!this.storageIsAvailable) {
      return null;
    }
    return localStorage.getItem(key);
  }

  setItem(key: LocalStorageKey, value: string): void {
    if (!this.storageIsAvailable) {
      return;
    }
    localStorage.setItem(key, value);
  }

  removeItem(key: LocalStorageKey): void {
    if (!this.storageIsAvailable) {
      return;
    }
    localStorage.removeItem(key);
  }

  private isStorageAvailable(storage: Storage): boolean {
    if (!storage) {
      return false;
    }

    // Check if the storage can actually be accessed.
    try {
      const now = Date.now();
      const testItemKey = `storage-test-entry-${now}`;
      const testItemValue = `storage-test-value-${now}`;
      storage.setItem(testItemKey, testItemValue);
      const retrievedItemValue = storage.getItem(testItemKey);
      storage.removeItem(testItemKey);

      return retrievedItemValue === testItemValue;
    } catch (error) {
      return false;
    }
  }
}

import { UrlMatchResult, UrlSegment } from '@angular/router';

export function urlMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (!segments[0]) {
    return null;
  }

  if (isStartFromCountryIso(segments)) {
    if (segments[1]) {
      return { consumed: segments.slice(0, 2), posParams: { countryIso: segments[0], orderId: segments[1] } };
    }
    return null;
  }
  return { consumed: segments.slice(0, 1), posParams: { orderId: segments[0] } };
}

function isStartFromCountryIso(segments: UrlSegment[]): boolean {
  return segments[0].path.length === 2;
}

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { getLocaleState } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';

const DEFAULT_DATE_FORMAT = 'd MMMM';
@Pipe({
  name: 'eswDate',
  pure: false,
})
export class TranslateDatePipe implements OnDestroy, PipeTransform {
  private localeChangeSub$: Subscription;
  private lang: string;
  private lastLang: string;
  private lastFormat: string;
  private lastValue: any;
  private lastDate: string;

  constructor(private store: Store<IAppState>, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.localeChangeSub$ = this.store.pipe(select(getLocaleState)).subscribe((lang: string) => {
      this.lang = lang;
      this.changeDetectorRef.markForCheck();
    });
  }

  transform(value: any, format?: string): string | null {
    if (this.lang === this.lastLang && format === this.lastFormat && value === this.lastValue) {
      return this.lastDate;
    }
    return this.getNewDate(value, format);
  }

  private getNewDate(value: any, format: string) {
    const datePipe = new DatePipe('en');
    const lastDate = datePipe.transform(value, format ? format : DEFAULT_DATE_FORMAT, 'UTC', this.lang);

    this.lastValue = value;
    this.lastFormat = format;
    this.lastLang = this.lang;
    this.lastDate = lastDate;

    return lastDate;
  }

  ngOnDestroy(): void {
    this.localeChangeSub$.unsubscribe();
  }
}

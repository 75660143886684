import { MoneyAdapter } from '@shared/adapters/money.adapter';
import { Name as OrderChargesTypes, OrderChargeResponseDto, Party } from '@shared/api';
import { OrderChargesModel } from '@shared/model/order-charges.model';

import { MoneyModel } from '../model/money.model';

export class OrderChargesAdapter {
  public static createOrderChargesModel(orderChargeResponseDtos: OrderChargeResponseDto[]): OrderChargesModel {
    const orderCharges: OrderChargesModel = new OrderChargesModel();
    let itemsCharge: MoneyModel;
    let itemsBeforeCartDiscountsCharge: MoneyModel;
    let cartDiscountCharge: MoneyModel;

    orderChargeResponseDtos.forEach((orderChargeResponseDto) => {
      const money = MoneyAdapter.createModel(orderChargeResponseDto.value);

      switch (orderChargeResponseDto.name) {
        case OrderChargesTypes.TotalLineItemBeforeDiscounts:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.items = money;
          } else if (orderChargeResponseDto.party === Party.Retailer) {
            orderCharges.totalLineItemBeforeDiscountsRetailerCurrency = money;
          }
          break;

        case OrderChargesTypes.TotalBeforeCartDiscounts:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            itemsBeforeCartDiscountsCharge = money;
          } else if (orderChargeResponseDto.party === Party.Retailer) {
            orderCharges.totalBeforeCartDiscountsRetailerCurrency = money;
          }
          break;

        case OrderChargesTypes.Items:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            itemsCharge = money;
            orderCharges.itemsAfterDiscount = money;
          } else if (orderChargeResponseDto.party === Party.Retailer) {
            orderCharges.itemsRetailerCurrency = money;
          }
          break;

        case OrderChargesTypes.Delivery:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.shipping = money;
          }
          break;

        case OrderChargesTypes.TaxLine1:
        case OrderChargesTypes.DutyAndTaxLine1:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.tax = money;
          }
          break;

        case OrderChargesTypes.DutyLine1:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.duty = money;
          }
          break;

        case OrderChargesTypes.TotalCartDiscountAmount:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            cartDiscountCharge = money;
          }
          break;

        case OrderChargesTypes.TotalLineItemAndTotalCartDiscountAmount:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.discount = money;
          }
          break;

        case OrderChargesTypes.Total:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.total = money;
          } else if (orderChargeResponseDto.party === Party.Retailer) {
            orderCharges.totalRetailerCurrency = money;
          }
          break;

        case OrderChargesTypes.TotalBeforeDiscounts:
          if (!orderChargeResponseDto.party || orderChargeResponseDto.party === Party.Shopper) {
            orderCharges.totalBeforeDiscounts = money;
          }
          break;
      }
    });

    orderCharges.items = orderCharges.items || itemsBeforeCartDiscountsCharge || itemsCharge;
    orderCharges.discount = orderCharges.discount || cartDiscountCharge;

    return orderCharges;
  }
}

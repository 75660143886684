import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { GenericErrorTemplateEnum } from '@app/errors/generic-error/generic-error-template.enum';
import { LayoutRoutesNames } from '@app/layout/layout.routes.names';
import { OrderInitializeAction, OrderInitializeMoveToPaymentAction } from '@app/store/app-init/app-init.actions';
import { getMainState } from '@app/store/app-init/app-init.reducer';
import { IAppState, ICheckoutState } from '@app/store/app-init/app.state';
import { BillingShowPaymentErrorAction } from '@app/store/billing/billing.actions';
import { SetExpressPaymentErrorIsVisible } from '@app/store/express-payments/express-payments.actions';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { OrderStatus } from '@shared/model/order-status.enum';
import { PaymentProviderType } from '@shared/model/payment-provider-type.enum';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

import { OrderService } from '../order.service';
import { getRoutePath } from './navigation-helper';

@Injectable()
export class OnlyOngoingOrdersGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private appInsights: AppInsightsWrapperService,
    private orderService: OrderService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const orderIdCandidate = next.paramMap.get('orderId');
    const countryIsoCandidate = next.paramMap.get('countryIso');
    this.store.dispatch(new OrderInitializeAction(orderIdCandidate));

    return this.store.pipe(
      select(getMainState),
      filter((app) => !!app.appInitialized),
      map((app: IAppState) => app.checkout),
      take(1),
      switchMap((checkout: ICheckoutState) => {
        if (!checkout || !checkout.order || checkout.order.status === null || checkout.order.status === undefined) {
          throw new Error('order badly formated');
        }

        switch (checkout.order.status) {
          case OrderStatus.OnGoing:
          case OrderStatus.PaymentInProgress:
            this.appInsights.trackEvent(AppInsightsEventName.CheckoutLoadedEvent);
            return of(true);
          case OrderStatus.BeingProcessed:
          case OrderStatus.Completed:
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, 'confirmation']));
            return of(false);
          case OrderStatus.PaymentFailed:
            this.appInsights.trackEvent(AppInsightsEventName.PaymentNotSuccessfulEvent);

            if (checkout.order?.paymentProviderType === PaymentProviderType.Express) {
              this.store.dispatch(new SetExpressPaymentErrorIsVisible());
              return of(true);
            }

            return this.orderService.putRetryPayment(orderIdCandidate).pipe(
              map(() => {
                this.store.dispatch(new OrderInitializeMoveToPaymentAction());
                this.store.dispatch(new BillingShowPaymentErrorAction());

                this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));
                return false;
              }),
            );
          case OrderStatus.TimedOut:
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]), {
              state: { template: GenericErrorTemplateEnum.ConfirmationPage },
            });
            return of(false);
          default:
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]));
            return of(false);
        }
      }),
      catchError((a, b) => {
        this.router.navigate(getRoutePath(countryIsoCandidate, [LayoutRoutesNames.Error]));
        return of(false);
      }),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}

import { IAppState } from '@app/store/app-init/app.state';
import { ContactInformationType } from '@shared/model/contact-information.model';

import {
  BillingContactInformationUpdateActions,
  BillingContactInformationUpdateActionType,
} from './billing-address-update.actions';

export function billingContactInformationUpdateReducer(state: IAppState, action: BillingContactInformationUpdateActions): IAppState {
  switch (action.type) {
    case BillingContactInformationUpdateActionType.UpdateSuccess:
      const useDeliveryContactInfoForPayment = action.payload.type === ContactInformationType.IsDeliveryAndPayment;
      const deliveryType = useDeliveryContactInfoForPayment
        ? ContactInformationType.IsDeliveryAndPayment
        : ContactInformationType.IsDelivery;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            payment: action.payload,
            delivery: { ...state.checkout.order.delivery, type: deliveryType },
            useDeliveryAsPaymentAddress: useDeliveryContactInfoForPayment,
          },
        },
      };

    default:
      return state;
  }
}

import { FeatureName } from '@configurations/feature-name.enum';
import { OrderFeatureResponseDto, Type } from '@shared/api';

import { FeatureModel } from '../model/feature.model';

export class FeatureAdapter {
  private static readonly featuresMap: Map<Type, FeatureName> = new Map<Type, FeatureName>([
    [Type.ExpressPaymentMethods, FeatureName.ExpressPaymentMethodsEnabled],
    [Type.PerformanceTestBaseline, FeatureName.PerformanceTestBaseline],
    [Type.RegionExclusionValidation, FeatureName.RegionExclusionValidation],
    [Type.PaymentPanelV2, FeatureName.PaymentPanelV2Enabled],
    [Type.InventoryCheck, FeatureName.InventoryCheckEnabled],
    [Type.PaymentPanelProfileV2, FeatureName.PaymentPanelProfileV2],
    [Type.IdCollectionFunctionality, FeatureName.IdCollectionFunctionality],
    [Type.RestrictedProducts, FeatureName.RestrictedCartItemsCheckEnabled],
    [Type.ApoFpoPoBoxValidation, FeatureName.BlockApoFpoPoBoxShippingAddress],
    [Type.ShipToStore, FeatureName.ShipToStore],
    [Type.CountryCodeInCheckoutUrl, FeatureName.CountryCodeInCheckoutUrl],
  ]);

  public static createFeatures(orderFeatureResponseDtos: OrderFeatureResponseDto[]): FeatureModel[] {
    return (orderFeatureResponseDtos || [])
      .map((orderFeatureResponseDto) => this.createModel(orderFeatureResponseDto))
      .filter((model: FeatureModel | null) => model != null);
  }

  private static createModel(orderFeatureResponseDto: OrderFeatureResponseDto): FeatureModel | null {
    if (orderFeatureResponseDto && this.featuresMap.has(orderFeatureResponseDto.type)) {
      return {
        type: this.featuresMap.get(orderFeatureResponseDto.type),
        active: orderFeatureResponseDto.active,
      };
    }
    return null;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { CustomCurrencyConfiguration } from '@app/shared/pipes/currency/custom-currency-configuration';
import { MoneyModel } from '@shared/model/money.model';
import { CurrencyService } from '@shared/services/currency.service';

@Component({
  selector: 'esw-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent implements OnInit {
  @Input()
  set price(price: MoneyModel) {
    if (!price) {
      return;
    }

    this.value = price.value;

    this.currencyIso$.next(price.currencyCodeISO);
  }

  @Input()
  isFreeTextEnabled = true;

  @Input()
  freeTextKey = 'Free';

  value: number;
  configuration$: Observable<CustomCurrencyConfiguration>;

  private currencyIso$ = new ReplaySubject<string>(1);

  constructor(private currencyService: CurrencyService) {}

  ngOnInit(): void {
    this.configuration$ = this.currencyIso$.pipe(
      distinctUntilChanged(),
      switchMap((currencyIso: string) => this.currencyService.getConfigurationForCurrency(currencyIso)),
    );
  }
}

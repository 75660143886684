export enum PaymentPanelEventName {
  SetIFrameHeight = 'setIFrameHeightEvent',
  PaymentAttempt = 'paymentAttemptEvent',
  PaymentMethodAction = 'payment_method_action',
}

export enum PaymentPanelV2EventName {
  Initialize = 'initialize',
  Initialized = 'initialized',
  PaymentAttempt = 'processing',
  Authorize = 'authorize',
  Authorizing = 'authorizing',
  PaymentMethodChanged = 'payment_method_changed',
  PaymentMethodAction = 'payment_method_action',
  InstallmentPlanChanged = 'installment_plan_changed',
}

export class PaymentPanelEvent {
  name: PaymentPanelEventName | string;
}

export class SetIFrameHeightEvent extends PaymentPanelEvent {
  height: number;
}

export class PaymentAttemptEvent extends PaymentPanelEvent {
  paymentMethod: string;
}

export class PaymentMethodActionEvent extends PaymentPanelEvent {
  eventData: {
    paymentMethod: string;
    imageLinkDesktop: string;
    imageLinkMobile: string;
    termsLink: string;
  };
}

export class PaymentPanelV2Event {
  eventName?: PaymentPanelV2EventName;
}

export class InitializeEvent extends PaymentPanelV2Event {
  eventData: {
    authorization_confirmation?: boolean;
    i18n?: {
      localized_text: { [key: string]: string };
    };
    style?: {
      paymentPanelThemeUrl?: string;
      cardPanelThemeUrl?: string;
      cssVariablesList?: { [key: string]: string };
      cardPanelFontUrls?: string[];
      paymentPanelFontUrls?: string[];
    };
  };
}

export class InitializedEvent extends PaymentPanelV2Event {
  eventData: {
    documentHeight: number;
  };
}

export class AuthorizeEvent extends PaymentPanelV2Event {
  eventData: {
    continue: boolean;
    profile_token?: string;
  };
}

export class AuthorizingEvent extends PaymentPanelV2Event {
  eventData: {
    paymentMethod: string;
  };
}

export class PaymentAttemptV2Event extends AuthorizingEvent {}

export class PaymentMethodChangedEvent extends PaymentPanelV2Event {
  eventData: {
    selectedMethod: string;
    documentHeight: number;
  };
}

export class PaymentMethodActionV2Event extends PaymentPanelV2Event {
  eventData: {
    paymentMethod: string;
    imageLinkDesktop: string;
    imageLinkMobile: string;
    termsLink: string;
  };
}

export class InstallmentPlanChangedEvent extends PaymentPanelV2Event {
  eventData: {
    currencyIso: string;
    totalAmount: string;
    isSinglePayment: boolean;
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartModule } from '@app/cart/cart.module';
import { RestrictedCartItemsModalComponent } from '@core/restricted-cart-items-modal/restricted-cart-items-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUIModule } from '@shared/ui';

@NgModule({
  imports: [CommonModule, CartModule, TranslateModule.forChild(), SharedUIModule],
  declarations: [RestrictedCartItemsModalComponent],
})
export class RestrictedCartItemsModalModule {}

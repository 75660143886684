import { MoneyModel } from '@shared/model/money.model';

import { CartItemDeliveryDateInformation } from './cart-item-delivery-date-information';
import { CartItemDiscountModel } from './cart-item-discount.model';
import { IProductSubItem } from './product-sub-item.model';
import { IMetadataItem } from '@shared/model/metadata-item.model';

export class CartItem {
  id?: string;
  color?: string;
  size?: string;
  available?: boolean;
  title?: string;
  quantity?: number;
  isGrouped?: boolean;
  cartGrouping?: string;
  description?: string;
  imageUrl?: string;
  price?: MoneyModel;
  priceRetailerCurrency?: MoneyModel;
  priceBeforeDiscounts?: MoneyModel;
  discounts?: CartItemDiscountModel[];
  discountsRetailerCurrency?: CartItemDiscountModel[];
  metadataItems?: IMetadataItem[];
  productUrl?: string;
  totalPrice?: MoneyModel;
  productCombinedItems?: IProductSubItem[];
  isProductInStock?: boolean;
  estimatedDeliveryDatesForDeliveryOptions?: CartItemDeliveryDateInformation[];
  hasGiftMessage?: boolean;
}

export type CartItems = CartItem[];

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WindowRefService } from '@app/core/windowref/windowref.service';
import { waitFor } from '@app/shared/rxjs/wait-for';

declare const correlation: correlation;

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
  constructor(private windowRefService: WindowRefService, private ngZone: NgZone) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const window = this.windowRefService.nativeWindow;

    return waitFor(this.ngZone, () => !!window.correlation).pipe(
      switchMap(() => from(correlation.getCorrelationId())),
      switchMap((correlationId: string) => {
        request = request.clone({
          setHeaders: {
            FingerprintId: correlationId,
          },
        });

        return next.handle(request);
      }),
    );
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { BusyIndicatorService } from '@core/busyIndicator';

import { getQueryParamsState } from './store';
import { AppInitializeAction, SetAppAsNativeAction } from './store/app-init/app-init.actions';
import { IAppState } from './store/app-init/app.state';

@Component({
  selector: 'esw-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private busyIndicatorService: BusyIndicatorService, private store: Store<IAppState>, private route: ActivatedRoute) {
    this.busyIndicatorService.setBusy();
    this.defineAppPlatformFromQueryParams();
    this.store.dispatch(new AppInitializeAction());
  }

  private defineAppPlatformFromQueryParams() {
    this.store.pipe(select(getQueryParamsState)).subscribe((params: Params) => {
      if (params && params['native-app'] === 'true') {
        this.store.dispatch(new SetAppAsNativeAction());
      }
    });
  }
}

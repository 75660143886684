import { IAppState, LayoutStateEnum } from '@app/store/app-init/app.state';

import { LayoutActions, LayoutActionType } from './layout.actions';

export function layoutReducer(state: IAppState, action: LayoutActions): IAppState {
  switch (action.type) {
    case LayoutActionType.MoveToShippingEdit:
      return { ...state, layoutState: { ...state.layoutState, current: LayoutStateEnum.ShippingEdit } };

    case LayoutActionType.MoveToPaymentEdit:
      return { ...state, layoutState: { ...state.layoutState, current: LayoutStateEnum.PaymentEdit } };

    case LayoutActionType.MoveToPaymentReview:
      return { ...state, layoutState: { ...state.layoutState, current: LayoutStateEnum.PaymentReview } };

    case LayoutActionType.ChangeLanguage:
      return {
        ...state,
        layoutState: {
          ...state.layoutState,
          locale: action.payload,
        },
      };

    case LayoutActionType.SetIsRtlMode:
      return {
        ...state,
        layoutState: {
          ...state.layoutState,
          isRtlMode: action.isRtlMode,
        },
      };

    default:
      return state;
  }
}

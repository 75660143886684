import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'esw-leave-page-modal',
  templateUrl: './leave-page-modal.component.html',
  styleUrls: ['./leave-page-modal.component.scss'],
})
export class LeavePageModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}

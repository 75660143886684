import { IAppState } from '@app/store/app-init/app.state';
import {
  ShippingInfoUpdateActions,
  ShippingInfoUpdateActionType,
} from '@app/store/shipping-info-update/shipping-info-update.actions';

export function shippingInfoUpdateReducer(state: IAppState, action: ShippingInfoUpdateActions): IAppState {
  switch (action.type) {
    case ShippingInfoUpdateActionType.ShippingInfoUpdateSuccess:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            deliveryOption: action.payload.selectedDeliveryMethod,
            delivery: action.payload.contactInfo,
          },
        },
      };

    case ShippingInfoUpdateActionType.MarketingEmailOptInUpdateSuccess:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            shopperCheckoutExperience: {
              ...state.checkout.order.shopperCheckoutExperience,
              marketingEmailOptInConfirmed: action.payload,
            },
          },
        },
      };

    case ShippingInfoUpdateActionType.OrderChargesUpdate:
      return {
        ...state,
        shippingState: {
          ...state.shippingState,
          isOrderChargesUpdating: true,
        },
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            deliveryOption: action.payload.deliveryOption,
          },
        },
      };

    case ShippingInfoUpdateActionType.OrderChargesUpdateSuccess:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            charges: action.payload,
          },
        },
        shippingState: {
          ...state.shippingState,
          isOrderChargesUpdating: false,
        },
      };

    case ShippingInfoUpdateActionType.OrderChargesUpdateFailure:
      return {
        ...state,
        shippingState: {
          ...state.shippingState,
          isOrderChargesUpdating: false,
        },
      };

    default:
      return state;
  }
}

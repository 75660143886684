import { Injectable } from '@angular/core';

function clickBackButton() {
  window.history.go(1);
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService<T extends Window = any> {
  get nativeWindow(): T {
    return (<unknown>window) as T;
  }

  disableClickBack() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', clickBackButton);
  }
}

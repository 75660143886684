export interface FontModel {
  name: string;
  source?: string;
}

export enum MobileDisplayUnit {
  ItemCount = 'itemCount',
  Price = 'price',
}

export enum ButtonStyle {
  Square = 'square',
  Round = 'round',
}

export interface StylingModel {
  primaryColor?: string;
  primaryFontFamily?: FontModel;
  logo?: string;
  favicon?: string;
  buttonStyle?: ButtonStyle;
}

export interface ThemeModelDto {
  name: string;
  styling?: StylingModel;
}

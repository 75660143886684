import { IAppState } from '@app/store/app-init/app.state';
import { reducerAgregator } from '@shared/utils';

import { analyticsReducer } from './analytics/analytics.reducer';
import { billingContactInformationUpdateReducer } from './billing-address-update/billing-address-update.reducer';
import { billingReducer } from './billing/billing.reducer';
import { gdprConsentUpdateReducer } from './gdpr-update/gdpr-update.reducer';
import { layoutReducer } from './layout/layout.reducer';
import { paymentOptionsGetReducer } from './payment-options-get/payment-options-get.reducer';
import { shippingInfoUpdateReducer } from './shipping-info-update/shipping-info-update.reducer';
import { transliterationReducer } from './transliteration/transliteration.reducer';

// add any sub-reducer functions into the list
const featureReducers = [
  shippingInfoUpdateReducer,
  analyticsReducer,
  layoutReducer,
  billingReducer,
  billingContactInformationUpdateReducer,
  paymentOptionsGetReducer,
  gdprConsentUpdateReducer,
  transliterationReducer,
];

export function checkoutReducer(state: IAppState, action) {
  return reducerAgregator<IAppState>(featureReducers, state, action);
}

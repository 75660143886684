import { Injectable } from '@angular/core';

import { WindowRefService } from '../windowref/windowref.service';

@Injectable()
export class QueryStringService {
  constructor(private window: WindowRefService) {}

  /**
   * Gets the value of a query string parameter of a given url
   * @param name name of the query string parameter
   * @param url (Optional) url to get parameters from, uses current window url if not provided
   *
   * @returns the value as string, null if parameter not found
   */
  getQueryStringParameter(paramName: string, baseUrl?: string): string {
    const url = baseUrl || this.window.nativeWindow.location.href;
    const name = paramName.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    const paramValue = decodeURIComponent(results[2].replace(/\+/g, ' '));

    return paramValue;
  }
}

import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, timeout } from 'rxjs/operators';

import { IGlobalAppSettings } from '@core/IAppSettings';
import { WindowRefService } from '@core/windowref/windowref.service';

@Injectable()
export class GoogleMapsWrapperService implements OnDestroy {
  isInitialized$: Observable<boolean>;

  private googleMapsApiKey: string;
  private initCheckIntervalTimeMs = 500;
  private initCheckTimeoutMs = 3000;
  private geocoder: google.maps.Geocoder;
  private distanceMatrixService: google.maps.DistanceMatrixService;
  private okStatus = 'OK';
  private travelModeDriving = 'DRIVING';

  private isInitializedSubject$ = new BehaviorSubject(false);
  private tearDown$ = new Subject();

  constructor(private windowRefService: WindowRefService, private ngZone: NgZone, appSettings: IGlobalAppSettings) {
    this.googleMapsApiKey = appSettings.googleMapsApiKey;
    this.isInitialized$ = this.isInitializedSubject$.asObservable();
  }

  ngOnDestroy() {
    this.isInitializedSubject$.complete();
    this.tearDown$.next();
    this.tearDown$.complete();
  }

  init() {
    if (!this.googleMapsApiKey || this.isInitializedSubject$.value) {
      return;
    }

    this.loadScript(this.googleMapsApiKey);
    this.trackInit();
  }

  initMap(mapDiv: Element | null, opts?: google.maps.MapOptions): google.maps.Map {
    let newMap: google.maps.Map;

    this.ngZone.runOutsideAngular(() => {
      newMap = new this.windowRefService.nativeWindow.google.maps.Map(mapDiv, opts);
    });

    return newMap;
  }

  geocode(address: string): Observable<google.maps.GeocoderResult[]> {
    let result: Observable<google.maps.GeocoderResult[]>;

    this.ngZone.runOutsideAngular(() => {
      result = new Observable(observer => {
        this.geocoder.geocode({ address: address }, (results, status) => {
          this.ngZone.run(() => {
            if (<any>status !== this.okStatus) {
              observer.error();
            } else {
              observer.next(results);
              observer.complete();
            }
          });
        });
      });
    });

    return result;
  }

  createMarker(options: google.maps.ReadonlyMarkerOptions): google.maps.Marker {
    let newMarker: google.maps.Marker;

    this.ngZone.runOutsideAngular(() => {
      newMarker = new this.windowRefService.nativeWindow.google.maps.Marker(options);
    });

    return newMarker;
  }

  removeMarker(marker: google.maps.Marker) {
    marker.setMap(null);
  }

  createInfoWindow(options: google.maps.InfoWindowOptions): google.maps.InfoWindow {
    let newInfoWindow: google.maps.InfoWindow;

    this.ngZone.runOutsideAngular(() => {
      newInfoWindow = new this.windowRefService.nativeWindow.google.maps.InfoWindow(options);
    });

    return newInfoWindow;
  }

  getDistance(request: google.maps.DistanceMatrixRequest): Observable<google.maps.Distance> {
    let result: Observable<google.maps.Distance>;
    const defaultParams: google.maps.DistanceMatrixRequest = {
      travelMode: <any>this.travelModeDriving,
    };

    this.ngZone.runOutsideAngular(() => {
      result = new Observable(observer => {
        this.distanceMatrixService.getDistanceMatrix({ ...defaultParams, ...request }, (response, status) => {
          this.ngZone.run(() => {
            if (<any>status !== this.okStatus) {
              observer.error();
            } else {
              observer.next(response.rows[0].elements[0].distance);
              observer.complete();
            }
          });
        });
      });
    });

    return result;
  }

  private trackInit() {
    this.ngZone.runOutsideAngular(() => {
      const nativeWindow = this.windowRefService.nativeWindow;

      interval(this.initCheckIntervalTimeMs)
        .pipe(
          map(() => typeof nativeWindow.google !== 'undefined' && nativeWindow.google),
          filter(isInitialized => !!isInitialized),
          take(1),
          timeout(this.initCheckTimeoutMs),
          takeUntil(this.tearDown$),
        )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.isInitializedSubject$.next(true);
          });
          this.geocoder = new nativeWindow.google.maps.Geocoder();
          this.distanceMatrixService = new nativeWindow.google.maps.DistanceMatrixService();
        });
    });
  }

  private loadScript(googleMapsApiKey: string) {
    (function(d, s) {
      const e = d.getElementsByTagName(s)[0];
      const f: any = d.createElement(s);
      f.async = true;
      f.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`;
      e.parentNode.insertBefore(f, e);
    })(this.windowRefService.nativeWindow.document, 'script');
  }
}

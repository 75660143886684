import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpStatusCode } from '@shared/model/http-status-code.enum';
import { NotificationCode } from '@shared/model/notification-code.enum';
import { OrderUtils } from '@shared/utils';

import { NotificationService } from '../notification.service';

@Injectable()
export class OrderErrorResponseInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Gone) {
          this.notificationService.notify(NotificationCode.OrderExpired);
        } else if (OrderUtils.isOrderCompletedError(error)) {
          this.notificationService.notify(NotificationCode.OrderAlreadyCompleted);
        }

        return throwError(error);
      }),
    );
  }
}

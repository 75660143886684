import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LazyComponentFactoryModule } from '@esw/checkout-common-ui/lazy-component-factory';
import { XPANEL_CONFIG } from '@esw/payments-express-panel';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppInitEffects } from '@app/store/app-init/app-init.effects';
import { LayoutConfigEffects } from '@app/store/layout-config/layout-config.effects';
import { LayoutEffects } from '@app/store/layout/layout.effects';
import { CONFIGURATION } from '@configurations/configuration.token';
import { EXTERNAL_STYLES_CONFIGURATION } from '@configurations/external-styles-configuration.token';
import { FEATURESET } from '@configurations/feature-set.token';
import { INTERNATIONALIZATION_CONFIGURATION } from '@configurations/i18n/i18n-configuration.token';
import { LANGUAGE_CONFIGURATION } from '@configurations/i18n/language-configuration.token';
import { URLSET } from '@configurations/url-set.token';
import { CoreModule, IGlobalAppSettings } from '@core/.';
import { RestrictedCartItemsModalModule } from '@core/restricted-cart-items-modal/restricted-cart-items-modal.module';
import { environment } from '@env/environment';
import { ENVIRONMENT } from '@env/environment.token';
import { TranslateLoaderService } from '@shared/services';
import { SharedServicesModule } from '@shared/services/shared-services.module';
import { MissingTranslationHighlighter } from '@shared/ui';
import { SharedUIModule } from '@shared/ui/shared-ui.module';

import { AppComponent } from './app.component';
import { AppRoutingModule, routedComponents } from './app.routing.module';
import { GlobalAppSettingsService } from './globalAppSettings.service';
import { FingerprintInterceptor } from './shared/services/interceptors/fingerprint.interceptor';
import { NoCacheOnGetInterceptor } from './shared/services/interceptors/no-cache-on-get.interceptor';
import { OrderErrorResponseInterceptor } from './shared/services/interceptors/order-error-response.interceptor';
import { CustomErrorHandler } from './shared/utils/custom-error-handler';
import { CustomRouterStateSerializer, reducers } from './store';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    LazyComponentFactoryModule,
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: true,
        // strictActionSerializability: true, // TODO: convert all state models to interfaces
        strictStateImmutability: true,
        // strictStateSerializability: true,
      },
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomRouterStateSerializer,
    }),

    environment.imports || [],
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([AppInitEffects, LayoutConfigEffects, LayoutEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHighlighter,
      },
      useDefaultLang: false,
    }),
    Angulartics2Module.forRoot(),
    SharedServicesModule,
    SharedUIModule,
    RestrictedCartItemsModalModule,
  ],
  declarations: [...routedComponents],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: FEATURESET, useValue: require('src/generated/feature-set.generated.json') },
    { provide: URLSET, useValue: require('src/generated/url-set.generated.json') },
    { provide: CONFIGURATION, useValue: require('src/generated/configuration.generated.json') },
    {
      provide: INTERNATIONALIZATION_CONFIGURATION,
      useValue: require('src/generated/i18n/i18n-configuration.generated.json'),
    },
    {
      provide: LANGUAGE_CONFIGURATION,
      useValue: require('src/generated/i18n/language-configuration.generated.json'),
    },
    {
      provide: EXTERNAL_STYLES_CONFIGURATION,
      useValue: require('src/generated/external-styles/configuration.generated.json'),
    },
    { provide: IGlobalAppSettings, useClass: GlobalAppSettingsService },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrderErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheOnGetInterceptor,
      multi: true,
    },
    {
      provide: XPANEL_CONFIG,
      useValue: {
        paymentsPanelUrl: environment.expressPaymentsPanelUrl,
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

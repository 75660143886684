import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IAppState } from '@app/store/app-init/app.state';
import { getFeatureIsEnabled } from '@app/store/feature-flag/feature-flag.reducer';
import { FeatureName } from '@configurations/feature-name.enum';

/**
 * The feature toggle directive will decide to render or not the component/element.
 * When isEnabled retur ns true, then render the component.
 * When isEnabled returns false, then do not render the component.
 *
 * There are two different contexts when using the feature toggle:
 * - You either want to add a new feature (then, you probably don't need the '!' negate). It would be show this or nothing.
 * - Or you want to modify a current behavior (then, you will probably need the '!' negate). It would be show this or this.
 */
@Directive({
  selector: '[eswFeatureToggle]',
})
export class FeatureToggleDirective implements OnInit, OnDestroy {
  @Input()
  eswFeatureToggle: FeatureName | string;

  private tearDown$ = new Subject();

  get featureName(): FeatureName | null {
    if (!this.eswFeatureToggle || this.eswFeatureToggle.length === 0) {
      return null;
    }
    const featureName: FeatureName = this.isNegative ? <FeatureName>this.eswFeatureToggle.slice(1) : <FeatureName>this.eswFeatureToggle;
    return featureName;
  }

  get isNegative(): boolean | null {
    if (!this.eswFeatureToggle || this.eswFeatureToggle.length === 0) {
      return null;
    }
    const isNegative: boolean = this.eswFeatureToggle[0] === '!';
    return isNegative;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<IAppState>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.store
      .pipe(select(getFeatureIsEnabled(this.featureName)), takeUntil(this.tearDown$))
      .subscribe((isEnabled: boolean | undefined) => {
        this.updateEmbeddedView(!!isEnabled);
      });
  }

  ngOnDestroy() {
    this.tearDown$.next();
    this.tearDown$.complete();
  }

  private updateEmbeddedView(isEnabled: boolean): void {
    this.viewContainer.clear();

    if (this.isToggleEnabled(isEnabled)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.changeDetectorRef.markForCheck();
    }
  }

  private isToggleEnabled(isEnabled: boolean): boolean {
    // This is a XOR operation.
    // If the isEnabled is true OR isNegative flag is true, you want to show the element.
    // If the isEnabled is true AND isNegative flag is true, you do not want to show the element
    return (isEnabled || this.isNegative) === true && (isEnabled && this.isNegative) === false;
  }
}

import { InjectionToken } from '@angular/core';

import { CountryIso, FieldsCharactersValidation } from '@esw/checkout-common-ui';
import { ICountrySpecificConfig } from '@core/ICountrySpecificConfig';

export interface Configuration {
  brandCode: string;
  brandName: string;
  tenantCode: string;
  metadataItemsMaxNumberToDisplay: number;
  metadataItemNamesNotVisible: string[];
  metadataItemToDataLayer: MetadataItemToDataLayer[];
  contactDetailsMetadataToDataLayer?: MetadataItemToDataLayer[];
  layout?: LayoutConfiguration;
  poBoxValidationAllowedCountries?: CountryIso[];
  addressValidation?: Array<AddressValidationConfiguration>;
}

export interface LayoutConfiguration {
  confirmationPage: ConfirmationPageLayoutVariant;
  cartItem: CartItemLayoutVariant;
  cartTitleOnConfirmationPage: string;
}

export interface AddressValidationConfiguration extends ICountrySpecificConfig {
  namesMaxInputLength: number;
  defaultMaxInputLength: number;
  fieldsCharactersValidation: FieldsCharactersValidation[];
}

export enum ConfirmationPageLayoutVariant {
  Default = 'default',
  Variant1 = 'variant-1',
}

export enum CartItemLayoutVariant {
  Default = 'default',
  Reverse = 'reverse',
  Inline = 'inline',
}

export class MetadataItemToDataLayer {
  orderName: string;
  dataLayerName: string;
}

export const CONFIGURATION = new InjectionToken<Configuration>('configuration');

import { CartItem, CartItems } from '@shared/model';

export class CartGroupingAdapter {
  public static groupItems(cartItems: CartItems): CartItems {
    const groupedItems: CartItems = [];

    for (let i = 0; i < cartItems.length; i++) {
      const cartElement = cartItems[i];
      const idx = groupedItems.findIndex(item => CartGroupingAdapter.isCartItemsCanBeGrouped(cartElement, item));
      if (idx >= 0) {
        const item = groupedItems[idx];
        groupedItems[idx] = {
          ...item,
          quantity: item.quantity + cartElement.quantity,
          isGrouped: true,
        };
      } else {
        groupedItems.push(cartElement);
      }
    }

    return groupedItems;
  }

  public static isCartItemsCanBeGrouped(cartItem: CartItem, cartItemToCompare: CartItem): boolean {
    if (
      !cartItem.cartGrouping ||
      cartItem.cartGrouping !== cartItemToCompare.cartGrouping ||
      cartItem.id !== cartItemToCompare.id ||
      cartItem.title !== cartItemToCompare.title ||
      cartItem.size !== cartItemToCompare.size ||
      cartItem.color !== cartItemToCompare.color ||
      cartItem.price.value !== cartItemToCompare.price.value
    ) {
      return false;
    }

    if (!cartItem.discounts || !cartItemToCompare.discounts) {
      return cartItem.discounts === cartItemToCompare.discounts;
    }

    if (cartItem.discounts.length !== cartItemToCompare.discounts.length) {
      return false;
    }

    for (let i = 0; i < cartItem.discounts.length; i++) {
      const discount = cartItem.discounts[i];
      const discountToCompare = cartItemToCompare.discounts[i];

      if (
        discount.title !== discountToCompare.title ||
        discount.description !== discountToCompare.description ||
        discount.amount.value !== discountToCompare.amount.value
      ) {
        return false;
      }
    }

    return true;
  }
}

import { PhoneNumber } from 'libphonenumber-js/min';

export class PhoneNumberUtils {
  /**
   * Format phone nummber as international but without country code
   */
  static format(phoneNumber: PhoneNumber | undefined): string | undefined {
    return phoneNumber ? phoneNumber.formatInternational().replace(`+${phoneNumber.countryCallingCode} `, '') : undefined;
  }
}

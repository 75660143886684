import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsExclusionService {
  private appInsightsContentExclusions = [
    'window.google_trackConversion',
    'Script error',
    `Cannot read property 'startTime' of undefined`,
    `$`,
    `jQuery`,
    `Can't find variable: twttr`,
    `twttr is not defined`,
    `null is not an object (evaluating 'document.querySelector('link[rel`,
    `Unable to set property 'href' of undefined or null reference`,
    `Impossible de définir la propriété « href » d’une référence null ou non définie`,
    `Cannot set property 'href' of null`,
    `UET is not defined`,
    `_etmc is not defined`,
    `document.querySelector(...) is null`,
    `Can't find variable: repfn`,
    `navigator.sendBeacon is not a function`,
    `missing ) after argument list`,
    `undefined is not an object (evaluating 'this.vfSocket.close')`,
    `Cannot read property 'close' of undefined`,
    `Cannot read property 'disconnect' of null`,
    `undefined is not an object (evaluating 'i.indexOf')`,
    `Object doesn't support property or method 'sendBeacon'`,
    `Unable to get property 'replace' of undefined or null reference'`,
    `Can't find variable: google_tag_manager`,
    `Cannot read property 'toFixed' of undefined`,
    `Unable to get property 'toFixed' of undefined or null reference`,
    `d[c].unitDiscountedPrice`,
    `ga.getAll is not a function`,
    `Cannot read property 'click' of undefined`,
    `webkitExitFullScreen`,
    `Unexpected end of input`,
    `Cannot read property '0' of undefined`,
    `Unexpected token else`,
    `Unexpected token 'else'`,
    `zoid destroyed all components`,
  ];

  get exclusions(): string[] {
    return this.appInsightsContentExclusions;
  }

  constructor() {}

  shouldBeExcluded(event: string): boolean {
    return event && this.appInsightsContentExclusions.some((excludedEvent: string) => event.includes(excludedEvent));
  }
}

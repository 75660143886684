import { Pipe, PipeTransform } from '@angular/core';

import { CustomCurrencyConfiguration } from './custom-currency-configuration';

const DOT_SIGN = '.';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: string | number, configuration: CustomCurrencyConfiguration): string | null {
    if (this.isEmpty(value) || !configuration) {
      return null;
    }

    const { configurationString, currencyCodeIso, currencySymbol, thousandSeparator, decimalSeparator, currencyExponent } = configuration;
    const roundedValue: string = this.toNumber(value).toFixed(currencyExponent);
    const number = this.getNumber(roundedValue, thousandSeparator);
    const exponent = this.getDecimals(roundedValue, currencyExponent);

    return configurationString
      .replace('[CurrencyISO]', currencyCodeIso)
      .replace('[CurrencySymbol]', currencySymbol)
      .replace('[Number]', number)
      .replace('[DecimalSeparator]', currencyExponent > 0 ? decimalSeparator : '')
      .replace('[Exponent]', exponent);
  }

  private isEmpty(value: any): boolean {
    return value == null || value === '' || value !== value;
  }

  private toNumber(value: number | string): number {
    if (typeof value === 'string' && !isNaN(Number(value))) {
      return Number(value);
    }
    if (typeof value !== 'number') {
      throw new Error(`${value} is not a number`);
    }
    return value;
  }

  private getNumber(value: string, thousandSeparator: string): string {
    const parts: string[] = value.split(DOT_SIGN);
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  }

  private getDecimals(value: string, fractionDigits: number): string {
    if (fractionDigits > 0 && value.lastIndexOf(DOT_SIGN) !== -1) {
      return value.slice(value.lastIndexOf(DOT_SIGN) + 1);
    }
    return '';
  }
}

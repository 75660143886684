import { MoneyAdapter } from '@shared/adapters/money.adapter';
import { DeliveryOption, DeliveryOption1, DeliveryOption2, OrderDeliveryOptionResponseDto } from '@shared/api';
import { OrderDeliveryOptionModel } from '@shared/model/order-delivery-options.model';

export class DeliveryOptionAdapter {
  public static createModel(
    { deliveryOption, estimatedDeliveryDate, charge }: OrderDeliveryOptionResponseDto,
    selectedDeliveryOption: DeliveryOption | DeliveryOption1 | DeliveryOption2,
  ): OrderDeliveryOptionModel {
    const model = new OrderDeliveryOptionModel();

    // TODO: 27721
    model.deliveryOption = <DeliveryOption>(<unknown>deliveryOption);
    model.estimatedDeliveryDate = estimatedDeliveryDate;
    model.price = MoneyAdapter.createModel(charge);

    return model;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';

import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { IGlobalAppSettings } from '@app/core';
import { getOrderState } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';
import { CONFIGURATION, Configuration } from '@configurations/configuration.token';
import { GUID_EMPTY, OrderUtils } from '@shared/utils';
import { EnvironmentWrapper } from '@shared/utils/EnvironmentWrapper';

import { AppInsightsEventName } from '../model/app-insights-event-name.enum';
import { HttpStatusCode } from '../model/http-status-code.enum';
import { AppInsightsExclusionService } from './app-insights-exclusion.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsWrapperService {
  constructor(
    private appInsights: AppInsightsService,
    private ngZone: NgZone,
    private store: Store<IAppState>,
    private appInsightsExclusionService: AppInsightsExclusionService,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) {}

  public init(settings: IGlobalAppSettings): void {
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      if (!!settings.appInsightsInstrumentalKey) {
        this.appInsights.config = {
          instrumentationKey: settings.appInsightsInstrumentalKey,
          disableTelemetry: !!navigator.webdriver,
          disableAjaxTracking: true,
        };
        this.ngZone.runOutsideAngular(() => {
          this.appInsights.init();
          this.addWindowOnErrorHandler();
        });
      }
    }
  }

  public trackEventByHttpErrorStatus(
    error: HttpErrorResponse,
    eventNames: { default: AppInsightsEventName; gone: AppInsightsEventName; orderCompleted: AppInsightsEventName },
  ) {
    if (error.status === HttpStatusCode.Gone) {
      this.trackHttpErrorEvent(error, eventNames.gone);
    } else if (OrderUtils.isOrderCompletedError(error)) {
      this.trackHttpErrorEvent(error, eventNames.orderCompleted);
    } else {
      this.trackHttpErrorEvent(error, eventNames.default);
    }
  }

  public trackHttpErrorEvent(
    error: HttpErrorResponse,
    eventName: AppInsightsEventName,
    eventProperties?: {
      OrderCode?: string;
      [name: string]: string;
    },
    metricProperty?: {
      [name: string]: number;
    },
  ): void {
    this.trackEvent(
      eventName,
      {
        StatusCode: (error.status || '').toString(),
        StatusText: error.statusText,
        Message: error.message,
        ...eventProperties,
      },
      metricProperty,
    );
  }

  public trackEvent(
    eventName: AppInsightsEventName,
    eventProperties?: {
      OrderCode?: string;
      [name: string]: string;
    },
    metricProperty?: {
      [name: string]: number;
    },
  ): void {
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      this.store.pipe(select(getOrderState), take(1)).subscribe((order) =>
        this.ngZone.runOutsideAngular(() =>
          this.appInsights.trackEvent(
            eventName,
            {
              OrderCode: order.id || GUID_EMPTY,
              BrandCode: this.configuration.brandCode,
              TenantCode: this.configuration.tenantCode,
              RetailerCartId: order.retailerCartId || GUID_EMPTY,
              ...eventProperties,
            },
            metricProperty,
          ),
        ),
      );
    }
  }

  private addWindowOnErrorHandler() {
    const oldErrorHandler = window.onerror;

    window.onerror = (event: any, source?: string, fileno?: number, columnNumber?: number, error?: Error) => {
      if (this.appInsightsExclusionService.shouldBeExcluded(event)) {
        this.trackUnhandledErrorEvent(AppInsightsEventName.UIEventExcludedWarningEvent, event, source, fileno, columnNumber, error);
        return true;
      }

      this.trackUnhandledErrorEvent(AppInsightsEventName.UIWindowOnErrorEvent, event, source, fileno, columnNumber, error);

      return oldErrorHandler && oldErrorHandler(event, source, fileno, columnNumber, error);
    };
  }

  private trackUnhandledErrorEvent(
    eventName: AppInsightsEventName,
    errorEvent: any,
    source?: string,
    fileno?: number,
    columnNumber?: number,
    error?: Error,
  ) {
    let eventSerrialized: string;
    if (errorEvent instanceof ErrorEvent) {
      eventSerrialized = `
        Type: '${errorEvent.type}',
        Message: '${errorEvent.message}',
        Filename: '${errorEvent.filename}',
        Lineno: ${errorEvent.lineno},
        Colno: ${errorEvent.colno},
        Error: '${JSON.stringify(errorEvent.error, Object.getOwnPropertyNames(errorEvent.error))}',
        Target: '${errorEvent.target.toString()}'
      `;
    } else {
      eventSerrialized = `Type: ${typeof errorEvent}, Data: '${errorEvent.toString()}'`;
    }

    this.trackEvent(eventName, {
      Event: eventSerrialized,
      Source: source,
      LineNumber: fileno ? fileno.toString() : undefined,
      ColumnNumber: columnNumber ? columnNumber.toString() : undefined,
      Error: error ? `Name: '${error.name}'; Message: '${error.message}'; Stack: '${error.stack}'` : undefined,
    });
  }
}

import { IState } from '@app/store';
import { expressPaymentsInitialState, ExpressPaymentsState } from '@app/store/express-payments/express-payments.reducer';
import { layoutConfigInitialState, LayoutConfigState } from '@app/store/layout-config/layout-config.reducer';
import { OrderModel } from '@shared/model';
import { AnalyticsModel } from '@shared/services/analytics/analytics.model';

import { FeatureFlagState } from '../feature-flag/feature-flag.reducer';

export enum LayoutStateEnum {
  ShippingEdit = 'ShippingEdit',
  PaymentEdit = 'PaymentEdit',
  PaymentReview = 'PaymentReview',
}

export class LayoutState {
  current: LayoutStateEnum;
  locale: string;
  isRtlMode: boolean;
}

export class ShippingState {
  isOrderChargesUpdating: boolean;
  isTransliterationFailure: boolean;
  isTransliterationLowAddressScore: boolean;
  transliterationAttempt: number;
}

export class BillingState {
  isInitialized: boolean;
  isPaymentErrorVisible: boolean;
  isTransliterationFailure: boolean;
  isTransliterationLowAddressScore: boolean;
  transliterationAttempt: number;
}

export interface ICheckoutState {
  order: OrderModel;
  paymentsUrl: string;
}

export interface CheckoutThemeState {
  cssVariables?: { [key: string]: string };
  favicon?: string;
  logoUrl?: string;
  fontUrls?: string[];
}

export interface PaymentThemeState {
  cssVariables?: { [key: string]: string };
  paymentPanelThemeUrl?: string;
  cardPanelThemeUrl?: string;
  paymentPanelFontUrls?: string[];
  cardPanelFontUrls?: string[];
}

export interface ThemeState {
  name: string;
  checkout?: CheckoutThemeState;
  payment?: PaymentThemeState;
}

export interface IAppState extends IState {
  appInitialized: boolean;
  isThemeInitialized: boolean;
  analytics: AnalyticsModel;
  checkout: ICheckoutState;
  layoutState: LayoutState;
  featureState: FeatureFlagState;
  shippingState: ShippingState;
  billingState: BillingState;
  expressPaymentsState: ExpressPaymentsState;
  layoutConfigState: LayoutConfigState;
  themeState: ThemeState;
  isNativeApp: boolean;
}

export const initialState = <IAppState>{
  appInitialized: false,
  isThemeInitialized: false,
  checkout: {
    order: {},
    paymentsUrl: null,
  },
  analytics: {},
  layoutState: {
    current: LayoutStateEnum.ShippingEdit,
    locale: '',
    isRtlMode: false,
  },
  shippingState: {
    isOrderChargesUpdating: false,
    isTransliterationFailure: false,
    isTransliterationLowAddressScore: false,
    transliterationAttempt: 0,
  },
  billingState: {
    isInitialized: false,
    isPaymentErrorVisible: false,
    isTransliterationFailure: false,
    isTransliterationLowAddressScore: false,
    transliterationAttempt: 0,
  },
  expressPaymentsState: expressPaymentsInitialState,
  layoutConfigState: layoutConfigInitialState,
  featureState: {
    base: require('src/generated/feature-set.generated.json'),
  },
  themeState: {},
  isNativeApp: false,
};

import { IAnalyticConfig } from '@core/IAnalyticConfig';
import { IAnalyticInitializer } from '@shared/services/analytics/ianalytic-initializer';

/* tslint:disable */
// I've disabled it to keep the 'original' google script
export class GoogleTagManagerInitializerService implements IAnalyticInitializer {
  configuration: IAnalyticConfig;

  init() {
    this.loadScript(this.configuration.googleTagManagerContainerId);
  }

  initForCountry(countryIso: string) {}

  private loadScript(containerId: string) {
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j: any = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', containerId);
  }
}

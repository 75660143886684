import { Action } from '@ngrx/store';

import { ShippingInfoUpdateModel } from '@shared/model/shipping-info-update.model';
import { AnalyticsModel } from '@shared/services/analytics/analytics.model';

export enum AnalyticsActionType {
  Initialize = '[Analytics] init',
  ChangeLanguage = '[Analytics] checkout language change',
  UpdateDeliveryOption = '[Analytics] update delivery option',
  TrackDeliveryAddress = '[Analytics] track delivery address event',
  TrackPaymentAttempt = '[Analytics] track payment attempt event',
  TrackPaymentSuccess = '[Analytics] track payment success event',
  TrackOrderConfirmation = '[Analytics] track order confirmation event',
}

export class AnalyticsInitializeAction implements Action {
  readonly type = AnalyticsActionType.Initialize;
  constructor(public payload: AnalyticsModel) {}
}

export class AnalyticsChangeLanguageAction implements Action {
  readonly type = AnalyticsActionType.ChangeLanguage;
  constructor(public payload: string) {}
}

export class AnalyticsUpdateDeliveryOptionAction implements Action {
  readonly type = AnalyticsActionType.UpdateDeliveryOption;
  constructor(public payload: string) {}
}

export class TrackDeliveryAddressEvent implements Action {
  readonly type = AnalyticsActionType.TrackDeliveryAddress;
  constructor(public newShippingInfo: ShippingInfoUpdateModel) {}
}

export class TrackPaymentAttemptEvent implements Action {
  readonly type = AnalyticsActionType.TrackPaymentAttempt;
  constructor(public paymentMethod: string) {}
}

export class TrackPaymentSuccessEvent implements Action {
  readonly type = AnalyticsActionType.TrackPaymentSuccess;
  constructor(public paymentMethod: string) {}
}

export class TrackOrderConfirmationEvent implements Action {
  readonly type = AnalyticsActionType.TrackOrderConfirmation;
}

export type AnalyticsActions =
  | AnalyticsInitializeAction
  | AnalyticsChangeLanguageAction
  | AnalyticsUpdateDeliveryOptionAction
  | TrackDeliveryAddressEvent
  | TrackPaymentAttemptEvent
  | TrackPaymentSuccessEvent
  | TrackOrderConfirmationEvent;

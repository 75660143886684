<div class="item-details">
  <div class="item-details__image-container">
    <img [eswImgPreload]="item.imageUrl" [alt]="item.title" />
  </div>
  <div class="item-details__specs-container">
    <h2 class="item-name">{{ item.description }}</h2>

    <div class="item-description">
      <div class="item-specs">
        <div class="item-spec">
          <div class="spec-label">
            <label class="spec-label__price">{{ 'Price:' | translate }}</label>
          </div>
          <div class="spec-value">
            <esw-currency [price]="item.price" [freeTextKey]="'Free cart item'"></esw-currency>
          </div>
        </div>
        <div class="item-spec">
          <div class="spec-label">
            <label class="spec-label__quantity">{{ 'Quantity:' | translate }}</label>
          </div>
          <div class="spec-value">
            <span>{{ item.quantity }}</span>
          </div>
        </div>
        <div *ngIf="customDeliveryDate$ | async as customDeliveryDate" class="item-spec">
          <div class="spec-label">
            <label class="spec-label__delivery">{{ 'Delivery' | translate }}:</label>
          </div>
          <div class="spec-value">
            <span>{{ customDeliveryDate | eswDate: 'dd MMMM' }}</span>
          </div>
        </div>
        <div class="item-spec" *ngIf="item.color">
          <div class="spec-label">
            <label class="spec-label__color">{{ 'Colour:' | translate }}</label>
          </div>
          <div class="spec-value">
            <span>{{ item.color }}</span>
          </div>
        </div>
        <div class="item-spec" *ngIf="item.size">
          <div class="spec-label">
            <label class="spec-label__size">{{ 'Size:' | translate }}</label>
          </div>
          <div class="spec-value">
            <span>{{ item.size }}</span>
          </div>
        </div>
        <div class="item-spec" *ngFor="let detail of topMetadataItems">
          <div class="spec-label">
            <label class="spec-label__{{ detail.name | lowercase }}">
              <ng-container *eswFeatureToggle="FeatureName.MetadataItemsNoTranslation">{{ detail.name + ':' }}</ng-container>
              <ng-container *eswFeatureToggle="'!' + FeatureName.MetadataItemsNoTranslation">
                {{ (detail.name | translate) + ':' }}
              </ng-container>
            </label>
          </div>
          <div class="spec-value">
            <span>{{ detail.value }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="item-description">
      <div class="item-other-specs">
        <div class="item-spec" *ngFor="let detail of bottomMetadataItems">
          <div class="spec-label">
            <label class="spec-label__{{ detail.name | lowercase }}">
              <ng-container *eswFeatureToggle="FeatureName.MetadataItemsNoTranslation">{{ detail.name + ':' }}</ng-container>
              <ng-container *eswFeatureToggle="'!' + FeatureName.MetadataItemsNoTranslation">
                {{ (detail.name | translate) + ':' }}
              </ng-container>
            </label>
          </div>
          <div class="spec-value">
            <span>{{ detail.value }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="item-details__button-container">
      <esw-button class="back-to-checkout-btn" (click)="onBackToCheckoutClick()">{{ 'Back to checkout' | translate }}</esw-button>
    </div>
  </div>
</div>

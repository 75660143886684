import { Inject, Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IAppState } from '@app/store/app-init/app.state';
import { getFeatureFlagState } from '@app/store/feature-flag/feature-flag.reducer';
import { CONFIGURATION, Configuration, MetadataItemToDataLayer } from '@configurations/configuration.token';
import { FeatureName } from '@configurations/feature-name.enum';
import { AnalyticsAdapter } from '@shared/adapters/analytics.adapter';
import { IMetadataItem, OrderModel } from '@shared/model';
import { ShippingInfoUpdateModel } from '@shared/model/shipping-info-update.model';
import { AnalyticsModel, EventTrack, EventTrackProperties, IEcommerceProductModel } from '@shared/services/analytics/analytics.model';
import { CountryIso } from '@esw/checkout-common-ui';

declare const correlation: correlation;

@Injectable()
export class AnalyticsService {
  private _deviceInfo: any = {};
  private featureFlagState$ = this.store.pipe(select(getFeatureFlagState));

  constructor(
    private angulartics: Angulartics2,
    private deviceService: DeviceDetectorService,
    private store: Store<IAppState>,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) {
    this.deviceInit();
  }

  getAnalytics(order: OrderModel): Observable<AnalyticsModel> {
    return from(correlation.getCorrelationId()).pipe(
      map((correlationId: string) => {
        const analytics: AnalyticsModel = AnalyticsAdapter.createAnalytics(order);
        analytics.browser = this.getBrowser();
        analytics.deviceInfo = this.getDeviceInfo();
        analytics.deviceCategory = this.getDeviceCategory();
        analytics.fingerprintId = correlationId;
        analytics.brandName = this.configuration.brandName;
        return analytics;
      }),
    );
  }

  trackCheckoutLoadEvent(event: AnalyticsModel, order: OrderModel): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        const eventTrack: EventTrack = {
          action: 'Checkout_Load',
          properties: {
            ...event,
            event: 'checkout_load',
            action: 'Checkout_Load',
            pageStep: 'Checkout_Load',
            ecommerce: AnalyticsAdapter.createEcommercePageLoad(
              event,
              order,
              this.configuration.metadataItemToDataLayer,
              flags[FeatureName.AnalyticsEcommerceStepsAlternative],
            ),
            ...this.getCommonProperties(order, flags),
          },
        };

        if (order.shopperCheckoutExperience.isUserRegistered) {
          eventTrack.properties.customerEmail = order.delivery.email;
        }

        if (flags[FeatureName.AdditionalAnalyticsData]) {
          eventTrack.properties = {
            ...eventTrack.properties,
            domestic: 'N',
            categoryId: 'GLOBAL',
            pageCategory: 'Checkout checkout',
            pageCategoryId: 'GLOBAL|CHECKOUT',
            pageTitle: 'CHECKOUT: eSW Delivery details',
            pageType: 'CHECKOUT',
          };
        }

        this.angulartics.eventTrack.next(eventTrack);
      }),
    );
  }

  trackDeliveryAddressEvent(event: AnalyticsModel, order: OrderModel, newShippingInfo: ShippingInfoUpdateModel): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        const eventTrack: EventTrack = {
          action: 'Delivery_Det_Complete',
          properties: {
            ...event,
            deliveryOption: newShippingInfo.selectedDeliveryMethod,
            action: 'Delivery_Det_Complete',
            event: 'Delivery_Det_Complete',
            pageStep: 'Delivery_Det_Complete',
            customerEmail: newShippingInfo.contactInfo.email,
            ecommerce: AnalyticsAdapter.createEcommerceDeliveryAddress(
              event,
              order,
              this.configuration.metadataItemToDataLayer,
              flags[FeatureName.AnalyticsEcommerceStepsAlternative],
            ),
            ...this.getCommonProperties(order, flags),
          },
        };

        if (flags[FeatureName.AdditionalAnalyticsData]) {
          eventTrack.properties = {
            ...eventTrack.properties,
            pageCategoryId: 'GLOBAL|CHECKOUT',
            pageTitle: 'CHECKOUT: eSW PAYMENT',
            pageType: 'CHECKOUT',
          };
        }

        this.angulartics.eventTrack.next(eventTrack);
      }),
    );
  }

  trackBillingAddressEvent(event: AnalyticsModel, order: OrderModel): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        if (flags[FeatureName.AdditionalAnalyticsData]) {
          const eventTrack: EventTrack = {
            action: 'Billing_Det_Complete',
            properties: {
              ...event,
              action: 'Billing_Det_Complete',
              event: 'Billing_Det_Complete',
              pageStep: 'Billing_Det_Complete',
              eventCategory: 'checkout_form_interaction',
              eventId: 'save_billing_address',
              eventMethod: 'link',
              ...this.getCommonProperties(order, flags),
            },
          };

          this.angulartics.eventTrack.next(eventTrack);
        }
      }),
    );
  }

  trackPaymentPanelLoadFailureEvent(event: AnalyticsModel, errorCode: number): void {
    const eventTrack: EventTrack = {
      action: 'Payment_Failure',
      properties: {
        ...event,
        action: 'Payment_Failure',
        pageStep: 'Payment_Failure',
        paymentStatus: 'Panel Load Failure',
        failureCode: errorCode,
      },
    };

    this.angulartics.eventTrack.next(eventTrack);
  }

  trackPaymentAttemptEvent(event: AnalyticsModel, order: OrderModel, paymentMethod: string): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        const eventTrack: EventTrack = {
          action: 'Payment_Attempt',
          properties: {
            ...event,
            action: 'Payment_Attempt',
            event: 'Payment_Attempt',
            pageStep: 'Payment_Attempt',
            paymentStatus: 'Payment_Attempt',
            cardOptions: paymentMethod,
            customerEmail: order.delivery.email,
            ecommerce: AnalyticsAdapter.createEcommercePaymentAttempt(
              event,
              order,
              paymentMethod,
              this.configuration.metadataItemToDataLayer,
              flags[FeatureName.AnalyticsEcommerceStepsAlternative],
            ),
            ...this.getCommonProperties(order, flags),
          },
        };

        if (flags[FeatureName.AdditionalAnalyticsData]) {
          eventTrack.properties = {
            ...eventTrack.properties,
            eventCategory: 'checkout_form_interaction',
            eventId: 'pay_now',
            eventMethod: 'link',
          };
        }

        this.angulartics.eventTrack.next(eventTrack);
      }),
    );
  }

  trackPaymentSuccessEvent(event: AnalyticsModel, order: OrderModel, paymentMethod: string): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        const eventTrack: EventTrack = {
          action: 'Payment_Success',
          properties: {
            ...event,
            action: 'Payment_Success',
            event: 'Payment_Success',
            pageStep: 'Payment_Success',
            paymentStatus: 'Payment_Success',
            cardOptions: paymentMethod,
            ecommerce: AnalyticsAdapter.createEcommercePaymentSuccess(
              event,
              order,
              paymentMethod,
              this.configuration.metadataItemToDataLayer,
              flags[FeatureName.AnalyticsEcommerceStepsAlternative],
            ),
            ...this.getCommonProperties(order, flags),
          },
        };

        this.angulartics.eventTrack.next(eventTrack);
      }),
    );
  }

  trackOrderConfirmationEvent(event: AnalyticsModel, order: OrderModel): Observable<void> {
    return this.featureFlagState$.pipe(
      take(1),
      map((flags: { [key: string]: boolean }) => {
        const eventTrack: EventTrack = {
          action: 'Order_Confirmation',
          properties: {
            ...event,
            event: 'purchase',
            action: 'Order_Confirmation',
            pageStep: 'Order_Confirmation',
            customerEmail: order.delivery.email,
            retailerOrderNumber: order.retailerOrderNumber,
            ecommerce: AnalyticsAdapter.createEcommerceOrderConfirmation(
              event,
              order,
              this.configuration,
              flags[FeatureName.AnalyticsRevenueAsItemsAfterDiscount],
            ),
            ...this.getCommonProperties(order, flags),
          },
        };

        const clientProfileId = order.shopperCheckoutExperience?.registeredProfileId;
        if (clientProfileId) {
          eventTrack.properties.clientProfileId = clientProfileId;
        }

        if (flags[FeatureName.AdditionalAnalyticsData]) {
          const separator = ',';
          const productCategories = [];
          const productNames = [];
          const productQuantities = [];
          const productPrices = [];
          const productIds = [];
          const productProductIds = [];

          eventTrack.properties.ecommerce.purchase.products.forEach((product: IEcommerceProductModel) => {
            productCategories.push(product.category);
            productNames.push(product.name);
            productQuantities.push(product.quantity);
            productPrices.push(product.price);
            productIds.push(product.id);
            productProductIds.push(product['product_id']);
          });

          eventTrack.properties = {
            ...eventTrack.properties,
            shipMethod: order.deliveryOption,
            customerCity: order.delivery.address.city,
            categoryId: 'GLOBAL',
            pageCategory: 'Checkout checkout',
            pageCategoryId: 'GLOBAL|THANK YOU',
            pageTitle: 'eSW ORDER COMPLETE',
            pageType: 'thankyou',
            productsCategory: productCategories.join(separator),
            productsName: productNames.join(separator),
            productsQuantity: productQuantities.join(separator),
            productsPrice: productPrices.join(separator),
            productsId: productIds.join(separator),
            productsProductId: productProductIds.join(separator),
          };

          if (order.charges?.tax?.value) {
            eventTrack.properties.tax = order.charges.tax.value.toString();
          }

          const revenueBeforeDiscounts = order.cart
            .map((item) => {
              let beforeDiscounts = item.discounts?.map((discount) => (discount?.beforeDiscount ? discount.beforeDiscount.value : 0));
              beforeDiscounts = beforeDiscounts?.length ? beforeDiscounts : [0];
              const highestBeforeDiscount = Math.max(...beforeDiscounts) || item.price.value;
              return highestBeforeDiscount * item.quantity;
            })
            .reduce((total: number, value: number) => total + value, 0)
            .toFixed(2);
          eventTrack.properties.ecommerce.purchase.actionField.revenueBeforeDiscounts = revenueBeforeDiscounts;
        }

        this.angulartics.eventTrack.next(eventTrack);
      }),
    );
  }

  private getCommonProperties(order: OrderModel, flags: { [key: string]: boolean }): Partial<EventTrackProperties> {
    const commonProperties: Partial<EventTrackProperties> = {};
    const billingCountryIso: CountryIso | string | undefined = order?.payment?.address?.country?.iso;
    if (billingCountryIso) {
      commonProperties.billingCountryIso = billingCountryIso;
    }
    const registeredProfileId: string | undefined = order?.shopperCheckoutExperience?.registeredProfileId;
    if (registeredProfileId && flags[FeatureName.AddRegisteredProfileIdToAnalyticsEvents]) {
      commonProperties.registeredProfileId = registeredProfileId;
    }
    if (this.configuration.contactDetailsMetadataToDataLayer?.length > 0 && order.contactDetailsMetadataItems?.length > 0) {
      this.configuration.contactDetailsMetadataToDataLayer.forEach((metadataItem: MetadataItemToDataLayer) => {
        const item: IMetadataItem | undefined = order.contactDetailsMetadataItems.find((i) => i.name === metadataItem.orderName);
        if (item) {
          commonProperties[metadataItem.dataLayerName] = item.value;
        }
      });
    }

    return commonProperties;
  }

  private deviceInit(): void {
    this._deviceInfo.desktop = this.deviceService.isDesktop();
    this._deviceInfo.mobile = this.deviceService.isMobile();
    this._deviceInfo.tablet = this.deviceService.isTablet();
    this._deviceInfo.device = this.deviceService.getDeviceInfo().device;
  }

  private getDeviceCategory(): string {
    return (this._deviceInfo.desktop && 'Desktop') || (this._deviceInfo.mobile && 'Mobile') || (this._deviceInfo.tablet && 'Tablet');
  }

  private getDeviceInfo(): string {
    return this._deviceInfo.desktop ? 'desktop' : this._deviceInfo.device;
  }

  private getBrowser(): string {
    return this.deviceService.browser + ' ' + this.deviceService.browser_version;
  }
}

import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { AddressAdapter } from '@shared/adapters/address.adapter';
import { ContactDetailsResponseDto } from '@shared/api';
import { AddressModel } from '@shared/model/address.model';
import { ContactInformationModel, ContactInformationType } from '@shared/model/contact-information.model';
import { PhoneNumber } from '@shared/model/phone-number.model';

import { PhoneNumberUtils } from '../utils/phone-number-utils';

export class ContactInformationAdapter {
  static createModel(contactDetailsResponse: ContactDetailsResponseDto, type?: ContactInformationType): ContactInformationModel {
    const model = new ContactInformationModel();
    model.address = new AddressModel();

    if (contactDetailsResponse) {
      model.idCollection = contactDetailsResponse.additionalPersonalDetails || {};
      model.email = contactDetailsResponse.email;
      model.address = AddressAdapter.createModel(contactDetailsResponse);
      const phoneNumber = contactDetailsResponse.telephone || '';
      model.telephone = <PhoneNumber>{
        iso: null,
        intPhCode: null,
        number: phoneNumber,
      };

      if (phoneNumber.length > 1) {
        try {
          // Try match a country from the number if it's internationally formatted otherwise we'll fall back to flagValue (deliveryCountry)
          if (phoneNumber.charAt(0) === '+') {
            const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
            model.telephone = <PhoneNumber>{
              iso: parsedPhoneNumber.country,
              intPhCode: parsedPhoneNumber.countryCallingCode,
              number: PhoneNumberUtils.format(parsedPhoneNumber),
            };
          }
        } catch (err) {
          // Could not parse a country from the provided value
          model.telephone = <PhoneNumber>{
            iso: null,
            intPhCode: null,
            number: null,
          };
        }
      }
    }
    model.type = type;
    return model;
  }
}

import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'img[eswImgPreload]',
})
export class ImgPreloadDirective implements OnInit {
  @Input('eswImgPreload')
  targetSource: string;
  @Input()
  defaultImage = 'assets/images/cart/ill_cart_placeholder.svg';

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLImageElement>) {}

  ngOnInit() {
    this.setImageSource(this.defaultImage);
    const downloadingImage = new Image();
    downloadingImage.onload = () => {
      this.setImageSource(this.targetSource);
    };
    downloadingImage.src = this.targetSource;
  }

  private setImageSource(imageSource: string): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'src', imageSource);
  }
}

import { IAppState } from '@app/store/app-init/app.state';

import { TransliterationActions, TransliterationActionType } from './transliteration.action';

export function transliterationReducer(state: IAppState, action: TransliterationActions): IAppState {
  switch (action.type) {
    case TransliterationActionType.ShippingTransliterationFailure:
      return {
        ...state,
        shippingState: {
          ...state.shippingState,
          isTransliterationFailure: true,
          transliterationAttempt: state.shippingState.transliterationAttempt + 1,
        },
      };

    case TransliterationActionType.ShippingTransliterationLowAddressScore:
      return {
        ...state,
        shippingState: {
          ...state.shippingState,
          isTransliterationLowAddressScore: true,
          transliterationAttempt: state.shippingState.transliterationAttempt + 1,
        },
      };
    case TransliterationActionType.BillingTransliterationFailure:
      return {
        ...state,
        billingState: {
          ...state.billingState,
          isTransliterationFailure: true,
          transliterationAttempt: state.billingState.transliterationAttempt + 1,
        },
      };
    case TransliterationActionType.BillingTransliterationLowAddressScore:
      return {
        ...state,
        billingState: {
          ...state.billingState,
          isTransliterationLowAddressScore: true,
          transliterationAttempt: state.billingState.transliterationAttempt + 1,
        },
      };

    case TransliterationActionType.TransliterationResetState:
      return {
        ...state,
        shippingState: {
          ...state.shippingState,
          isTransliterationFailure: false,
          isTransliterationLowAddressScore: false,
        },
        billingState: {
          ...state.billingState,
          isTransliterationFailure: false,
          isTransliterationLowAddressScore: false,
        },
      };

    default:
      return state;
  }
}

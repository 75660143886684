import { OrderShopperCheckoutExperienceResponseDto } from '@shared/api';
import { ShopperExperienceModel } from '@shared/model/shopper-experience.model';

export class ShopperCheckoutExperienceAdapter {
  public static createShopperCheckoutExperience(response: OrderShopperCheckoutExperienceResponseDto = {}): ShopperExperienceModel {
    const model = new ShopperExperienceModel();

    model.shopperCultureLanguageIso = response.shopperCultureLanguageIso;
    model.registeredProfileId = response.registeredProfileId;
    model.marketingEmailOptInConfirmed = response.emailMarketingOptIn;
    model.isUserRegistered = response.orderForRegisteredUser;
    model.isDisplayCustomsSection = response.isAdditionalContactDetailsToGather;

    return model;
  }
}

import { AddressValidationResult, ExpressPaymentAttempt, ExpressUiIntegrationType } from '@esw/payments-express-panel';
import { Action } from '@ngrx/store';

import { OrderModel } from '@shared/model';
import { ContactInformationModel } from '@shared/model/contact-information.model';

export enum ExpressPaymentsActionType {
  SetExpressPaymentId = '[Express Payments] set express payment id',
  SetExpressPaymentMethodLogo = '[Express Payments] set express payment method logo',
  SetExpressPaymentUiIntegrationType = '[Express Payments] set express payment ui integration type',
  SetExpressPaymentShippingContactInfo = '[Express Payments] set express payment shipping info',
  SetExpressPaymentRavelinDeviceId = '[Express Payments] set express payment raveling id',
  AuthorizeExpressPayment = '[Express Payments] authorize express payment',
  AuthorizeExpressPaymentSuccess = '[Express Payments] authorize express payment success',
  AuthorizeExpressPaymentFailure = '[Express Payments] authorize express payment failure',
  ExpressPaymentAttempt = '[Express Payments] express payment attempt',
  ExpressPaymentPanelInitialized = '[Express Payments] express payment panel is initialized',
  ExpressPaymentsNoChargesUpdate = '[Express Payments] no charges update attempt',
  ExpressPaymentsOrderChargesUpdateAttempt = '[Express Payments] update order charges',
  ExpressPaymentsOrderChargesUpdateResult = '[Express Payments] update order charges result',
  SetExpressPaymentErrorIsVisible = '[Express Payments] set express payment error is visible',
}

export class SetExpressPaymentId implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentId;
  constructor(public expressPaymentId: string) {}
}

export class SetExpressPaymentMethodLogo implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentMethodLogo;
  constructor(public expressPaymentMethodLogoUrl: string) {}
}

export class SetExpressPaymentUiIntegrationType implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentUiIntegrationType;
  constructor(public expressUiIntegrationType: ExpressUiIntegrationType) {}
}

export class SetExpressPaymentShippingContactInfo implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentShippingContactInfo;
  constructor(public shippingContactInfo: ContactInformationModel) {}
}

export class SetExpressPaymentRavelinDeviceId implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentRavelinDeviceId;
  constructor(public ravelinDeviceId: string) {}
}

export class AuthorizeExpressPayment implements Action {
  readonly type = ExpressPaymentsActionType.AuthorizeExpressPayment;
  constructor(public shippingContactInfo: ContactInformationModel) {}
}

export class AuthorizeExpressPaymentSuccess implements Action {
  readonly type = ExpressPaymentsActionType.AuthorizeExpressPaymentSuccess;
  constructor(public order: OrderModel) {}
}

export class AuthorizeExpressPaymentFailure implements Action {
  readonly type = ExpressPaymentsActionType.AuthorizeExpressPaymentFailure;
}

export class ExpressPaymentAttemptAction implements Action {
  readonly type = ExpressPaymentsActionType.ExpressPaymentAttempt;
  constructor(public paymentAttempt: ExpressPaymentAttempt) {}
}

export class ExpressPaymentPanelInitialized implements Action {
  readonly type = ExpressPaymentsActionType.ExpressPaymentPanelInitialized;
}

export class ExpressPaymentsOrderChargesUpdateAttempt implements Action {
  readonly type = ExpressPaymentsActionType.ExpressPaymentsOrderChargesUpdateAttempt;
  constructor(public region: string) {}
}

export class ExpressPaymentsOrderChargesUpdateResult implements Action {
  readonly type = ExpressPaymentsActionType.ExpressPaymentsOrderChargesUpdateResult;
}

export class ExpressPaymentsNoChargesUpdate implements Action {
  readonly type = ExpressPaymentsActionType.ExpressPaymentsNoChargesUpdate;
  constructor(public validationErrors?: AddressValidationResult) {}
}

export class SetExpressPaymentErrorIsVisible implements Action {
  readonly type = ExpressPaymentsActionType.SetExpressPaymentErrorIsVisible;
  constructor(public isVisible: boolean = true) {}
}

export type ExpressPaymentsActions =
  | SetExpressPaymentId
  | SetExpressPaymentMethodLogo
  | SetExpressPaymentUiIntegrationType
  | SetExpressPaymentShippingContactInfo
  | SetExpressPaymentRavelinDeviceId
  | AuthorizeExpressPayment
  | AuthorizeExpressPaymentSuccess
  | AuthorizeExpressPaymentFailure
  | ExpressPaymentPanelInitialized
  | ExpressPaymentAttemptAction
  | ExpressPaymentsNoChargesUpdate
  | ExpressPaymentsOrderChargesUpdateResult
  | SetExpressPaymentErrorIsVisible
  | ExpressPaymentsOrderChargesUpdateAttempt;

import { Action } from '@ngrx/store';

export enum TransliterationActionType {
  ShippingTransliterationFailure = '[Transliteration] Shipping Failure',
  ShippingTransliterationLowAddressScore = '[Transliteration] Shipping Low Address Score',
  BillingTransliterationFailure = '[Transliteration] Billing Failure',
  BillingTransliterationLowAddressScore = '[Transliteration] Billing Low Address Score',
  TransliterationResetState = '[Transliteration] Reset State',
}

export class ShippingTransliterationFailure implements Action {
  readonly type = TransliterationActionType.ShippingTransliterationFailure;
  constructor() {}
}

export class ShippingTransliterationLowAddressScore implements Action {
  readonly type = TransliterationActionType.ShippingTransliterationLowAddressScore;
  constructor() {}
}

export class BillingTransliterationFailure implements Action {
  readonly type = TransliterationActionType.BillingTransliterationFailure;
  constructor() {}
}

export class BillingTransliterationLowAddressScore implements Action {
  readonly type = TransliterationActionType.BillingTransliterationLowAddressScore;
  constructor() {}
}

export class TransliterationResetState implements Action {
  readonly type = TransliterationActionType.TransliterationResetState;
  constructor() {}
}

export type TransliterationActions = ShippingTransliterationFailure
| ShippingTransliterationLowAddressScore
| BillingTransliterationFailure
| BillingTransliterationLowAddressScore
| TransliterationResetState;

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FeatureFlagIsPipe } from '@shared/pipes/feature-flag-is/feature-flag-is.pipe';
import { SafePipe } from '@shared/pipes/safe/safe.pipe';

import { CustomCurrencyPipe } from './currency/custom-currency.pipe';
import { LanguageSpecificUrlPipe } from './language-specific-url/language-specific-url.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SafePipe, CustomCurrencyPipe, LanguageSpecificUrlPipe, FeatureFlagIsPipe, TruncatePipe],
  exports: [SafePipe, CustomCurrencyPipe, LanguageSpecificUrlPipe, FeatureFlagIsPipe, TruncatePipe],
})
export class PipesModule {}

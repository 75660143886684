import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CartItem } from '@shared/model/cart-item.model';

@Component({
  selector: 'esw-sub-items-details',
  templateUrl: './sub-items-details.component.html',
  styleUrls: ['./sub-items-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubItemsDetailsComponent {
  @Input() item: CartItem;
  @Output() backToCheckout = new EventEmitter<void>();

  constructor() {}

  onBackToCheckoutClick() {
    this.backToCheckout.emit();
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { debounceTime, startWith } from 'rxjs/operators';

import { CONFIGURATION, Configuration } from '@configurations/configuration.token';

import { BusyIndicatorService } from './busyIndicator.service';

@Component({
  selector: 'esw-busy-indicator',
  templateUrl: './busyIndicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyIndicatorComponent {
  isVisible$ = this.busyService.isBusy$.pipe(
    debounceTime(150),
    startWith(true), // show splash screen at startup to avoid blinks
  );

  constructor(private busyService: BusyIndicatorService, @Inject(CONFIGURATION) public configuration: Configuration) {}
}

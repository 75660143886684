import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GiftMessageModalComponent } from '@app/cart/cart-item/gift-message-modal/gift-message-modal.component';
import { CartItemDeliveryDateInformation } from '@app/shared/model/cart-item-delivery-date-information';
import { getSelectedDeliveryOption } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';
import { getFeatureIsEnabled } from '@app/store/feature-flag/feature-flag.reducer';
import { getCartLayoutVariant } from '@app/store/layout-config/layout-config.selectors';
import { CartItemLayoutVariant, CONFIGURATION, Configuration } from '@configurations/configuration.token';
import { FeatureName } from '@configurations/feature-name.enum';
import { CartItem, IMetadataItem, KnownDiscountTypes } from '@shared/model';
import { CartItemDiscountModel } from '@shared/model/cart-item-discount.model';
import { MetadataItemName } from '@shared/model/metadata-item-name.enum';

@Component({
  selector: 'esw-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemComponent {
  @Input() item: CartItem;
  @Input() isDetailsModalsAvailable = true;

  FeatureName = FeatureName;

  customDeliveryDate$: Observable<Date> = this.store.pipe(
    select(getSelectedDeliveryOption),
    map(
      (selectedDeliveryOption: string) =>
        this.item.estimatedDeliveryDatesForDeliveryOptions?.find(
          (information: CartItemDeliveryDateInformation) => information.deliveryOption === selectedDeliveryOption,
        )?.estimatedDeliveryDate,
    ),
  );

  isViewMoreButtonVisible$: Observable<boolean> = this.store.pipe(
    select(getFeatureIsEnabled(FeatureName.ExpandMetadataItems)),
    map(
      (isExpandMetadataItemsEnabled: boolean) =>
        isExpandMetadataItemsEnabled &&
        this.item.available &&
        this.item.metadataItems.filter((item) => !this.configuration.metadataItemNamesNotVisible.includes(item.name)).length >
          this.configuration.metadataItemsMaxNumberToDisplay,
    ),
  );

  layoutVariant$: Observable<CartItemLayoutVariant> = this.store.pipe(select(getCartLayoutVariant));
  readonly layoutVariants = CartItemLayoutVariant;

  get visibleMetadataItems(): IMetadataItem[] {
    return this.item.metadataItems
      .filter((item) => !this.configuration.metadataItemNamesNotVisible.includes(item.name))
      .slice(0, this.configuration.metadataItemsMaxNumberToDisplay);
  }

  get giftMessage(): string | undefined {
    return this.item.metadataItems.find((i) => i.name === MetadataItemName.GiftMessage)?.value;
  }

  get additionalMessages(): string[] {
    return this.item.metadataItems.filter((i) => i.name === MetadataItemName.AdditionalMessage).map((i) => i.value);
  }

  get comparablePrice(): string | undefined {
    return this.item.metadataItems.find((i) => i.name === MetadataItemName.ComparablePrice)?.value;
  }

  get hasAdditionalMessages(): boolean {
    return this.additionalMessages.length > 0;
  }

  get itemHasDiscountAmount(): boolean {
    return !!this.item.discounts?.find((discount) => discount.amount?.value > 0);
  }

  get itemDiscountTitle(): string {
    return this.item.discounts[0].title;
  }

  get itemHasDiscountTitle(): boolean {
    return !!this.item.discounts?.find((discount) => discount.title?.length > 0);
  }

  get itemDiscountDescription(): string {
    return this.item.discounts[0].description;
  }

  get itemHasDiscountDescription(): boolean {
    return !!this.item.discounts?.find((discount) => discount.description?.length > 0);
  }

  /**
   * @deprecated Currently not used by VS
   */
  get itemHasClearanceDiscount(): boolean {
    // tslint:disable-next-line: deprecation
    return !!this.item.discounts?.find((discount) => discount.title?.toUpperCase() === KnownDiscountTypes.Clearance.toUpperCase());
  }

  get nonClearanceDiscounts(): CartItemDiscountModel[] {
    // tslint:disable-next-line: deprecation
    return (this.item.discounts || []).filter((discount) => discount.title?.toUpperCase() !== KnownDiscountTypes.Clearance.toUpperCase());
  }

  @ViewChild('specDetailsModal', { static: true })
  private modalSpecDetailsElementRef: ElementRef;
  @ViewChild('subItemsDetailsModal', { static: true })
  private subItemsDetailsElementRef: ElementRef;

  constructor(
    private store: Store<IAppState>,
    private modalService: NgbModal,
    @Inject(CONFIGURATION) public configuration: Configuration,
  ) {}

  openSpecDetailsModal() {
    this.modalService.open(this.modalSpecDetailsElementRef, {
      windowClass: 'modal-responsive',
      backdropClass: 'modal-backdrop-responsive',
    });
  }

  openSubItemsDetailsModal() {
    this.modalService.open(this.subItemsDetailsElementRef, {
      keyboard: false,
    });
  }

  openGiftMessageModal() {
    this.modalService.open(GiftMessageModalComponent, {
      keyboard: false,
    });
  }
}

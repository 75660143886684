import { NgZone } from '@angular/core';

import { interval, Observable, timer } from 'rxjs';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';

import { enterZone } from './enter-zone';
import { leaveZone } from './leave-zone';

export const waitFor = (zone: NgZone, condition: () => boolean, checkIntervalMs: number = 100, timeoutMs: number = 0) => {
  const result: Observable<number> = interval(checkIntervalMs).pipe(
    startWith(0),
    filter((tick: number) => {
      return condition();
    }),
    take(1),
    leaveZone(zone),
    enterZone(zone),
  );

  return timeoutMs ? result.pipe(takeUntil(timer(timeoutMs))) : result;
};

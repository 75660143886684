import { AddressModel } from '@shared/model/address.model';
import { PhoneNumber } from '@shared/model/phone-number.model';

import { IdCollectionModel } from './id-collection.model';

export enum ContactInformationType {
  IsDelivery = 'IsDelivery',
  IsPayment = 'IsPayment',
  IsDeliveryAndPayment = 'IsDeliveryAndPayment',
}

export class ContactInformationModel {
  telephone?: PhoneNumber;
  email?: string;
  address = new AddressModel();
  idCollection?: IdCollectionModel;
  type?: ContactInformationType;
}

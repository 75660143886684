import { IAppState } from '@app/store/app-init/app.state';

import { PaymentOptionsGetActions, PaymentOptionsGetActionType } from './payment-options-get.actions';

export function paymentOptionsGetReducer(state: IAppState, action: PaymentOptionsGetActions): IAppState {
  switch (action.type) {
    case PaymentOptionsGetActionType.GetSuccess:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          paymentsUrl: action.payload.paymentUrl,
        },
      };

    default:
      return state;
  }
}

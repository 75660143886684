import { createSelector } from '@ngrx/store';

import { getMainState } from '@app/store/app-init/app-init.reducer';
import { IAppState } from '@app/store/app-init/app.state';
import { LayoutConfigState } from '@app/store/layout-config/layout-config.reducer';
import { CartItemLayoutVariant, ConfirmationPageLayoutVariant, LayoutConfiguration } from '@configurations/configuration.token';

export const getLayoutConfigState = createSelector(getMainState, (state: IAppState) => state.layoutConfigState);

export const getLayoutConfiguration = createSelector(
  getLayoutConfigState,
  (state: LayoutConfigState): LayoutConfiguration | null => state.configuration,
);

export const getConfirmationPageLayoutVariant = createSelector(
  getLayoutConfiguration,
  (state: LayoutConfiguration | null): ConfirmationPageLayoutVariant => {
    return state?.confirmationPage || ConfirmationPageLayoutVariant.Default;
  },
);

export const getCartLayoutVariant = createSelector(
  getLayoutConfiguration,
  (state: LayoutConfiguration | null): CartItemLayoutVariant => {
    return state?.cartItem || CartItemLayoutVariant.Default;
  },
);

export const getCartTitleOnConfirmationPage = createSelector(getLayoutConfiguration, (state: LayoutConfiguration | null): string => {
  return state?.cartTitleOnConfirmationPage || 'Order Summary';
});

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandConfigurationService } from '@shared/services';
import { WindowRefService } from '@app/core';

@Component({
  selector: 'esw-gift-message-modal',
  templateUrl: './gift-message-modal.component.html',
  styleUrls: ['./gift-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftMessageModalComponent {
  constructor(
    public modal: NgbActiveModal,
    private brandConfigurationService: BrandConfigurationService,
    private window: WindowRefService,
  ) {}

  redirectToCart(): void {
    const backToCartUrl = this.brandConfigurationService.getBrandConfiguration().backToCartUrl;
    this.window.nativeWindow.location.href = backToCartUrl;
  }
}

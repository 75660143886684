import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { LayoutRoutesNames } from '@app/layout/layout.routes.names';
import { UrlTools } from '@app/shared/utils';
import { OrderInitializeAction, OrderInitializeFailureAction } from '@app/store/app-init/app-init.actions';
import { getMainState } from '@app/store/app-init/app-init.reducer';
import { IAppState, ICheckoutState } from '@app/store/app-init/app.state';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { OrderStatus } from '@shared/model/order-status.enum';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

import { getRoutePath } from './navigation-helper';

@Injectable()
export class OnlyValidOrdersGuard implements CanActivate, CanActivateChild {
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const orderIdCandidate: string = next.paramMap.get('orderId');
    const countryIsoCandidate: string | undefined = next.paramMap.get('countryIso');
    const pricingSyncIdCandidate: string | undefined = UrlTools.getUrlPathFragment(state.url, countryIsoCandidate ? 2 : 1);

    if (!this.isNotFound(state.url)) {
      const shouldProcessPricingSyncId: boolean = pricingSyncIdCandidate && this.pricingSynchronizationIdIsValid(pricingSyncIdCandidate);
      if (shouldProcessPricingSyncId) {
        this.store.dispatch(new OrderInitializeAction(orderIdCandidate, pricingSyncIdCandidate));
      } else {
        this.store.dispatch(new OrderInitializeAction(orderIdCandidate));
      }

      return this.store.pipe(
        select(getMainState),
        filter((app) => !!app.appInitialized),
        map((app: IAppState) => app.checkout),
        map((checkout: ICheckoutState) => {
          if (shouldProcessPricingSyncId) {
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));
          }
          this.validateCheckoutState(checkout);
          const deliveryCountryIso = checkout.order.deliveryCountryIso;
          if (countryIsoCandidate && this.countriesFromUrlAndOrderMismatch(deliveryCountryIso, countryIsoCandidate)) {
            this.router.navigate(['/', deliveryCountryIso.toLowerCase(), orderIdCandidate]);
          }

          return true;
        }),
        catchError((a, b) => {
          if (this.isNotFound(state.url)) {
            return this.notFoundRedirect();
          }
          this.router.navigate([`not-found/${LayoutRoutesNames.Error}`]);
          return of(false);
        }),
      );
    } else {
      return this.notFoundRedirect();
    }
  }

  private countriesFromUrlAndOrderMismatch(countryIsoFromOrder: string, countryIsoFromUrl: string) {
    return countryIsoFromOrder.toUpperCase() !== countryIsoFromUrl.toUpperCase();
  }

  private notFoundRedirect(): Observable<boolean> {
    this.store.dispatch(new OrderInitializeFailureAction());
    return of(true);
  }

  private isNotFound(urlFragment: string): boolean {
    return !!(urlFragment.indexOf('not-found') + 1);
  }

  private validateCheckoutState(checkout: ICheckoutState) {
    if (this.isAnInvalidCheckout(checkout)) {
      this.appInsights.trackEvent(AppInsightsEventName.CheckoutOrderBadlyFormattedErrorEvent, {
        order: JSON.stringify(checkout?.order),
      });
      throw new Error('checkout badly formatted');
    }
    if (this.isAnInvalidOrderStatus(checkout.order.status)) {
      this.appInsights.trackEvent(AppInsightsEventName.CheckoutOrderStatusNotRecognizedErrorEvent, {
        orderStatus: `${checkout.order.status}`,
      });
      throw new Error(`order.status "${checkout.order.status}" is not recognized`);
    }
  }

  private isAnInvalidOrderStatus(orderStatus: OrderStatus): boolean {
    let validOrderStatus = false;

    switch (orderStatus) {
      case OrderStatus.OnGoing:
      case OrderStatus.PaymentInProgress:
      case OrderStatus.Completed:
      case OrderStatus.PaymentFailed:
      case OrderStatus.Failed:
      case OrderStatus.BeingProcessed:
      case OrderStatus.TimedOut:
        validOrderStatus = true;
    }

    return !validOrderStatus;
  }

  private isAnInvalidCheckout(checkout: ICheckoutState): boolean {
    return !checkout || !checkout.order;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  constructor(private store: Store<IAppState>, private router: Router, private appInsights: AppInsightsWrapperService) {}

  private pricingSynchronizationIdIsValid(pricingSyncIdCandidate: string): boolean {
    const nowAllowedCandidates: Array<string> = [
      LayoutRoutesNames.Error,
      LayoutRoutesNames.Payment,
      LayoutRoutesNames.Confirmation,
      LayoutRoutesNames.PureIntegrationExpressPayment,
    ];
    return !nowAllowedCandidates.includes(pricingSyncIdCandidate);
  }
}

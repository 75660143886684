import { IAppState } from '@app/store/app-init/app.state';

import { BillingActions, BillingActionType } from './billing.actions';

export function billingReducer(state: IAppState, action: BillingActions): IAppState {
  switch (action.type) {
    case BillingActionType.Initialize:
      return {
        ...state,
        billingState: {
          ...state.billingState,
          isInitialized: true,
        },
      };

    case BillingActionType.ShowPaymentError:
      return {
        ...state,
        billingState: {
          ...state.billingState,
          isPaymentErrorVisible: true,
        },
      };

    case BillingActionType.HidePaymentError:
      return {
        ...state,
        billingState: {
          ...state.billingState,
          isPaymentErrorVisible: false,
        },
      };

    default:
      return state;
  }
}

import { HttpErrorResponse } from '@angular/common/http';

import { ErrorModel } from '@shared/api';

import { HttpStatusCode } from '../model/http-status-code.enum';
import { OrderValidationFailureReason } from '../model/order-validation-failure-reason.enum';

export class OrderUtils {
  static isOrderCompletedError(error: HttpErrorResponse): boolean {
    return !!(
      error &&
      error.status === HttpStatusCode.BadRequest &&
      Array.isArray(error.error) &&
      (<ErrorModel[]>error.error).some(err => err.code === OrderValidationFailureReason.OrderIsAlreadyPaidNoChangesAllowed)
    );
  }
}

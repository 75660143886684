import { Inject, Injectable } from '@angular/core';

import {
  INTERNATIONALIZATION_CONFIGURATION,
  InternationalizationConfiguration,
} from '@configurations/i18n/i18n-configuration.token';
import { EndPointRelation, EndPointRelationNamespace } from '@env/endPointRelation';
import { ENVIRONMENT } from '@env/environment.token';
import { IEnvironmentConfig } from '@env/IEnvironmentConfig';

import { IAppSettings, IGlobalAppSettings } from './core';

@Injectable()
export class GlobalAppSettingsService implements IGlobalAppSettings {
  private appSettings: IAppSettings;

  constructor(
    @Inject(ENVIRONMENT) environment: IEnvironmentConfig,
    @Inject(INTERNATIONALIZATION_CONFIGURATION) i18nConfiguration: InternationalizationConfiguration,
  ) {
    this.checkValidEnvironmentConfiguration(environment);

    this.initializeAppSettings(environment, i18nConfiguration);
  }

  private initializeAppSettings(environment: IEnvironmentConfig, i18nConfiguration: InternationalizationConfiguration) {
    this.appSettings = {
      backendApiUrl: environment.backends.find((b) => b.rel === EndPointRelation.appBackend).url,
      backendOrderApiVersion: environment.backendOrderApiVersion,
      backendShipToStoreApiVersion: environment.backendShipToStoreApiVersion,
      backendAppConfigurationVersion: environment.backendAppConfigurationVersion,
      appInsightsInstrumentalKey: environment.appInsightsInstrumentalKey,
      analyticConfiguration: {
        googleTagManagerContainerId: environment.analyticsGoogleTagManagerContainerId,
        tealiumIQAccount: environment.analyticsTealiumIQAccount,
        tealiumIQProfile: environment.analyticsTealiumIQProfile,
        tealiumIQEnvironment: environment.analyticsTealiumIQEnvironment,
        tealiumIQCountriesSpecific: i18nConfiguration.analyticsTealiumIQCountrySpecific,
        segmentWriteKey: environment.segmentWriteKey,
      },
      addressCompleteConfiguration: {
        key: environment.addressCompleteKey,
        name: environment.addressCompleteName,
      },
      googleMapsApiKey: environment.googleMapsApiKey,
      ravelinPublicKey: environment.ravelinPublicKey,
    };
  }

  private checkValidEnvironmentConfiguration(environment: IEnvironmentConfig) {
    if (!environment) {
      throw new Error('No environments settings found');
    }

    // sanity check - blow up the app if the services endpoints are not present
    EndPointRelationNamespace.toArray().forEach((endpointDef) => {
      if (!environment.backends.find((b) => b.rel === endpointDef.value)) {
        const errorMessage = `CONFIGURATION ERROR: No endpoint configured for ${endpointDef.name}`;
        throw new Error(errorMessage);
      }
    });
  }

  get backendApiUrl() {
    return this.appSettings.backendApiUrl;
  }

  get backendOrderApiVersion() {
    return this.appSettings.backendOrderApiVersion;
  }

  get backendShipToStoreApiVersion() {
    return this.appSettings.backendShipToStoreApiVersion;
  }

  get backendAppConfigurationVersion() {
    return this.appSettings.backendAppConfigurationVersion;
  }

  get appInsightsInstrumentalKey() {
    return this.appSettings.appInsightsInstrumentalKey;
  }

  get analyticConfiguration() {
    return this.appSettings.analyticConfiguration;
  }

  get addressCompleteConfiguration() {
    return this.appSettings.addressCompleteConfiguration;
  }

  get googleMapsApiKey() {
    return this.appSettings.googleMapsApiKey;
  }

  get ravelinPublicKey(): string {
    return this.appSettings.ravelinPublicKey;
  }
}

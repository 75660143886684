import { MoneyModel } from '@shared/model/money.model';

export class MoneyAdapter {
  public static createModel(currencyIsoAndValue: string): MoneyModel {
    const CURRENCY_ISO_LENGTH = 3;
    const money = new MoneyModel();

    if (currencyIsoAndValue) {
      money.currencyCodeISO = currencyIsoAndValue.substring(0, CURRENCY_ISO_LENGTH);
      money.value = +currencyIsoAndValue.substring(CURRENCY_ISO_LENGTH);
    }

    return money;
  }
}

<div class="sub-items">
  <div class="sub-items__image-container">
    <img [eswImgPreload]="item.imageUrl" [alt]="item.title" />
  </div>

  <div class="sub-items__description-container">
    <h2 class="sub-items__name">{{ item.description }}</h2>

    <div class="sub-items__container">
      <div class="sub-item__container" *ngFor="let subItem of item.productCombinedItems">
        <div class="sub-item__description">
          <div class="sub-item__dash">
            <span>-</span>
          </div>
          <label class="sub-item__label-{{ subItem.description | lowercase }}">
            {{ subItem.description }}
          </label>
        </div>
        <div class="sub-item__shade">
          <span>{{ subItem.shade }}</span>
        </div>
      </div>
    </div>

    <div class="sub-items__button-container">
      <esw-button class="back-to-checkout-btn" (click)="onBackToCheckoutClick()">{{ 'Back to checkout' | translate }}</esw-button>
    </div>
  </div>
</div>

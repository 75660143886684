import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModalModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { StyleGuideModule } from '@app/style-guide';
import { LeavePageModalComponent } from '@core/leave-page-modal/leave-page-modal.component';
import { LeavePageService } from '@core/leave-page-modal/leave-page.service';
import { PipesModule } from '@shared/pipes/pipes.module';
import { BackToCartDirective } from '@shared/ui/back-to-cart/back-to-cart.directive';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { CheckboxComponent } from '@shared/ui/checkbox/checkbox.component';
import { DetailsReviewComponent } from '@shared/ui/details-review/details-review.component';
import { FeatureToggleDirective } from '@shared/ui/feature-toggle/feature-toggle.directive';
import { FieldValidationComponent } from '@shared/ui/field-validation/field.validation.component';
import { GdprConsentComponent } from '@shared/ui/gdpr/gdpr-consent.component';
import { InputMaskComponent } from '@shared/ui/input-mask/input-mask.component';
import { InputComponent } from '@shared/ui/input/input.component';
import { NumericFieldDirective, NumericFieldWithDecimalsDirective } from '@shared/ui/numeric-field/numeric-field.directive';
import { NotificationMessageComponent } from '@shared/ui/payment-error-message/notification-message.component';
import { PhoneInputModule } from '@shared/ui/phone-input/phone-input.module';
import { RadioComponent } from '@shared/ui/radio/radio.component';
import { SelectComponent } from '@shared/ui/select/select.component';
import { TooltipComponent } from '@shared/ui/tooltip/tooltip.component';
import { TooltipDirective } from '@shared/ui/tooltip/tooltip.directive';

import { TranslateDatePipe } from '../pipes/date/date.pipe';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { CurrencyComponent } from './currency/currency.component';
import { ExternalLinkDirective } from './external-link/external-link.directive';
import { ScrollToFirstInvalidDirective } from './field-validation/scroll-to-first-invalid.directive';
import { ImgPreloadDirective } from './img-preload/img-preload.directive';
import { ModalComponent } from './modal/modal.component';
import {
  SelectedDeliveryMethodInfoComponent,
} from './selected-delivery-method-info/selected-delivery-method-info.component';
import { TranslateWarningDirective } from './translate-warning.directive';
import { TypeaheadComponent } from './typeahead/typeahead.component';

@NgModule({
  declarations: [
    FeatureToggleDirective,
    NumericFieldDirective,
    NumericFieldWithDecimalsDirective,
    TooltipDirective,
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    FieldValidationComponent,
    ButtonComponent,
    TooltipComponent,
    InputMaskComponent,
    TypeaheadComponent,
    ModalComponent,
    RadioComponent,
    CurrencyComponent,
    ImgPreloadDirective,
    ScrollToFirstInvalidDirective,
    TranslateWarningDirective,
    TranslateDatePipe,
    ClickStopPropagationDirective,
    LeavePageModalComponent,
    ExternalLinkDirective,
    DetailsReviewComponent,
    NotificationMessageComponent,
    GdprConsentComponent,
    SelectedDeliveryMethodInfoComponent,
    BackToCartDirective,
  ],
  providers: [LeavePageService],
  imports: [
    NgbTooltipModule,
    CommonModule,
    FormsModule,
    StyleGuideModule,
    ReactiveFormsModule,
    PipesModule,
    PhoneInputModule,
    NgbModalModule,
    TranslateModule,
    NgbTypeaheadModule,
  ],
  exports: [
    NumericFieldDirective,
    NumericFieldWithDecimalsDirective,
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    FieldValidationComponent,
    ButtonComponent,
    TooltipComponent,
    FeatureToggleDirective,
    TranslateModule,
    PipesModule,
    PhoneInputModule,
    InputMaskComponent,
    TypeaheadComponent,
    ModalComponent,
    RadioComponent,
    CurrencyComponent,
    ImgPreloadDirective,
    ScrollToFirstInvalidDirective,
    TranslateWarningDirective,
    TranslateDatePipe,
    ClickStopPropagationDirective,
    LeavePageModalComponent,
    ExternalLinkDirective,
    DetailsReviewComponent,
    NotificationMessageComponent,
    GdprConsentComponent,
    SelectedDeliveryMethodInfoComponent,
    BackToCartDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedUIModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AddressModel } from '@app/shared/model/address.model';
import { CalculatorNotificationsModel } from '@app/shared/model/calculator-notifications.model';
import { ContactInformationModel } from '@app/shared/model/contact-information.model';
import { Code, DeliveryOption } from '@shared/api';
import { CartItem, CartItems, OrderModel, OrderStatus } from '@shared/model';
import { OrderDeliveryOptionModel } from '@shared/model/order-delivery-options.model';
import { RetailerExperienceModel } from '@shared/model/retailer-experience.model';
import { ShopperExperienceModel } from '@shared/model/shopper-experience.model';
import { AnalyticsModel } from '@shared/services/analytics/analytics.model';

import { AppInitActions, AppInitActionType } from './app-init.actions';
import {
  BillingState,
  CheckoutThemeState,
  IAppState,
  ICheckoutState,
  initialState,
  LayoutStateEnum,
  ShippingState,
  ThemeState,
} from './app.state';

export function appInitReducer(state: IAppState = initialState, action: AppInitActions): IAppState {
  switch (action.type) {
    case AppInitActionType.AppInitializeSuccessAction:
      return {
        ...state,
        themeState: { ...action.themeState },
      };

    case AppInitActionType.OrderInitializeSuccess:
      return {
        ...state,
        appInitialized: true,
        checkout: { ...state.checkout, order: action.payload },
        billingState: {
          ...state.billingState,
          isInitialized: true,
        },
      };

    case AppInitActionType.OrderInitializeFailure:
      return { ...state, appInitialized: true, checkout: undefined };

    case AppInitActionType.OrderInitializeMoveToPayment:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            status: OrderStatus.PaymentInProgress,
          },
        },
        layoutState: {
          ...state.layoutState,
          current: LayoutStateEnum.PaymentReview,
        },
        billingState: {
          ...state.billingState,
          isInitialized: false,
        },
      };

    case AppInitActionType.ThemeInitializeSuccess:
      return {
        ...state,
        isThemeInitialized: true,
      };
    case AppInitActionType.SetAppAsNative:
      return {
        ...state,
        isNativeApp: true,
      };
    default:
      return state;
  }
}

export const AppInitFeatureName = 'App';

export const getMainState = createFeatureSelector<IAppState>(AppInitFeatureName);

export const getIsNativeAppState = createSelector(getMainState, (state: IAppState) => state.isNativeApp);

export const getCheckoutState = createSelector(getMainState, (state: IAppState) => state.checkout);

export const getLayoutState = createSelector(getMainState, (state: IAppState) => state.layoutState);

export const getLocaleState = createSelector(getMainState, (state: IAppState) => state.layoutState.locale);

export const getThemeState = createSelector(getMainState, (state: IAppState) => state.themeState);

export const getIsRtlModeEnabled = createSelector(getMainState, (state: IAppState): boolean => state.layoutState?.isRtlMode);

const getShippingState = createSelector(getMainState, (state: IAppState) => state.shippingState);

export const getIsChargesUpdatingState = createSelector(getShippingState, (state: ShippingState) => state.isOrderChargesUpdating);

export const getOrderState = createSelector(getCheckoutState, (state: ICheckoutState) => (state ? state.order : <OrderModel>{}));

export const getDeliveryCountryIso = createSelector(getOrderState, (state: OrderModel) => state.deliveryCountryIso);

export const getGdprAcceptedState = createSelector(getOrderState, (state: OrderModel) => state.gdprAccepted);

export const getPaymentsUrlState = createSelector(getCheckoutState, (state: ICheckoutState) => state.paymentsUrl);

export const getAnalyticsState = createSelector(getMainState, (state: IAppState) => state.analytics);

export const getCorrelationId = createSelector(getAnalyticsState, (state: AnalyticsModel) => state.fingerprintId);

export const getOrderIdState = createSelector(getOrderState, (state: OrderModel) => state.id);

export const getOrderStatus = createSelector(getOrderState, (state: OrderModel) => state.status);

export const getOrderChargesState = createSelector(getOrderState, (state: OrderModel) => state.charges);

export const getOrderDiscountsState = createSelector(getOrderState, (state: OrderModel) => state.discounts);

export const getOrderPaymentCardLast4Digits = createSelector(getOrderState, (state: OrderModel) => state.paymentCardLast4Digits);

export const getPromoCodesState = createSelector(getOrderState, (state: OrderModel) => state.promoCodes);

export const getDeliveryContactInformationState = createSelector(getOrderState, (state: OrderModel) => state.delivery);

export const getDeliveryAddress = createSelector(
  getDeliveryContactInformationState,
  (contactInformation: ContactInformationModel): AddressModel => contactInformation?.address,
);

export const getDeliveryOptions = createSelector(getOrderState, (state: OrderModel) => (state ? state.deliveryOptions : []));

export const getSelectedDeliveryOption = createSelector(getOrderState, (state: OrderModel) => state.deliveryOption);

export const getSelectedDeliveryOptionDeliveryDate = createSelector(getOrderState, (state: OrderModel) => {
  const selectedDeliveryOptionKey: string = state.deliveryOption;
  const selectedDeliveryOption: OrderDeliveryOptionModel | undefined = state.deliveryOptions.find(
    (option: OrderDeliveryOptionModel) => option.deliveryOption === selectedDeliveryOptionKey,
  );
  return selectedDeliveryOption.estimatedDeliveryDate;
});

export const getPaymentContactInformationState = createSelector(getOrderState, (state: OrderModel) => state.payment);

export const getPaymentAddress = createSelector(
  getPaymentContactInformationState,
  (contactInformation: ContactInformationModel): AddressModel => contactInformation?.address,
);

export const getPaymentCountryIso = createSelector(getPaymentAddress, (address: AddressModel): string => address?.country.iso);

export const getCartState = createSelector(getOrderState, (state: OrderModel) => state.cart);

export const getRestrictedCartItems = createSelector(getOrderState, (state: OrderModel) => state.restrictedCartItems);

export const getAllCartItemsAreRestricted = createSelector(
  getCartState,
  getRestrictedCartItems,
  (cartItems: CartItems, restrictedCartItems: CartItems): boolean => {
    return restrictedCartItems?.length > 0 && cartItems?.length === 0;
  },
);

export const getCheckoutCurrency = createSelector(getOrderState, (state: OrderModel) => state.shopperCurrencyIso);

export const getCustomDeliveryDateInfoIsVisible = createSelector(
  getCartState,
  getSelectedDeliveryOption,
  (cartItems: CartItems, selectedDeliveryOption: DeliveryOption): boolean => {
    const isAllCartItemsNotCustomized: boolean = cartItems.every((item: CartItem) => {
      return item.estimatedDeliveryDatesForDeliveryOptions?.length === 0;
    });
    const firstItemDeliveryDate: Date | undefined = cartItems[0]?.estimatedDeliveryDatesForDeliveryOptions?.find((edd) => {
      return edd.deliveryOption === selectedDeliveryOption;
    })?.estimatedDeliveryDate;
    const isAllCartItemsCustomizedWithSameEdd: boolean =
      cartItems.length > 1 &&
      cartItems.every((item: CartItem) => {
        if (item.estimatedDeliveryDatesForDeliveryOptions?.length === 0) {
          return false;
        }
        const itemDeliveryDate: Date | undefined = item.estimatedDeliveryDatesForDeliveryOptions?.find((edd) => {
          return edd.deliveryOption === selectedDeliveryOption;
        })?.estimatedDeliveryDate;
        return firstItemDeliveryDate === itemDeliveryDate;
      });
    return !(isAllCartItemsNotCustomized || isAllCartItemsCustomizedWithSameEdd);
  },
);

export const getCartItemsTotalQuantity = createSelector(getOrderState, (state: OrderModel): number => {
  const initialLength = 0;
  if (!state.cart) {
    return initialLength;
  }
  return state.cart.reduce((sum: number, cartItem: CartItem) => sum + cartItem.quantity, initialLength);
});

export const getRetailerCheckoutExperience = createSelector(
  getOrderState,
  (state: OrderModel): RetailerExperienceModel => state.retailerCheckoutExperience,
);

export const getContinueShoppingUrl = createSelector(
  getRetailerCheckoutExperience,
  (retailerExperienceModel: RetailerExperienceModel): string => retailerExperienceModel?.continueShoppingUrl,
);

export const getBackToCartUrl = createSelector(
  getRetailerCheckoutExperience,
  (retailerExperienceModel: RetailerExperienceModel): string => retailerExperienceModel?.backToCartUrl,
);

export const getCustomerServiceUrl = createSelector(
  getRetailerCheckoutExperience,
  (retailerExperienceModel: RetailerExperienceModel): string => retailerExperienceModel?.customerServiceUrl,
);

export const getRetailerOriginCountry = createSelector(getOrderState, (state: OrderModel) => state.retailerOriginCountry);

export const getUseDeliveryAsPaymentAddressState = createSelector(getOrderState, (state: OrderModel) => state.useDeliveryAsPaymentAddress);

const getShopperCheckoutExperience = createSelector(
  getOrderState,
  (state: OrderModel): ShopperExperienceModel => state.shopperCheckoutExperience,
);

export const getMarketingEmailOptInConfirmedState = createSelector(
  getShopperCheckoutExperience,
  (state: ShopperExperienceModel) => state.marketingEmailOptInConfirmed,
);

export const getIsUserRegisteredState = createSelector(
  getShopperCheckoutExperience,
  (state: ShopperExperienceModel) => state.isUserRegistered,
);

export const getShopperCultureLanguageIso = createSelector(
  getShopperCheckoutExperience,
  (state: ShopperExperienceModel) => state.shopperCultureLanguageIso,
);

export const getIsDisplayCustomsSection = createSelector(
  getShopperCheckoutExperience,
  (state: ShopperExperienceModel): boolean => !!state.isDisplayCustomsSection,
);

const getBillingState = createSelector(getMainState, (state: IAppState) => state.billingState);

export const getIsBillingInitializedState = createSelector(getBillingState, (state: BillingState) => state.isInitialized);

export const getIsPaymentErrorVisibleState = createSelector(getBillingState, (state: BillingState) => state.isPaymentErrorVisible);

export const getCalculatorNotifications = createSelector(getOrderState, (state: OrderModel) => state.calculatorNotifications || []);

export const getCalculatorNotificationIsPresent = (notificationCode: Code) =>
  createSelector(getCalculatorNotifications, (notifications: CalculatorNotificationsModel[]): boolean => {
    return !!notifications.find((notification: CalculatorNotificationsModel) => notification.code === notificationCode);
  });

export const getOrderLimitThresholdValue = (notificationCode: Code) =>
  createSelector(getCalculatorNotifications, (notifications: CalculatorNotificationsModel[]): string | undefined => {
    const notification: CalculatorNotificationsModel | undefined = notifications?.find(
      (calculatorNotification) => calculatorNotification.code === notificationCode,
    );
    return notification?.thresholdValues[0]?.value;
  });

export const getIsDisplayNationalId = createSelector(
  getCalculatorNotifications,
  (state: CalculatorNotificationsModel[]) =>
    state?.filter((calculatorNotification) => calculatorNotification.code === Code.DisplayNationalId).length > 0,
);

export const getIsTransliterationFailure = createSelector(
  getShippingState,
  getBillingState,
  (shippingState: ShippingState, billingState: BillingState) =>
    shippingState?.isTransliterationFailure || billingState?.isTransliterationFailure,
);

export const getIsTransliterationLowAddressScore = createSelector(
  getShippingState,
  getBillingState,
  (shippingState: ShippingState, billingState: BillingState) =>
    shippingState?.isTransliterationLowAddressScore || billingState?.isTransliterationLowAddressScore,
);

export const getShippingTransliterationAttempts = createSelector(
  getShippingState,
  (shippingState: ShippingState) => shippingState?.transliterationAttempt,
);

export const getBillingTransliterationAttempts = createSelector(
  getBillingState,
  (billingState: BillingState) => billingState?.transliterationAttempt,
);

export const getThemeCheckoutState = createSelector(getThemeState, (themeState: ThemeState) => themeState?.checkout);

export const getLogoUrl = createSelector(getThemeCheckoutState, (checkout: CheckoutThemeState) => checkout?.logoUrl);

export enum GenericErrorTemplateEnum {
  Default = 'Default',
  ConfirmationPage = 'ConfirmationPage',
  MultiplePayments = 'MultiplePayments',
  OrderIsOutOfStock = 'OrderIsOutOfStock',
  InventoryUnavailable = 'InventoryUnavailable',
  MaxOrderValueThresholdBreached = 'MaxOrderValueThresholdBreached',
  MaxNumberOfItemsExceeded = 'MaxNumberOfItemsExceeded',
  OrderWeightExceeded = 'OrderWeightExceeded',
  MaxFobOrderValueThresholdBreached = 'MaxFobOrderValueThresholdBreached',
  MaxCifOrderValueThresholdBreached = 'MaxCifOrderValueThresholdBreached',
}

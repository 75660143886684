import { Action } from '@ngrx/store';
import { LayoutConfiguration } from '@configurations/configuration.token';

export enum LayoutConfigActionTypes {
  SetLayoutConfiguration = '[LayoutConfig] set layout config',
}

export class SetLayoutConfiguration implements Action {
  readonly type = LayoutConfigActionTypes.SetLayoutConfiguration;
  constructor(public configuration: LayoutConfiguration) {}
}

export type LayoutConfigActions = SetLayoutConfiguration;

import { IAppState } from '@app/store/app-init/app.state';

import { AnalyticsActions, AnalyticsActionType } from './analytics.actions';

export function analyticsReducer(state: IAppState, action: AnalyticsActions): IAppState {
  switch (action.type) {
    case AnalyticsActionType.Initialize:
      return { ...state, analytics: action.payload };

    case AnalyticsActionType.ChangeLanguage:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          checkoutLanguage: action.payload,
        },
      };

    case AnalyticsActionType.UpdateDeliveryOption:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          deliveryOption: action.payload,
        },
      };

    default:
      return state;
  }
}

import { CartItems, IMetadataItem, OrderStatus } from '@shared/model';
import { ContactInformationModel } from '@shared/model/contact-information.model';
import { OrderChargesModel } from '@shared/model/order-charges.model';
import { OrderDeliveryOptionModel } from '@shared/model/order-delivery-options.model';
import { OrderDiscountModel } from '@shared/model/order-discount.model';
import { OrderError } from '@shared/model/order-error.model';
import { PaymentProviderType } from '@shared/model/payment-provider-type.enum';
import { PromoCode } from '@shared/model/promo-code.model';
import { RetailerExperienceModel } from '@shared/model/retailer-experience.model';
import { ShopperExperienceModel } from '@shared/model/shopper-experience.model';

import { CalculatorNotificationsModel } from './calculator-notifications.model';
import { PaymentThreeDSecureRedirectModel } from './payment-three-d-secure-redirect.model';

export class OrderModel {
  calculatorNotifications?: CalculatorNotificationsModel[];
  cart: CartItems;
  restrictedCartItems?: CartItems;
  charges: OrderChargesModel;
  delivery: ContactInformationModel;
  deliveryCountryIso: string;
  deliveryOption: string;
  deliveryOptions: OrderDeliveryOptionModel[];
  discounts?: OrderDiscountModel[];
  discountsRetailerCurrency?: OrderDiscountModel[];
  gdprAccepted: boolean;
  id: string;
  payment: ContactInformationModel;
  paymentId: string;
  paymentProviderType?: PaymentProviderType;
  paymentIsSuccessfull: boolean;
  paymentCardLast4Digits?: string;
  paymentThreeDSecureRedirect?: PaymentThreeDSecureRedirectModel;
  promoCodes?: PromoCode[];
  retailerCartId: string;
  retailerOrderNumber?: string;
  retailerCheckoutExperience: RetailerExperienceModel;
  retailerOriginCountry: string;
  retailerCurrencyIso: string;
  shopperCheckoutExperience: ShopperExperienceModel;
  shopperCurrencyIso: string;
  status: OrderStatus;
  timeToExpiry?: number;
  useDeliveryAsPaymentAddress: boolean;
  errors?: Array<OrderError>;
  contactDetailsMetadataItems?: IMetadataItem[];
}

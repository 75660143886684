import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { LayoutRoutesNames } from './layout/layout.routes.names';
import { urlMatcher } from './urlMatcher';

const routes: Routes = [
  {
    path: 'styles',
    pathMatch: 'prefix',
    loadChildren: () => import('./style-guide-page/style-guide-page.module').then((m) => m.StyleGuidePageModule),
  },
  {
    matcher: urlMatcher,
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: '**',
    redirectTo: `${LayoutRoutesNames.Error}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routedComponents = [AppComponent];

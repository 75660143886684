import { IAddressCompleteConfig } from '@env/IAddressCompleteConfig';

import { IAnalyticConfig } from './IAnalyticConfig';

export class AuthEndpoints {
  endpointsRequiringAuth: Array<string>;

  constructor(endpoints: Array<string>) {
    this.endpointsRequiringAuth = endpoints;
  }

  needsAuth(url: string): boolean {
    return this.endpointsRequiringAuth.some((endpoint) => url.startsWith(endpoint));
  }
}

export interface IAppSettings {
  backendApiUrl: string;

  backendOrderApiVersion: string;

  backendShipToStoreApiVersion: string;

  backendAppConfigurationVersion: string;

  appInsightsInstrumentalKey: string;

  analyticConfiguration: IAnalyticConfig;

  addressCompleteConfiguration: IAddressCompleteConfig;

  googleMapsApiKey: string;

  ravelinPublicKey: string;
}

export interface IAuthEndpointConfig {
  url: string;
  redirectUrl: string;
}

export abstract class IGlobalAppSettings implements IAppSettings {
  abstract get backendApiUrl(): string;

  abstract get backendOrderApiVersion(): string;

  abstract get backendShipToStoreApiVersion(): string;

  abstract get backendAppConfigurationVersion(): string;

  abstract get appInsightsInstrumentalKey(): string;

  abstract get analyticConfiguration(): IAnalyticConfig;

  abstract get addressCompleteConfiguration(): IAddressCompleteConfig;

  abstract get googleMapsApiKey(): string;

  abstract get ravelinPublicKey(): string;
}

import { InjectionToken } from '@angular/core';

import { ICurrencyFormatsConfig } from '@app/core/ICurrencyFormatsConfig';
import { IPriceFormatsToLocaleSpecificConfig } from '@app/core/IPriceFormatsConfig';
import { ILanguagesToCountriesSpecificConfig } from '@core/ILanguagesToCountriesSpecificConfig';
import { IMessageCountriesSpecificConfig } from '@core/IMessageCountriesSpecificConfig';
import { ITealiumIQCountriesSpecificConfig } from '@core/ITealiumIQCountriesSpecificConfig';
import { ISupportCenterPhonesCountrySpecificConfig } from '@core/ISupportCenterPhonesCountrySpecificConfig';

export const INTERNATIONALIZATION_CONFIGURATION = new InjectionToken<InternationalizationConfiguration>(
  'internationalization-configuration',
);
export class InternationalizationConfiguration {
  supportedLanguagesByCountriesIso: Array<ILanguagesToCountriesSpecificConfig> = [];
  supportedBillingCountriesIso: Array<string> = []; // ['*'] or ['DZ', 'BO]
  priceConfigurations: Array<IPriceFormatsToLocaleSpecificConfig> = [];
  currencyConfigurations: ICurrencyFormatsConfig;
  analyticsTealiumIQCountrySpecific?: ITealiumIQCountriesSpecificConfig[];
  supportedAddressCompleteCountriesIso?: string[];
  languageIsoToTitleMap: { [languageIso: string]: string };
  imperialUnitSystemCountriesIso: string[] = [];
  cartMessageByCountriesIso: Array<IMessageCountriesSpecificConfig> = [];
  billingMessageByCountriesIso: Array<IMessageCountriesSpecificConfig> = [];
  supportCenterPhoneByCountryIso: Array<ISupportCenterPhonesCountrySpecificConfig> = [];
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esw-modal',
  templateUrl: 'modal.component.html',
})
export class ModalComponent {
  @Input() title: string;
  @Input() buttonText: string;
  @Input() secondaryButtonText = '';
  @Input() iconClass: string;
  @Input() isCloseButtonVisible = false;

  @Output() close = new EventEmitter<void>();
  @Output() buttonClick = new EventEmitter<void>();
  @Output() secondaryButtonClick = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }

  onSecondaryButtonClick() {
    this.secondaryButtonClick.emit();
  }

  onCloseButtonClick() {
    this.close.emit();
  }
}

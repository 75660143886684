<esw-modal
  [title]="'Restricted items in your cart' | translate"
  [buttonText]="
    allItemsRestricted || (FeatureName.RestrictedItemsModalProceedButtonHidden | featureFlagIs: true | async)
      ? ''
      : ('Proceed to checkout' | translate)
  "
  [secondaryButtonText]="'Back to cart restricted' | translate"
  iconClass="ion-android-alert"
  (buttonClick)="modal.close()"
  (secondaryButtonClick)="redirectToCart()"
>
  <div class="restricted-cart-items">
    <section class="restricted-cart-items__info">
      <ng-container *ngIf="!allItemsRestricted">
        <span id="not-all-items-restricted">
          {{
            'Unfortunately, the following item is not available for shipping to your country. The item has been removed from your purchase.'
              | translate
          }}
        </span>
        <span *ngIf="cartDiscountsRemoved" id="cart-discounts-removed">
          {{ 'Due to the removal of restricted items from your cart, your cart discount is no longer valid' | translate }}
        </span>
      </ng-container>
      <ng-container *ngIf="allItemsRestricted">
        <span id="all-items-restricted">
          {{
            'Unfortunately, the selected items are not available for shipping to your country.'
              | translate: { brandName: configuration.brandName }
          }}
        </span>
      </ng-container>
    </section>
    <section class="restricted-cart-items__content">
      <esw-cart-item
        *ngFor="let item of restrictedCartItems"
        [item]="item"
        [isDetailsModalsAvailable]="false"
        class="restricted-cart-items__item"
      ></esw-cart-item>
    </section>
  </div>
</esw-modal>

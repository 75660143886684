import { IAppState } from '@app/store/app-init/app.state';
import { GdprConsentUpdateActions, GdprConsentUpdateActionType } from '@app/store/gdpr-update/gdpr-update.actions';

export function gdprConsentUpdateReducer(state: IAppState, action: GdprConsentUpdateActions): IAppState {
  switch (action.type) {
    case GdprConsentUpdateActionType.Update:
    case GdprConsentUpdateActionType.UpdateSuccess:
    case GdprConsentUpdateActionType.UpdateFailure:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order: {
            ...state.checkout.order,
            gdprAccepted: action.payload,
          },
        },
      };

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { GenericErrorTemplateEnum } from '@app/errors/generic-error/generic-error-template.enum';
import { LayoutRoutesNames } from '@app/layout/layout.routes.names';
import { OrderInitializeAction, OrderInitializeMoveToPaymentAction } from '@app/store/app-init/app-init.actions';
import { getMainState } from '@app/store/app-init/app-init.reducer';
import { IAppState, ICheckoutState } from '@app/store/app-init/app.state';
import { BillingShowPaymentErrorAction } from '@app/store/billing/billing.actions';
import { SetExpressPaymentErrorIsVisible } from '@app/store/express-payments/express-payments.actions';
import { ErrorCode } from '@shared/api';
import { OrderModel } from '@shared/model';
import { AppInsightsEventName } from '@shared/model/app-insights-event-name.enum';
import { OrderError } from '@shared/model/order-error.model';
import { OrderStatus } from '@shared/model/order-status.enum';
import { PaymentProviderType } from '@shared/model/payment-provider-type.enum';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';

import { OrderService } from '../order.service';
import { getRoutePath } from './navigation-helper';

@Injectable()
export class OnlyPaymentOrdersGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private appInsights: AppInsightsWrapperService,
    private orderService: OrderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const orderIdCandidate = route.paramMap.get('orderId');
    const countryIsoCandidate = route.paramMap.get('countryIso');
    const paymentId = route.paramMap.get('paymentId');

    this.store.dispatch(new OrderInitializeAction(orderIdCandidate));

    return this.store.pipe(
      select(getMainState),
      filter((app) => !!app.appInitialized),
      map((app: IAppState) => app.checkout),
      take(1),
      switchMap((checkout: ICheckoutState) => {
        if (!checkout || !checkout.order || checkout.order.status === null || checkout.order.status === undefined) {
          throw new Error('order badly formatted');
        }

        if (paymentId !== checkout.order.paymentId && checkout.order.paymentIsSuccessfull) {
          this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]), {
            state: { template: GenericErrorTemplateEnum.MultiplePayments },
          });
          return of(false);
        }

        if (paymentId !== checkout.order.paymentId) {
          this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));
          return of(false);
        }

        if (this.orderHasErrorCode(checkout.order, ErrorCode.RetailerOutOfStock)) {
          this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]), {
            state: { template: GenericErrorTemplateEnum.OrderIsOutOfStock },
          });
          return of(false);
        }

        switch (checkout.order.status) {
          case OrderStatus.BeingProcessed:
          case OrderStatus.Completed:
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Confirmation]));
            return of(false);
          case OrderStatus.PaymentFailed:
            this.appInsights.trackEvent(AppInsightsEventName.PaymentNotSuccessfulEvent);

            if (checkout.order?.paymentProviderType === PaymentProviderType.Express) {
              this.store.dispatch(new SetExpressPaymentErrorIsVisible());
              this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));
              return of(false);
            }

            return this.orderService.putRetryPayment(orderIdCandidate).pipe(
              map(() => {
                this.store.dispatch(new OrderInitializeMoveToPaymentAction());
                this.store.dispatch(new BillingShowPaymentErrorAction());

                this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate]));

                return false;
              }),
            );
          default:
            this.appInsights.trackEvent(AppInsightsEventName.OrderStatusNotValidAfterPaymentErrorEvent, {
              orderStatus: `${checkout.order.status}`,
            });
            this.router.navigate(getRoutePath(countryIsoCandidate, [orderIdCandidate, LayoutRoutesNames.Error]));
            return of(false);
        }
      }),
      catchError((a, b) => {
        this.router.navigate(getRoutePath(countryIsoCandidate, [LayoutRoutesNames.Error]));
        return of(false);
      }),
    );
  }

  private orderHasErrorCode(order: OrderModel, errorCode: ErrorCode): boolean {
    return order.errors && Array.isArray(order.errors) && order.errors.some((error: OrderError) => error.errorCode === errorCode);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }
}

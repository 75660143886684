import { Action } from '@ngrx/store';

export enum GdprConsentUpdateActionType {
  Update = '[GdprConsent] Update',
  UpdateSuccess = '[GdprConsent] Update Success',
  UpdateFailure = '[GdprConsent] Update Failure',
}

export class GdprConsentUpdateAction implements Action {
  readonly type = GdprConsentUpdateActionType.Update;
  constructor(public payload: boolean) {}
}

export class GdprConsentUpdateSuccessAction implements Action {
  readonly type = GdprConsentUpdateActionType.UpdateSuccess;
  constructor(public payload: boolean) {}
}

export class GdprConsentUpdateFailureAction implements Action {
  readonly type = GdprConsentUpdateActionType.UpdateFailure;
  readonly payload = false;
}

export type GdprConsentUpdateActions = GdprConsentUpdateAction | GdprConsentUpdateSuccessAction | GdprConsentUpdateFailureAction;

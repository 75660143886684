import { Injectable } from '@angular/core';

import { LocalStorageKey } from '@shared/model/local-storage-key.enum';
import { LocalStorageWrapperService } from '@shared/services/local-storage-wrapper/local-storage-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class SaveAddressService {
  constructor(private localStorageWrapperService: LocalStorageWrapperService) {}

  public setSaveAddress(value) {
    this.localStorageWrapperService.setItem(LocalStorageKey.SavedAddress, JSON.stringify(value));
  }

  public getSavedAddress() {
    return JSON.parse(this.localStorageWrapperService.getItem(LocalStorageKey.SavedAddress));
  }

  public deleteSavedAddress() {
    this.localStorageWrapperService.removeItem(LocalStorageKey.SavedAddress);
  }
}

import { OrderRetailerCheckoutExperienceResponseDto } from '@shared/api';
import { RetailerExperienceModel } from '@shared/model/retailer-experience.model';

export class RetailerCheckoutExperienceAdapter {
  public static createRetailerCheckoutExperience(response: OrderRetailerCheckoutExperienceResponseDto = {}): RetailerExperienceModel {
    const model = new RetailerExperienceModel();

    model.backToCartUrl = response.backToCartUrl;
    model.continueShoppingUrl = response.continueShoppingUrl;
    model.customerServiceUrl = response.customerServiceUrl;

    return model;
  }
}

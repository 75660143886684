import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule, NgZone, Optional, SkipSelf } from '@angular/core';

import { ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Segment } from 'angulartics2/segment';

import { FEATURESET } from '@configurations/feature-set.token';
import { AddressCompleteService } from '@shared/services/address-complete/address-complete.service';
import { AnalyticInitializerService, AnalyticServiceFactory } from '@shared/services/analytics/analytic-initializer.service';
import { Angulartics2GtmCustomProvider } from '@shared/services/analytics/angulartics2-gtm-custom-provider.service';
import { AppInsightsWrapperService } from '@shared/services/app-insights-wrapper.service';
import { GoogleMapsWrapperService } from '@shared/services/google-maps-wrapper.service';
import { SharedUIModule } from '@shared/ui';
import { EnvironmentWrapper } from '@shared/utils/EnvironmentWrapper';

import { BusyIndicatorComponent, BusyIndicatorService } from './busyIndicator';
import { IGlobalAppSettings } from './IAppSettings';
import { AskConfirmationDeactivateGuard } from './navigation/modal-confirmation-deactivate-guard.service';
import { OrderCompletedModalComponent } from './order-completed-modal/order-completed-modal.component';
import { QueryStringService } from './query-string/query-string.service';
import { ScrollingService } from './scrolling.service';
import { TimeOutComponent } from './time-out/time-out.component';
import { WindowRefService } from './windowref/windowref.service';

/*
 * Core module, should be called .forRoot on the AppModule.
 * App module has to provide a service of type IGlobalAppSettings (for injecting application settings)
 */
@NgModule({
  declarations: [BusyIndicatorComponent, TimeOutComponent, OrderCompletedModalComponent],
  exports: [BusyIndicatorComponent, TimeOutComponent, OrderCompletedModalComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true,
    }),
    SharedUIModule,
  ],
  providers: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        BusyIndicatorService,
        AskConfirmationDeactivateGuard,
        QueryStringService,
        ScrollingService,
        GoogleMapsWrapperService,
        {
          provide: Angulartics2GoogleTagManager,
          useClass: Angulartics2GtmCustomProvider,
        },
        {
          provide: AnalyticInitializerService,
          useFactory: AnatyticInitializerServiceFactory,
          deps: [IGlobalAppSettings, Angulartics2Segment, Angulartics2GoogleTagManager],
        },
        {
          provide: AddressCompleteService,
          useFactory: AddressCompleteServiceFactory,
          deps: [IGlobalAppSettings, WindowRefService],
        },
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    appInsights: AppInsightsWrapperService,
    analyticInitializerService: AnalyticInitializerService,
    private settings: IGlobalAppSettings,
    private ngZone: NgZone,
    @Inject(FEATURESET) private featureSet: { [key: string]: boolean },
  ) {
    this.throwExceptionIfItisAlreadyLoaded(parentModule);
    if (EnvironmentWrapper.getAnalyticsEnabled()) {
      this.ngZone.runOutsideAngular(() => {
        appInsights.init(settings);
        analyticInitializerService.initializeAnalyticServices();
      });
    }
  }

  private throwExceptionIfItisAlreadyLoaded(parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

export function AnatyticInitializerServiceFactory(
  settings: IGlobalAppSettings,
  angulartics2Segment: Angulartics2Segment,
  angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
) {
  const factory = new AnalyticServiceFactory(settings.analyticConfiguration);

  return new AnalyticInitializerService(factory, angulartics2Segment, angulartics2GoogleTagManager);
}

export function AddressCompleteServiceFactory(settings: IGlobalAppSettings, windowRef: WindowRefService) {
  return new AddressCompleteService(settings.addressCompleteConfiguration, windowRef);
}

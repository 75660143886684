import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { IAppState } from '@app/store/app-init/app.state';
import { expressPaymentsReducer } from '@app/store/express-payments/express-payments.reducer';
import { checkoutReducer } from '@app/store/reducer';
import { reducerAgregator } from '@shared/utils';

import { appInitReducer } from './app-init/app-init.reducer';
import { featureFlagReducer } from './feature-flag/feature-flag.reducer';
import { layoutConfigReducer } from './layout-config/layout-config.reducer';
import { IState } from './state';

const featureReducers = [appInitReducer, checkoutReducer, featureFlagReducer, expressPaymentsReducer, layoutConfigReducer];
export function appReducers(state: IAppState, action) {
  return reducerAgregator<IAppState>(featureReducers, state, action);
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<IState> = {
  routerReducer: fromRouter.routerReducer,
  App: appReducers,
};

<esw-modal
  [title]="'Gift Message' | translate"
  [buttonText]="'Continue shopping' | translate"
  [secondaryButtonText]="'Back to cart' | translate"
  iconClass="ion-android-alert"
  (buttonClick)="modal.close()"
  (secondaryButtonClick)="redirectToCart()"
>
  <div class="gift-message-modal">
    {{ 'To edit your gift message' | translate }}
  </div>
</esw-modal>

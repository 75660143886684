import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WindowRefService } from '../windowref/windowref.service';
import { LeavePageModalComponent } from './leave-page-modal.component';

@Injectable()
export class LeavePageService {
  private history: History;
  private isLeavingConfirmed = false;
  private isWindowOpened = false;

  constructor(private modalService: NgbModal, private locationStrategy: LocationStrategy, private windowRefService: WindowRefService) {}

  init() {
    this.history = this.windowRefService.nativeWindow.history;

    // no need to push a history state if there is no history
    if (this.history?.length > 1) {
      // adding the same history state to be available to check browsers back/forward event onPopState
      // when user press back button
      this.history.pushState(null, null);
    }

    this.locationStrategy.onPopState(() => {
      if (this.isTravellingToTheFirstPage) {
        this.isLeavingConfirmed ? this.leave() : this.openDialog();
      }
    });
  }

  get isTravellingToTheFirstPage() {
    return this.history.state?.navigationId === 1;
  }

  openDialog(): void {
    this.history.pushState(null, null);

    if (!this.isWindowOpened) {
      this.isWindowOpened = true;
      this.modalService
        .open(LeavePageModalComponent, {
          backdrop: 'static',
          keyboard: false,
        })
        .result.then(
          () => {
            this.isWindowOpened = false;
          },
          () => {
            this.isWindowOpened = false;
            this.isLeavingConfirmed = true;
            this.leave();
          },
        );
    }
  }

  private leave(): void {
    this.history.go(-2);
  }
}
